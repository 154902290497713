import type { Ref } from 'vue'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import type { FiltersSelection } from '~/types'
import LxcError from '~/utils/LxcError'

interface UseFetchParameters<T> {
  service: (page: number, pageSize: number, searchParams: string | FiltersSelection, sort?: string | null, params?: Map<string, any>) => Promise<T>
  searchParams: Ref<string | FiltersSelection>
  sort: Ref<string | null | undefined>
}

export function useFetchPage<T>({ service, searchParams, sort }: UseFetchParameters<T>) {
  const results: Ref<T | null> = ref(null)
  const error: Ref<LxcError | null | undefined> = ref(null)
  const isLoading = ref(false)

  watch(sort, () => fetchData())

  async function fetchData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE, sortOverride?: string, params?: Map<string, any>) {
    isLoading.value = true

    const response = await service(page, pageSize, searchParams.value, sortOverride || (sort.value ?? undefined), params)

    if (LxcError.check(response)) {
      results.value = null
      error.value = response
    } else {
      results.value = response ?? null
      error.value = null
    }

    isLoading.value = false
  }

  return {
    isLoading,
    results,
    error,
    fetchData,
  }
}
