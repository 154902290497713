<script setup lang="ts">
import type { CreateInterfaceResponseI } from '@lxc/app-device-types'
import ILxcCheckCircle from '~icons/lxc/check-circle'

const props = defineProps<{
  show: boolean
  newInterfaceResponse?: CreateInterfaceResponseI
}>()

defineEmits(['close'])

const clientId = computed(() => {
  return props.newInterfaceResponse?.clientId ?? ''
})

const secret = computed(() => {
  return props.newInterfaceResponse?.clientSecret ?? ''
})

const iconClass = [
  'p-3',
  'rounded-full',
  'box-border',
  'self-start',
  'bg-success-100',
  'text-gray-700',
]
</script>
<template>
  <lxc-common-modal
    :show="show"
    body-class="bg-white"
    header-class="pt-6"
    footer-class="mb-2 flex-row-reverse"
    no-close-button
  >
    <template #header>
      <div
        class="flex gap-4"
      >
        <div
          :class="iconClass"
        >
          <ILxcCheckCircle
            width="24"
            height="24"
          />
        </div>
        <div
          class="text-start"
        >
          <h2
            class="m-0"
          >
            {{ $t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.header.title') }}
          </h2>
          <p
            class="text-base text-wrap text-gray-500"
          >
            {{ $t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.header.info') }}
          </p>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex gap-8">
        <div class="flex-1">
          <lxc-input
            type="text"
            disabled
            :label="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.clientId.label')"
            :model-value="clientId"
          />
        </div>
        <lxc-copy-to-clipboard
          :confirmation="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.copied')"
          :title="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.buttonTitle')"
          :info-to-copy="clientId"
        />
      </div>
      <div class="mt-6 flex gap-8">
        <div class="flex-1">
          <lxc-input
            type="text"
            disabled
            :label="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.secret.label')"
            :model-value="secret"
          />
        </div>
        <lxc-copy-to-clipboard
          :confirmation="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.copied')"
          :title="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.buttonTitle')"
          :info-to-copy="secret"
        />
      </div>
    </template>
    <template #footer>
      <lxc-button
        :title="$t('button.close')"
        @click="$emit('close')"
      >
        {{ $t('button.close') }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>
