<script setup lang="ts">
import { useSerialize } from '@lxc/app-device-common'
import type { KeystoreSelfsignedCertificateRequestI } from '@lxc/app-device-types'
import type { Rules } from 'async-validator'
import { type Ref, type WritableComputedRef, ref } from 'vue'
import { VALIDATION_NUMBER, VALIDATION_REGEXP } from '~/constants/constants'
import keystoreService from '~/services/keystore.service'
import type { KeystoreSelfsignedCertificateForm } from '~/types/keystore'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import { getValuesFromUniqueLabels } from '~/utils/unique-label-tools'

const { t } = useI18n()
const serialize = useSerialize()

const props = defineProps<{
  disabled?: false
  isTagsLoading: boolean
  sideCanvasShown: boolean
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const emit = defineEmits([
  'save',
  'update:sideCanvasShown',
])

const isLoading: Ref<boolean> = ref(false)
const defaultCertificateForm: KeystoreSelfsignedCertificateForm = {
  alias: '',
  subject: {
    cn: '',
    email: '',
    serialNumber: undefined,
    organizationUnit: undefined,
    organization: undefined,
    locality: undefined,
    region: undefined,
    country: undefined,
  },
  tags: [],
}

const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown
  },
  set(sideCanvasShown: boolean) {
    emit('update:sideCanvasShown', sideCanvasShown)
  },
})
// deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
const certificateForm: Ref<KeystoreSelfsignedCertificateForm> = ref<KeystoreSelfsignedCertificateForm>({
  ...defaultCertificateForm,
  subject: { ...defaultCertificateForm.subject },
  tags: [...defaultCertificateForm.tags],
})
const defaultCertificateFormStringified = serialize(defaultCertificateForm)
const certificateFormRef: Ref = ref()
const edited = computed(() => {
  const strigifiedForm = serialize(certificateForm.value)
  return strigifiedForm !== defaultCertificateFormStringified
})

// Certificate rules
const rules = computed(() => {
  const rulesConfig: Rules = {
    'alias': [
      {
        required: true,
        message: t('certificates.validation.alias.required'),
        type: 'string',
        whitespace: false,
      },
      {
        type: 'string',
        message: t('certificates.validation.alias.invalid'),
        pattern: VALIDATION_REGEXP.CERTIFICATE_ALIAS,
      },
    ],
    'subject.cn': [
      {
        required: true,
        message: t('certificates.validation.cn'),
        type: 'string',
        whitespace: false,
      },
    ],
    'subject.email': [
      {
        required: true,
        message: t('certificates.validation.email'),
        whitespace: false,
      },
      {
        type: 'email',
        message: t('input.error.invalidFormat'),
      },
    ],
  }

  return rulesConfig
})

function setKeystoreForm() {
  // deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
  certificateForm.value = Object.assign(certificateForm.value, defaultCertificateForm,
    {
      subject: { ...defaultCertificateForm.subject },
      tags: [...defaultCertificateForm.tags],
    })
  // set timeout to be sure that the clearValidate is processed after updating the form inputs
  setTimeout(certificateFormRef.value?.clearValidate, 0)
}

async function saveForm(): Promise<void> {
  isLoading.value = true
  certificateForm.value.alias = certificateForm.value.alias.trim()

  const certificate: KeystoreSelfsignedCertificateRequestI = {
    ...certificateForm.value,
    tags: getValuesFromUniqueLabels(certificateForm.value.tags),
  }

  const response = await keystoreService.generateSelfSignedCertificate(certificate)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    close()
    setKeystoreForm()
    emit('save')
  }

  isLoading.value = false
}

async function validate(): Promise<boolean> {
  return await certificateFormRef.value?.validate().catch((_: any) => false)
}

const onSubmit = async(): Promise<boolean> => {
  const dataValid = await validate()

  if (dataValid) {
    saveForm()
  }

  return dataValid
}

function close() {
  formSideCanvasShown.value = false
}

const onCancel = () => {
  close()
  setKeystoreForm()
}
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    :header="$t('certificates.keystore.generateSelfSignedCertificate')"
    :close-tooltip="$t('button.close')"
    @discard="onCancel"
  >
    <lxc-form
      ref="certificateFormRef"
      :model="certificateForm"
      :rules="rules"
      @submit.prevent="onSubmit"
    >
      <lxc-keystore-generic-generation-form
        v-model="certificateForm"
        :disabled="false"
        :edition="false"
        :is-tags-loading="isTagsLoading"
        :tags="tags"
        :tags-error="tagsError"
      />
    </lxc-form>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="$t('button.close')"
          @click="onCancel"
        >
          {{ $t('button.close') }}
        </lxc-button>
        <lxc-button
          html-type="submit"
          :disabled="!edited || isLoading"
          :title="$t('certificates.keystore.generateSelfSignedCertificate')"
          @click="onSubmit"
        >
          {{ $t('certificates.keystore.generateSelfSignedCertificate') }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
