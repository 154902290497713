<script setup lang="ts">
import type { UniqueLabel } from '@lxc/app-device-common'
import { exceptsAll, includesAll } from '@lxc/app-device-common'
import { CampaignOperationState } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { operationStateOptions } from './campaignOperationsFilters.config'
import type { FiltersSelection, Option } from '~/types'
import { Filters, OPERATOR_EQUAL } from '~/types'
import filtersUtils from '~/utils/filters.utils'
const { t } = useI18n()

const props = defineProps<{
  appliedTags: Array<UniqueLabel>
  filters: FiltersSelection
  modelValue: Array<string>
  selectedTags: Array<UniqueLabel>
  tagLabel: string
}>()
const FilterTagsToDisplay: Array<string> = [
  CampaignOperationState.DONE,
  CampaignOperationState.ERROR,
  CampaignOperationState.CANCELLING,
  CampaignOperationState.CANCELLED,
  CampaignOperationState.PAUSED,
  CampaignOperationState.OP_RUNNING,
  CampaignOperationState.PENDING,
  CampaignOperationState.EXPIRED,
]

const emit = defineEmits([
  'change',
  'update:modelValue',
  'update:selectedTags',
  'update:appliedTags',
])

const selectedStates: Ref<Array<string>> = ref(props.modelValue.slice(0) ?? [])
const operationStateOptionsViewModel: Ref<Array<Option>> = ref([])
operationStateOptionsViewModel.value = operationStateOptions.options.map((option) => {
  return {
    disabled: option.disabled,
    label: t(option.label),
    value: option.value,
  }
}).sort((option1, option2) => option1.label.localeCompare(option2.label))

const onAppliedFilterChange = () => {
  selectedStates.value = props.filters.get(Filters.STATE)?.value as Array<string>
}

const applyFilter = () => {
  if (selectedStates.value.includes(CampaignOperationState.ERROR) && !selectedStates.value.includes(CampaignOperationState.OP_ERROR)) {
    selectedStates.value.push(CampaignOperationState.OP_ERROR)
  } else if (selectedStates.value.includes(CampaignOperationState.OP_ERROR) && !selectedStates.value.includes(CampaignOperationState.ERROR)) {
    selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_ERROR), 1)
  }
  if (selectedStates.value.includes(CampaignOperationState.CANCELLED) && !selectedStates.value.includes(CampaignOperationState.OP_CANCELLED)) {
    selectedStates.value.push(CampaignOperationState.OP_CANCELLED)
  } else if (selectedStates.value.includes(CampaignOperationState.OP_CANCELLED) && !selectedStates.value.includes(CampaignOperationState.CANCELLED)) {
    selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_CANCELLED), 1)
  }
  const deliveredOrPendingStates: Array<string> = [
    CampaignOperationState.RETRY,
    CampaignOperationState.OP_RETRY,
    CampaignOperationState.OP_PENDING,
    CampaignOperationState.DELIVERED,
  ]

  if (selectedStates.value.includes(CampaignOperationState.OP_RUNNING)
    && exceptsAll<string>(selectedStates.value, deliveredOrPendingStates)
  ) {
    Array.prototype.push.apply(selectedStates.value, deliveredOrPendingStates)
  } else if (!selectedStates.value.includes(CampaignOperationState.OP_RUNNING)
    && includesAll<string>(selectedStates.value, deliveredOrPendingStates)
  ) {
    selectedStates.value = selectedStates.value.filter(state => deliveredOrPendingStates.includes(state as CampaignOperationState))
  }

  emit('change', Filters.STATE, selectedStates.value.slice(0))
}

function removeFilterDefinition(items?: Ref<Array<string>>, uid?: string) {
  if (uid !== undefined && items !== undefined) {
    const index = items.value.findIndex(value => value === uid)
    if (index !== -1) {
      const filterDefinition = items.value[index]
      switch (filterDefinition) {
        case CampaignOperationState.OP_RUNNING:
        case CampaignOperationState.ERROR:
        case CampaignOperationState.CANCELLED:
          removeSelectedStatesMultipleValues(filterDefinition)
          break
        default:
          items.value.splice(index, 1)
          break
      }
    }
  }
}

function removeSelectedStatesMultipleValues(filterDefinition: string) {
  const valuesToRemove: Ref<Array<CampaignOperationState>> = ref([])
  switch (filterDefinition) {
    case CampaignOperationState.OP_RUNNING:
      valuesToRemove.value.push(
        CampaignOperationState.OP_RUNNING,
        CampaignOperationState.RETRY,
        CampaignOperationState.OP_RETRY,
        CampaignOperationState.OP_PENDING,
        CampaignOperationState.DELIVERED,
      )
      break
    case CampaignOperationState.ERROR:
      valuesToRemove.value.push(CampaignOperationState.ERROR, CampaignOperationState.OP_ERROR)
      break
    case CampaignOperationState.CANCELLED:
      valuesToRemove.value.push(CampaignOperationState.CANCELLED, CampaignOperationState.OP_CANCELLED)
      break
  }
  valuesToRemove.value.forEach(value => selectedStates.value.splice(selectedStates.value.indexOf(value), 1))
}

function stateTag(stateValue: string): UniqueLabel | undefined {
  const stateI18nKey = `operation.${stateValue === CampaignOperationState.PENDING ? 'delivered' : stateValue.toLowerCase()}`

  return filtersUtils.getTag(
    props.tagLabel,
    stateValue,
    t(stateI18nKey),
  )
}

function updateSelectedFilterTags() {
  const tagFilters: Array<UniqueLabel> = []

  for (const stateValueCurrent of selectedStates.value) {
    if (Object.values(FilterTagsToDisplay).includes(stateValueCurrent)) {
      const tag = stateTag(stateValueCurrent)
      if (tag) {
        tagFilters.push(tag)
      }
    }
  }

  emit('update:modelValue', selectedStates.value)
  emit('update:selectedTags', tagFilters)
}

const onSelectedStateChange = () => {
  emit('update:modelValue', selectedStates.value)
  updateSelectedFilterTags()
}

const updatedAppliedFilterTags = () => {
  const tagFilters: Array<UniqueLabel> = []
  const stateValue = props.filters.get(Filters.STATE)

  if (stateValue?.value) {
    for (const stateValueCurrent of stateValue.value) {
      if (Object.values(FilterTagsToDisplay).includes(stateValueCurrent)) {
        const tag = stateTag(stateValueCurrent)
        if (tag) {
          tagFilters.push(tag)
        }
      }
    }
  }
  emit('update:appliedTags', tagFilters)
}

const clearFilter = () => {
  selectedStates.value = []
}

const discardFilter = () => {
  selectedStates.value = (props.filters.get(Filters.STATE)?.value as string[] | undefined ?? []).slice(0)
}

const deleteFilter = (label: string, uid?: string) => {
  removeFilterDefinition(selectedStates, uid ?? label)
}

const setSelectedFilterMap = (filterSelection: FiltersSelection, selectedStates: string[]): void => {
  filterSelection.set(Filters.STATE, {
    key: 'state',
    operator: OPERATOR_EQUAL,
    value: selectedStates,
  })
}

defineExpose({
  applyFilter,
  onAppliedFilterChange,
  clearFilter,
  discardFilter,
  deleteFilter,
  setSelectedFilterMap,
})

watch(() => selectedStates.value, onSelectedStateChange, { deep: true })
watch(() => props.filters, updatedAppliedFilterTags, { deep: true })

</script>
<template>
  <div class="rounded-lg bg-white text-gray-900 p-6">
    <lxc-checkbox
      v-for="stateOption in operationStateOptionsViewModel"
      :key="stateOption.value"
      v-model="selectedStates"
      :label="stateOption.label"
      :value="stateOption.value"
      class="mb-2"
    />
  </div>
</template>
