<script setup lang="ts">
/**
 * Display the information of a firmware
 * Fetch the firmware if compatible hardware & software versions are not defined in firmware
 * Trigger update event after fetched
 */
import type { FirmwareI } from '@lxc/app-device-types'
import firmwareService from '~/services/firmware.service'
import LxcError from '~/utils/LxcError'
import ILxcInfo from '~icons/lxc/info'

const props = defineProps<{
  firmware?: FirmwareI|null
}>()

const emit = defineEmits(['update:firmware'])

const error: Ref<LxcError|undefined|null> = ref(null)
const isLoading = ref<boolean>(true)

const computedFirmware = computed({
  get(): FirmwareI|undefined|null {
    return props.firmware
  },
  set(firmware: FirmwareI|undefined|null) {
    emit('update:firmware', firmware)
  },
})

const hardwareVersions = computed(() => computedFirmware.value?.hardwareVersions.join(', '))
const firmwareVersions = computed(() => computedFirmware.value?.firmwareVersions.join(', '))

/**
 * Fetch the firmware
 */
const fetchFirmware = async() => {
  isLoading.value = true

  // Fetch the firmware only if the attributes firmwareVersions & hardwareVersions are not presents
  if (props.firmware && (!props.firmware.firmwareVersions || !props.firmware.hardwareVersions)) {
    const response = await firmwareService.getFirmwareByUuid(props.firmware.uuid)

    if (LxcError.check(response)) {
      computedFirmware.value = null
      error.value = response
    } else {
      computedFirmware.value = response
    }
  }

  isLoading.value = false
}

watch(() => props.firmware, fetchFirmware)

onMounted(fetchFirmware)
</script>

<template>
  <div
    v-if="computedFirmware"
    class="sticky bottom-0"
  >
    <lxc-container
      :is-loading="isLoading"
      :error="error"
      :px="0"
      :py="0"
      :loader-size="14"
    >
      <lxc-card
        class="mt-3"
      >
        <div
          class="flex align-middle"
        >
          <div
            class="border rounded-xl border-gray-300 p-3 self-center"
          >
            <ILxcInfo class="text-gray-500" />
          </div>
          <div
            class="ms-3"
          >
            <p
              class="font-semibold text-gray-700"
            >
              {{ $t('firmware.firmwareInfo.compatibility.title') }}
            </p>
            <p
              class="text-gray-600"
            >
              {{ $t('firmware.firmwareInfo.compatibility.description') }}
            </p>
          </div>
        </div>
        <div
          class="flex gap-5 mt-3"
        >
          <div class="flex-1">
            <p
              class="text-gray-700"
            >
              {{ $t('firmware.firmwareInfo.equipmentTypeAttached') }}
            </p>
            <lxc-input
              v-model="computedFirmware.range"
              label=""
              type="text"
              disabled
            />
          </div>
          <div class="flex-1">
            <p
              class="text-gray-700"
            >
              {{ $t('firmware.firmwareInfo.compatibleHardwareVersions') }}
            </p>
            <lxc-input
              v-model="hardwareVersions"
              label=""
              type="text"
              disabled
            />
          </div>
          <div class="flex-1">
            <p
              class="text-gray-700"
            >
              {{ $t('firmware.firmwareInfo.compatibleFirmwareVersions') }}
            </p>
            <lxc-input
              v-model="firmwareVersions"
              label=""
              type="text"
              disabled
            />
          </div>
        </div>
      </lxc-card>
    </lxc-container>
  </div>
</template>
