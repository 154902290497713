<script setup lang="ts">
import { useAcl } from 'vue-simple-acl'
import { ACL_ROLES } from '~/types'
import ILxcPlus from '~icons/lxc/plus'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'

const { t } = useI18n()
const acl = useAcl()
const canManageFirmwares = computed(() => acl.can(ACL_ROLES.MANAGE_FIRMWARES))
const canViewFirmwares = computed(() => acl.can(ACL_ROLES.DISPLAY_FIRMWARES))
const triggerSearch = ref<boolean>()
const addNewFirmwareShown = ref<boolean>(false)

const refreshLoad = () => {
  triggerSearch.value = true
  triggerSearch.value = false
}

const onAddNewFirmware = () => {
  addNewFirmwareShown.value = true
}

</script>
<template>
  <lxc-container
    v-if="canViewFirmwares"
  >
    <div
      class="flex start pb-8"
    >
      <lxc-breadcrumb
        :name="BreadcrumbConfig.FIRMWARE.title"
        class="grow shrink basis-0"
        readonly
      />
      <lxc-button
        v-if="(canManageFirmwares)"
        :icon="ILxcPlus"
        :title="t('firmware.newFirmware')"
        class="self-center"
        html-type="button"
        type="primary"
        @click="onAddNewFirmware"
      >
        {{ $t('firmware.newFirmware') }}
      </lxc-button>
    </div>
    <lxc-firmwares-list
      :trigger-search="triggerSearch"
    />
    <lxc-firmware-form
      v-model:side-canvas-shown="addNewFirmwareShown"
      @upload="refreshLoad"
    />
  </lxc-container>
</template>
