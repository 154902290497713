<script lang='ts' setup>
import { useAcl } from 'vue-simple-acl'
import { useCertificatesACLRoles } from '~/composables/useCertificates'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import { useCertificateTags } from '~/composables/useCertificateTags'
import { ACL_ROLES } from '~/types'
import ILxcPlus from '~icons/lxc/plus'

const keystoreTabKey = 'keystoreTab'
const truststoreTabKey = 'truststoreTab'

const { can } = useAcl()

const {
  canManageCertificates,
} = useCertificatesACLRoles()

const {
  error: tagsError,
  getTags,
  isLoading: isTagsLoading,
  tags,
} = useCertificateTags()

const selectedTab: Ref<string> = ref(keystoreTabKey)
const canCreateKeystoreCertificate: ComputedRef<boolean> = computed((): boolean => canManageCertificates() && !isTagsLoading.value)
const triggerSearch = ref<boolean>()
const generateSelfSignedCertificateFormShown: Ref<boolean> = ref(false)
const generateCSRFormShown: Ref<boolean> = ref(false)

const canCreateTruststoreCertificate: ComputedRef<boolean> = computed((): boolean => canManageCertificates() && !isTagsLoading.value)
const importTruststoreCertificate: Ref<boolean> = ref(false)

const refreshLoad = () => {
  triggerSearch.value = !triggerSearch.value
}
const onGenerateSelfSignedCertificate = () => {
  generateSelfSignedCertificateFormShown.value = true
}

const onGenerateCSR = () => {
  generateCSRFormShown.value = true
}

const onImportTrustStoreCertificate = () => {
  importTruststoreCertificate.value = true
}

const onTabSelected = (_: number, name?: string) => {
  selectedTab.value = name ?? ''
}

const isTabVisible: ComputedRef<boolean> = computed((): boolean => can(ACL_ROLES.DVT_CACRT_VIEW))

const isKeystoreTabSelected: ComputedRef<boolean> = computed((): boolean => selectedTab.value === keystoreTabKey)

const isTruststoreTabSelected: ComputedRef<boolean> = computed((): boolean => selectedTab.value === truststoreTabKey)

onMounted(() => {
  if (isTabVisible.value) {
    getTags()
  }
})
</script>

<template>
  <lxc-container>
    <div
      class="flex justify-between"
    >
      <lxc-breadcrumb
        :name="BreadcrumbConfig.CERTIFICATES.title"
        readonly
      />

      <div
        v-if="isTabVisible"
        class="self-end"
      >
        <div
          v-if="isKeystoreTabSelected"
          class="flex justify-end gap-4"
        >
          <lxc-button
            :disabled="!canCreateKeystoreCertificate"
            :icon="ILxcPlus"
            :title="$t('certificates.keystore.generateSelfSignedCertificate')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onGenerateSelfSignedCertificate"
          >
            {{ $t('certificates.keystore.generateSelfSignedCertificate') }}
          </lxc-button>
          <lxc-button
            :disabled="!canCreateKeystoreCertificate"
            :icon="ILxcPlus"
            :title="$t('certificates.keystore.generateCSR')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onGenerateCSR"
          >
            {{ $t('certificates.keystore.generateCSR') }}
          </lxc-button>
        </div>
        <div v-if="isTruststoreTabSelected">
          <lxc-button
            :disabled="!canCreateTruststoreCertificate"
            :icon="ILxcPlus"
            :title="$t('certificates.truststore.uploadCertificate')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onImportTrustStoreCertificate"
          >
            {{ $t('certificates.truststore.uploadCertificate') }}
          </lxc-button>
        </div>
      </div>
    </div>

    <lxc-tabs
      v-if="isTabVisible"
      class="py-8"
      @on-tab-selected="onTabSelected"
    >
      <lxc-tab-pane
        :label="$t('certificates.tab.keystore')"
        :name="keystoreTabKey"
      >
        <lxc-keystore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <lxc-keystore-self-signed-certificate-generation-form
          v-model:side-canvas-shown="generateSelfSignedCertificateFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
        <lxc-keystore-csr-generation-form
          v-model:side-canvas-shown="generateCSRFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('certificates.tab.truststore')"
        :name="truststoreTabKey"
      >
        <lxc-truststore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <lxc-truststore-upload-certificate
          v-model:side-canvas-shown="importTruststoreCertificate"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
