<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { UniqueLabel } from '@lxc/app-device-common'
import ILxcAlertCircle from '~icons/lxc/alert-circle'
import { VALIDATION_NUMBER } from '~/constants/constants'
import type { Option } from '~/types'
import LxcError from '~/utils/LxcError'
import type { TruststoreCertificateDetailForm, TruststoreCertificateUploadForm } from '~/types/truststore'

const props = defineProps <{
  disabled?: boolean
  edition?: boolean
  isTagsLoading: boolean
  modelValue: TruststoreCertificateUploadForm | TruststoreCertificateDetailForm
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const emit = defineEmits([
  'update:modelValue',
])

const selectedTags = computed({
  get(): Array<UniqueLabel> {
    return props.modelValue.tags ?? []
  },
  set(paramTags: Array<UniqueLabel>) {
    const tmpCertificate = props.modelValue
    tmpCertificate.tags = paramTags
    emit('update:modelValue', tmpCertificate)
  },
})

// deep copy of modelValue including subject property
const form = reactive<TruststoreCertificateUploadForm | TruststoreCertificateDetailForm>(props.modelValue)

let initTagOptionsPending = props.tags === undefined || !props.tags?.length
const tagOptions: Ref<Array<Option>> = ref([])

async function initTagOptions() {
  if (props.isTagsLoading) {
    initTagOptionsPending = true
  } else if (!LxcError.check(props.tagsError)) {
    initTagOptionsPending = props.tags === undefined

    tagOptions.value = props.tags?.map((tag: string) => {
      return {
        disabled: props.disabled || !!selectedTags.value.find(selectedTag => selectedTag.uid === tag),
        label: tag,
        value: tag,
      }
    }) ?? []
  }
}

watch(() => form, (newForm: TruststoreCertificateUploadForm | TruststoreCertificateDetailForm) => {
  emit('update:modelValue', newForm)
}, {
  deep: true,
})

watch(() => props.isTagsLoading, (isLoading) => {
  if (!isLoading && initTagOptionsPending) {
    initTagOptions()
  }
})

const isTagError: ComputedRef<boolean> = computed(() => {
  return LxcError.check(props.tagsError)
})

onMounted(initTagOptions)
</script>

<template>
  <lxc-form-item
    v-if="modelValue"
    :label="$t('certificates.trustChain.label')"
  >
    <lxc-certificate-trust-chain
      :data="modelValue.trustChain"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.alias')"
    prop="alias"
  >
    <lxc-input
      v-model="form.alias"
      type="text"
      :disabled="disabled || edition"
      :max-length="VALIDATION_NUMBER.ALIAS_MAX_LENGTH"
      required
    />
  </lxc-form-item>

  <lxc-alert
    v-if="isTagError"
    :icon="ILxcAlertCircle"
    type="error"
  >
    <template #title>
      {{ $t('certificates.tags.label') }}
      {{ $t('certificates.tags.label') }}
    </template>
    <p>{{ tagsError?.toError()?.message ?? '' }}</p>
  </lxc-alert>

  <lxc-form-item
    :label="$t('certificates.tags.label')"
    prop="tags"
  >
    <lxc-tag-select
      v-model="selectedTags"
      :options="tagOptions"
      :placeholer="$t('certificates.form.tags.placeholder')"
      :delete-tooltip="$t('certificates.form.tags.remove.tooltip')"
      :disabled="disabled || isTagError"
    />
  </lxc-form-item>
</template>
