import type { FunctionalComponent, SVGAttributes } from 'vue'
import { PATHS } from '~/constants/paths'
import ILxCampaign from '~icons/lxc-business/campaign'
import ILxcHomeLine from '~icons/lxc-custom/home-line'
import ILxcInfoFilledItalic from '~icons/lxc-custom/info-filled-italic'
import ILxcCompass from '~icons/lxc/compass'
import ILxcCopy from '~icons/lxc/copy'
import ILxcMapPin from '~icons/lxc/map-pin'
import ILxcSettings from '~icons/lxc/settings'
import ILxcTablet from '~icons/lxc/tablet'
import ILxcUser from '~icons/lxc/user'

export interface Breadcrumb {
  title: string
  disabled: boolean
  href: string
  icon?: FunctionalComponent<SVGAttributes>
}

export const BreadcrumbConfig: Record<string, Breadcrumb> = {
  APPLICATION: {
    title: 'page.applications.title',
    disabled: false,
    href: PATHS.APPLICATIONS,
    icon: ILxcCopy,
  },
  SECTOR: {
    title: 'page.sectors.title',
    disabled: false,
    href: PATHS.SECTORS,
    icon: ILxcMapPin,
  },
  DEVICE: {
    title: 'page.devices.title',
    disabled: false,
    href: PATHS.DEVICES_DVTM_ESOFT,
    icon: ILxcTablet,
  },
  DTWIN: {
    title: 'page.dtwins.title',
    disabled: false,
    href: PATHS.DTWINS,
    icon: ILxcTablet,
  },
  CAMPAIGN: {
    title: 'page.campaigns.title',
    disabled: false,
    href: PATHS.CAMPAIGNS,
    icon: ILxCampaign,
  },
  DASHBOARD: {
    title: 'page.dashboard.title',
    disabled: false,
    href: '/',
    icon: ILxcHomeLine,
  },
  PROFILE: {
    title: 'page.profiles.title',
    disabled: false,
    href: PATHS.USER_MANAGEMENT_PROFILES,
    icon: ILxcUser,
  },
  GROUP: {
    title: 'page.userGroups.title',
    disabled: false,
    href: PATHS.USER_MANAGEMENT_GROUPS,
    icon: ILxcUser,
  },
  LOGS: {
    title: 'page.logs.title',
    disabled: false,
    href: PATHS.LOGS,
    icon: ILxcCompass,
  },
  PARAMETERS: {
    title: 'page.parameters.title',
    disabled: false,
    href: '',
    icon: ILxcSettings,
  },
  CERTIFICATES: {
    title: 'page.certificates.title',
    disabled: false,
    href: PATHS.PARAMETERS_CERTIFICATES,
  },
  PKI_CONNECTOR: {
    title: 'page.pkiConnector.title',
    disabled: false,
    href: PATHS.PARAMETERS_CONNECTOR_PKI,
  },
  FIRMWARE: {
    title: 'page.firmware.title',
    disabled: false,
    href: PATHS.PARAMETERS_FIRMWARES,
  },
  USER_MANAGEMENT: {
    title: 'page.users.title',
    disabled: false,
    href: PATHS.USER_MANAGEMENT,
    icon: ILxcUser,
  },
  DEVICE_CONFIGURATION: {
    title: 'page.deviceConfiguration.title',
    disabled: false,
    href: PATHS.PARAMETERS_DEVICE_CONFIGURATION,
  },
  CERT_ALERT_REPORTING: {
    title: 'page.certificateAlertReporting.title',
    disabled: false,
    href: PATHS.PARAMETERS_CERTIFICATE_ALERT_REPORTING,
  },
  APPLICATION_MGT: {
    title: 'page.applicationMgts.title',
    disabled: false,
    href: PATHS.PARAMETERS_APPLICATIONS,
  },
  LICENSE: {
    title: 'page.license.title',
    disabled: false,
    href: PATHS.PARAMETERS_LICENSE,
  },
  USER_ACCOUNT: {
    title: 'page.userAccount.title',
    disabled: false,
    href: PATHS.USER_ACCOUNT,
    icon: ILxcUser,
  },
  ABOUT: {
    title: 'page.about.title',
    disabled: false,
    href: PATHS.ABOUT,
    icon: ILxcInfoFilledItalic,
  },
}
