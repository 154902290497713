import { INPUT_NOT_DEFINED, NO_CONTENT } from '~/components/dtwins/dtwinsForm/LxcDtwinsFormType'

export class LxcDtwinsDescriptionIdCardType {
  readonly friendlyName: string
  readonly type: string
  readonly serialNumber: string
  readonly hardwareVersion: string
  readonly isAllowedToEdit: boolean
  readonly doesUpdateSucceed: boolean | undefined

  constructor({
    friendlyName,
    type,
    serialNumber,
    hardwareVersion,
    isAllowedToEdit,
    doesUpdateSucceed,
  }: {
    friendlyName?: string
    type?: string
    serialNumber?: string
    hardwareVersion?: string
    isAllowedToEdit?: boolean
    doesUpdateSucceed?: boolean
  }) {
    this.friendlyName = friendlyName ?? INPUT_NOT_DEFINED
    this.type = type ?? NO_CONTENT
    this.serialNumber = serialNumber ?? NO_CONTENT
    this.hardwareVersion = hardwareVersion ?? NO_CONTENT
    this.isAllowedToEdit = isAllowedToEdit ?? false
    this.doesUpdateSucceed = doesUpdateSucceed
  }
}
