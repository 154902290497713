<script setup lang="ts">
import type { GroupsUser, NewUserDataI, UserDataI, UserGroupI } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { DEFAULT_PAGE_SIZE } from '~/constants/constants'
import userGroupService from '~/services/userGroups.service'
import usersService from '~/services/users.service'
import { useConfigStore } from '~/stores/useConfigStore'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcCheck from '~icons/lxc/check'

const props = defineProps<{
  disabled: boolean
  userId: string | null
  user: UserDataI | NewUserDataI | null
}>()
const emit = defineEmits(['previous', 'submit'])

const { t } = useI18n()
const { isModeAD } = storeToRefs(useConfigStore())

const userGroupSearch: Ref<string> = ref('')
const initialUserGroups = props.user?.userGroups ?? []
const userGroupsForm = reactive<GroupsUser[]>([...initialUserGroups])

/**
 * Search user groups
 * @param query
 * @param showResults
 */
async function onSearchUserGroups(query: string, showResults: (data: UserGroupI[]) => void) {
  const response = await userGroupService.getUserGroups(1, DEFAULT_PAGE_SIZE, query)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.error)
  } else {
    showResults(response.data ?? [])
  }
}

/**
 * Add user to group
 */
async function onAddUserGroup(selectedUserGroup: GroupsUser) {
  const existingUserGroup = userGroupsForm.find((userGroup: GroupsUser) => userGroup.code === selectedUserGroup.code)

  if (!existingUserGroup) {
    userGroupsForm.push(selectedUserGroup)
    userGroupSearch.value = ''
  }
}

/**
 * Remove user from group
 */
async function onRemoveUserGroup(userGroup: GroupsUser) {
  const userGroupIndex = userGroupsForm.indexOf(userGroup)

  if (userGroupIndex !== -1) {
    userGroupsForm.splice(userGroupIndex, 1)
  }
}

async function onSaveUserGroups() {
  if (props.userId) {
    const userGroupCodesToRemove = (initialUserGroups ?? []).map((userGroup: GroupsUser) => userGroup.code!)
    const userGroupCodesToAdd = (userGroupsForm ?? []).map((userGroup: GroupsUser) => userGroup.code!)

    const removeResult = await usersService.removeUserGroups(props.userId, userGroupCodesToRemove)
    const addResult = await usersService.addUserGroups(props.userId, userGroupCodesToAdd)
    const hasError = [removeResult, addResult].some(result => LxcError.check(result))

    if (hasError) {
      showNotificationError(t(NotificationKey.saveError))
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess))
      emit('submit')
    }
  }
}
</script>

<template>
  <el-form
    label-position="left"
    label-width="200px"
  >
    <el-form-item
      v-if="!isModeAD"
      :label="$t('input.addUserGroup')"
    >
      <el-autocomplete
        key="id"
        v-model="userGroupSearch"
        :fetch-suggestions="onSearchUserGroups"
        value-key="label"
        class="inline-input"
        :disabled="disabled"
        @select="onAddUserGroup"
      />
    </el-form-item>

    <el-container>
      <el-tag
        v-for="userGroup in userGroupsForm"
        :key="userGroup.code"
        class="mx-1"
        size="large"
        :closable="!disabled"
        @close="onRemoveUserGroup(userGroup)"
      >
        {{ userGroup.label }}
      </el-tag>
    </el-container>

    <el-row
      v-if="!isModeAD"
      justify="space-between"
      class="button-wrapper"
    >
      <el-button
        :disabled="disabled"
        plain
        @click="$emit('previous')"
      >
        {{ $t('button.previous') }}
      </el-button>
      <el-button
        :disabled="disabled"
        type="primary"
        :icon="ILxcCheck"
        @click="onSaveUserGroups"
      >
        {{ $t('button.validate') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<style scoped lang="scss">
:deep(.el-autocomplete) {
  width: 100%;
}
</style>
