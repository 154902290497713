import { defineStore } from 'pinia'
import type { SectorI } from '@lxc/app-device-types'
import SectorsService from '~/services/sectors.service'

export const useSectorStore = defineStore('sectorStore', () => {
  const allSectors = ref<SectorI[]>([])
  const selectedSectors = ref<SectorI[]>([])
  const availableSectors = ref<SectorI[]>([])
  const selectedSectorCodes = ref<string[]>([])

  function getSectorCodes(sectors: SectorI[]): string[] {
    return sectors.map(sector => sector.code)
  }

  function changeSelection(newSelection: SectorI[]) {
    selectedSectors.value = newSelection
    selectedSectorCodes.value = getSectorCodes(newSelection)
  }

  async function retrieveAllSectors() {
    allSectors.value = SectorsService.flattenSectors(await SectorsService.getSectors())
  }

  async function retrieveAvailableSectors() {
    availableSectors.value = await SectorsService.getAllFlattenSectors()
  }

  async function resetSectorsSelection() {
    selectedSectors.value = []
    selectedSectorCodes.value = []
    await retrieveAvailableSectors()
  }

  return {
    getSectorCodes,
    allSectors,
    selectedSectors,
    selectedSectorCodes,
    availableSectors,
    changeSelection,
    resetSectorsSelection,
    retrieveAllSectors,
    retrieveAvailableSectors,
  }
})
