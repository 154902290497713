<script setup lang="ts">
import type { Ref } from 'vue'
import { deviceTypeDeclinationMap } from '~/constants/deviceFilters.config'
import type { Option } from '~/types'

const emit = defineEmits(['selected', 'update:modelValue'])
const loading: Ref<boolean> = ref(false)

const props = defineProps<{
  modelValue: string[]
  types: string[]
}>()

const selectedItem = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    // Note: we are using destructuring assignment syntax here.
    emit('update:modelValue', selected)
  },
})

const declinations = computed((): Option[] => {
  if ((props.types?.length ?? 0) === 0) {
    return []
  } else {
    let options: Option[] = []
    for (const type of props.types) {
      const deviceTypeDelination = deviceTypeDeclinationMap[type]
      if (deviceTypeDelination) {
        options = options.concat(deviceTypeDelination.deviceDeclinations.map((declination: string): Option => {
          return {
            label: declination,
            value: declination,
          }
        }))
      }
    }
    return options
  }
})

</script>

<template>
  <lxc-filter-checklist
    v-model="selectedItem"
    :data="declinations"
    :is-loading="loading"
    :empty-text="$t('logs.filters.devices-dvtm-esoft.model.empty')"
    :header="$t('device.model')"
    prop="label"
    checkbox-prop="value"
  />
</template>
