<script setup lang="ts">
import type { ApiListResult, UserGroupI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useElTable } from '~/composables/useElTable'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import userGroupsService from '~/services/userGroups.service'
import LxcError from '~/utils/LxcError'
import ILxcPlus from '~icons/lxc/plus'

const router = useRouter()
const { getCellStyle } = useElTable()

const userGroups: Ref<null | ApiListResult<UserGroupI>> = ref(null)
const query = ref<string>('')
const error: Ref<LxcError | null> = ref(null)

const isLoading = ref(true)

onMounted(getUserGroups)

/**
 * Retrieve userGroups
 * @param page
 */
async function getUserGroups(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  isLoading.value = true
  error.value = null

  const userGroupsResponse = await userGroupsService.getUserGroups(page, pageSize, query.value)

  if (LxcError.check(userGroupsResponse)) {
    error.value = userGroupsResponse
  } else {
    userGroups.value = userGroupsResponse
  }

  isLoading.value = false
}

/**
 * Redirect when selecting à specific userGroup
 * @param userGroup
 * @param label
 */
function onSelectUserGroup(userGroup: UserGroupI, { label }: { label: string }) {
  if (label) {
    router.push(`${PATHS.USER_MANAGEMENT_GROUPS}/${userGroup.code}`)
  }
}

/**
 * Redirect when creating a userGroup
 */
function onCreateUserGroup() {
  router.push(PATHS.USER_MANAGEMENT_GROUPS_NEW)
}

function onInputChange(event: string) {
  query.value = event
}

function onClear() {
  query.value = ''
  getUserGroups()
}

function reloadWithContext() {
  getUserGroups(userGroups.value?.context?.page, userGroups.value?.context?.pageSize)
}

</script>

<template>
  <el-container
    data-cy="lxc-user-group-list-component"
    direction="vertical"
  >
    <lx-toolbar>
      <lx-search-bar
        :search-query="query"
        :is-loading="isLoading && !userGroups"
        :search-placeholder="$t('userGroup.search')"
        @clear="onClear"
        @input-change="onInputChange"
        @search="getUserGroups"
      />
      <el-button
        data-cy="buttonCreateUserGroup"
        type="primary"
        size="large"
        :icon="ILxcPlus"
        @click="onCreateUserGroup"
      >
        {{ $t('userGroup.button.create') }}
      </el-button>
    </lx-toolbar>

    <lxc-container
      :error="error"
      :is-loading="isLoading && !userGroups"
    >
      <el-table
        v-loading="isLoading"
        :data="userGroups?.data"
        :fit="true"
        :empty-text="$t('userGroup.empty')"
        row-class-name="clickable"
        :cell-style="getCellStyle"
        @row-click="onSelectUserGroup"
      >
        <el-table-column
          prop="label"
          :label="$t('table.header.label')"
          data-cy="col-label"
        />
        <el-table-column
          :label="$t('table.header.sectors')"
          data-cy="col-sectors"
          class-name="no-no-wrap"
        >
          <template #default="scope">
            <el-tag
              v-for="(sector, index) in scope.row.sectors"
              :key="index"
            >
              {{ sector?.label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="nbUsers"
          :label="$t('table.header.nbUsers')"
          data-cy="col-nbUsers"
        />
        <el-table-column width="50">
          <template #default="scope">
            <lxc-user-group-actions
              :user-group="scope.row"
              @change="reloadWithContext"
            />
          </template>
        </el-table-column>
      </el-table>

      <lxc-pagination
        :type-of-element="$t('pagination.group', userGroups?.context?.totalCount ?? 0)"
        :context="userGroups?.context"
        @update:current-page="getUserGroups"
      />
    </lxc-container>
  </el-container>
</template>
