<script setup lang="ts">
import { FilterInputType } from '~/types'
import type { FilterFormSection, FilterSelectionValue, Filters, FiltersSelection, Option } from '~/types'
import LxcError from '~/utils/LxcError'
const { t } = useI18n()

const props = defineProps<{
  filters: FiltersSelection
  isTagsLoading: boolean
  tagFilterName: Filters
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const emit = defineEmits(['change', 'enter'])

function setFilter(filter: Filters, value: FilterSelectionValue) {
  emit('change', filter, value)
}

const tagLabel = t('certificates.tag')
const emptyTagsLabel = t('certificates.tags.empty')
let initTagOptionsPending = false
const tagOptions: Ref<Array<Option>> = ref([])

async function initTagOptions() {
  const vTags = props.tags ?? []

  if (props.isTagsLoading) {
    initTagOptionsPending = true
    tagOptions.value = []
  } else if (!LxcError.check(props.tagsError)) {
    initTagOptionsPending = props.tags === undefined

    tagOptions.value = vTags.sort().map((tag: string) => {
      return {
        label: tag,
        value: tag,
      }
    }) ?? []
  } else {
    tagOptions.value = []
  }
}

// computed are necessary for the FilterFormSection
const tagOptionsLoading: ComputedRef<boolean> = computed(() => props.isTagsLoading)
const tagOptionsError: ComputedRef<LxcError | undefined | null> = computed(() => props.tagsError)

const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: props.tagFilterName,
    footerEnabled: true,
    footerId: 'status-footer',
    header: tagLabel,
    id: 'status',
    inputType: FilterInputType.CHECKBOX,
    loading: tagOptionsLoading,
    loadingError: tagOptionsError,
    menuLabel: tagLabel,
    options: tagOptions,
    emptyOptionsText: emptyTagsLabel,
    tagPrefix: tagLabel,
  },
]

watch(() => props.isTagsLoading, (isLoading) => {
  if (!isLoading && initTagOptionsPending) {
    initTagOptions()
  }
})

onMounted(() => {
  initTagOptions()
})
</script>

<template>
  <div class="relative mb-4">
    <lxc-filters
      :filter-sections="filterFormSections"
      :filters="filters"
      :is-entity-loading="isTagsLoading"
      is-button-right
      @change="setFilter"
      @enter="emit('enter', $event)"
    />
  </div>
</template>
