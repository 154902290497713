<script lang='ts' setup>
import ILxcArrowUpCircle from '~icons/lxc/arrow-up-circle'
import ILxcPlus from '~icons/lxc/plus'
import ILxcTrash2 from '~icons/lxc/trash-2'

const props = defineProps<{
  isTopLevel: boolean
  isLastLevel: boolean
  isAffected: boolean
}>()

const emit = defineEmits(['create', 'update', 'remove'])
const { t } = useI18n()

enum Action {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

/**
 * When selecting an action in menu
 * @param action
 */
function onSelectAction(action: Action) {
  switch (action) {
    case Action.CREATE:
      emit('create')
      break
    case Action.UPDATE:
      emit('update')
      break
    case Action.REMOVE:
      emit('remove')
      break
  }
}
</script>

<template>
  <lxc-dropdown
    v-if="isAffected"
    @command="onSelectAction"
  >
    <el-dropdown-item
      v-if="!isLastLevel"
      :command="Action.CREATE"
      :icon="ILxcPlus"
    >
      {{ $t('sectors.button.newSub') }}
    </el-dropdown-item>
    <el-dropdown-item
      v-if="!isTopLevel"
      :command="Action.UPDATE"
      :icon="ILxcArrowUpCircle"
    >
      {{ $t('sectors.button.update') }}
    </el-dropdown-item>
    <el-dropdown-item
      v-if="!isTopLevel"
      :command="Action.REMOVE"
      :icon="ILxcTrash2"
    >
      {{ $t('sectors.button.remove') }}
    </el-dropdown-item>
  </lxc-dropdown>
</template>
