<script setup lang="ts">
import type { AppDeviceState, DeviceI } from '@lxc/app-device-types'
import { ErrorCode, LicenseStatus, LicenseStatusFeatureByDeviceTypeStatus } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'
import { useUpdateState } from '~/composables/useUpdateState'
import DeviceService from '~/services/device.service'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'
import { FeaturesName } from '~/types/deviceFeatures'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcClose from '~icons/lxc/x'

const { t } = useI18n()
const route = useRoute()
const { createDataForStateUpdate, getState } = useUpdateState()

const device: Ref<DeviceI | null> = ref(null)
const error: Ref<LxcError | null> = ref(null)
const deviceId = route.params.id
const isLoading = ref(true)
const activeName = ref('description')
const [confirmUpdateStateVisible, toggleConfirmUpdateState] = useToggle()
const disabledConfirm = ref(false)

// Metadata are only availabe for device type=S4G-PL and with advanced fleet management feature from license activated for this device type
const canAccessMetadata = computed(() => {
  if (device.value?.model?.type !== 'S4G-PL') {
    return false
  }

  const { licenseStatus } = useLicenseStatusStore()

  if (!licenseStatus) {
    return false
  }

  // the license is not active or suspended
  if (![LicenseStatus.ACTIVATED, LicenseStatus.WARNING_DATE].includes(licenseStatus.statusOfLicense)) {
    return false
  }

  // check the feature advanced fleet management status for the device type
  let isFeatureAdvancedFleetManagementActivatedForDeviceType = false

  if (licenseStatus?.featureByDeviceTypes) {
    for (const feature of licenseStatus.featureByDeviceTypes) {
      if (
        feature.name === FeaturesName.ADVANCED_FLEET_MGT
        && feature.deviceType === device.value?.model?.type
        && [LicenseStatusFeatureByDeviceTypeStatus.ACTIVATED, LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE].includes(feature.status)
      ) {
        isFeatureAdvancedFleetManagementActivatedForDeviceType = true
        break
      }
    }
  }

  return isFeatureAdvancedFleetManagementActivatedForDeviceType
})

const options = computed(() => {
  if (device.value) {
    return createDataForStateUpdate(device.value.state!, 'device', device.value.name!)
  }
})

async function fetchDevice() {
  const response = await DeviceService.getDeviceDetails(deviceId.toString())

  if (LxcError.check(response)) {
    device.value = null
    error.value = response
  } else {
    device.value = response
  }

  isLoading.value = false
}

async function updateState(state: AppDeviceState, id: string) {
  disabledConfirm.value = true
  const response = await DeviceService.updateDeviceState(id, getState(state))

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DEACTIVATION_CONFLICT) {
      showNotificationError(t('device.updateState.deactivate.error'))
    } else {
      response.notify(NotificationKey.saveError)
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
  }

  toggleConfirmUpdateState()
  disabledConfirm.value = false
  await fetchDevice()
}

const connectivityStateBadge = computed(() => {
  return device.value?.connectivity?.state === 'inactive' ? 'danger' : 'secondary'
})

onMounted(fetchDevice)
</script>

<template>
  <lxc-container>
    <div
      class="flex justify-between"
    >
      <div class="flex items-center">
        <lxc-breadcrumb
          :name="device?.name ?? ''"
          back
        />
        <div
          v-if="device"
          class="self-center ml-4"
        >
          <lxc-badge
            :type="connectivityStateBadge"
          >
            {{ $t(`filters.connectivityState.${device?.connectivity?.state}`) }}
          </lxc-badge>
        </div>
      </div>
      <lxc-button
        v-if="device"
        class="self-end"
        type="tertiary"
        :icon="ILxcClose"
        :title="options?.activatedButton"
        @click="toggleConfirmUpdateState(true)"
      >
        {{ options?.activatedButton }}
      </lxc-button>
    </div>

    <lxc-container
      :is-loading="isLoading"
      :error="error"
      :px="0"
    >
      <lxc-tabs
        v-model="activeName"
      >
        <lxc-tab-pane
          :label="$t('device.description.title')"
          name="description"
        >
          <lxc-device-description
            :device="device"
          />
        </lxc-tab-pane>
        <lxc-tab-pane
          v-if="canAccessMetadata"
          :label="$t('device.additionalMetadata.title')"
          name="additionalMetadata"
        >
          <lxc-device-metadata
            :device="device!"
          />
        </lxc-tab-pane>
        <lxc-tab-pane
          :label="$t('device.certificate')"
          name="certificate"
        >
          <lxc-device-certificate
            :device="device!"
          />
        </lxc-tab-pane>
        <lxc-tab-pane
          :label="$t('device.firmwareForm.title')"
          name="firmware"
        >
          <lxc-device-firmware
            :device="device!"
          />
        </lxc-tab-pane>
      </lxc-tabs>
    </lxc-container>
  </lxc-container>
  <lxc-confirm-modal
    v-if="options"
    :is-dialog-visible="confirmUpdateStateVisible"
    :title="options.title"
    :description="options.description"
    :info="options.warning"
    :disabled-confirm="disabledConfirm"
    @cancel="toggleConfirmUpdateState"
    @confirm="updateState(device?.state!, device?.id!)"
  />
</template>
