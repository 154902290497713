<script setup lang="ts">
import type { Ref } from 'vue'
import type { TestPkiConnectivity } from '@lxc/app-device-types'
import { ConnectivityState } from '@lxc/app-device-types'
import type { PkiConfigFormI } from '~/types'
import PkiConnectorService from '~/services/pkiConnector.service'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'

const { t } = useI18n()
const router = useRouter()

const isLoading = ref(false)
const pkiConfiguration = ref<PkiConfigFormI | null>()
const error: Ref<LxcError | null> = ref(null)
let pkiConfigurationBackup: PkiConfigFormI | undefined | null

onMounted(async() => await fetchPkiConnectorConfiguration())

async function fetchPkiConnectorConfiguration() {
  isLoading.value = true
  const response = await PkiConnectorService.getPkiConnectorConfiguration()

  if (LxcError.check(response)) {
    error.value = response
    pkiConfigurationBackup = null
  } else {
    pkiConfiguration.value = response
    pkiConfigurationBackup = Object.assign<PkiConfigFormI, PkiConfigFormI>({}, response)
  }

  isLoading.value = false
}

async function updatePkiConnectorConfiguration(formContent: PkiConfigFormI) {
  isLoading.value = true
  const response = await PkiConnectorService.updatePkiConnectorConfiguration(formContent)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    pkiConfigurationBackup = pkiConfiguration.value ? Object.assign({}, pkiConfiguration.value) : null
  }

  isLoading.value = false
}

function isConnectivitySucceeded(testResult?: TestPkiConnectivity): boolean {
  return [
    testResult?.connectivity,
    testResult?.authentication,
    testResult?.rootCA,
  ].every(test => test === ConnectivityState.OK)
}

function cancelForm() {
  pkiConfiguration.value = pkiConfigurationBackup ? Object.assign({}, pkiConfigurationBackup) : null
  showNotificationSuccess(t(NotificationKey.cancel))
}

function sendForm(event: PkiConfigFormI) {
  pkiConfiguration.value = event
  updatePkiConnectorConfiguration(event)
}
</script>

<template>
  <lxc-container>
    <lxc-breadcrumb
      :name="BreadcrumbConfig.PKI_CONNECTOR.title"
      readonly
    />
    <lxc-container
      :error="error"
      :is-loading="isLoading"
      :px="0"
    >
      <lxc-pki-connector-form
        :pki-configuration="pkiConfiguration"
        @cancel="cancelForm"
        @validate="sendForm"
      />
    </lxc-container>
  </lxc-container>
</template>
