import type { RouteLocation, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import LxcUnexpectedError from '../components/errors/LxcUnexpectedError.vue'
import LxcDashboard from '../components/dashboard/LxcDashboard.vue'
import LxcApplications from '../components/applications/applicationList/LxcApplications.vue'
import LxcApplicationDetail from '../components/applications/applicationDetail/LxcApplicationDetail.vue'
import LxcDevices from '../components/devices/deviceList/LxcDevices.vue'
import LxcDeviceDetail from '../components/devices/deviceDetail/LxcDeviceDetail.vue'
import LxcDtwins from '../components/dtwins/dtwinsList/LxcDtwins.vue'
import LxcDtwinsForm from '../components/dtwins/dtwinsForm/LxcDtwinsForm.vue'
import LxcCampaigns from '../components/campaigns/campaignList/LxcCampaigns.vue'
import LxcCampaignDetail from '../components/campaigns/campaignDetail/LxcCampaignDetail.vue'
import LxcCampaignReport from '../components/campaigns/campaignReport/LxcCampaignReport.vue'
import LxcLogs from '../components/logs/logList/LxcLogs.vue'
import LxcUserGroups from '../components/userManagement/userGroup/userGroupList/LxcUserGroups.vue'
import LxcUserGroupDetail from '../components/userManagement/userGroup/userGroupDetail/LxcUserGroupDetail.vue'
import LxcCertificateAlertReporting
  from '../components/parameters/certificate-alert-reporting/LxcCertificateAlertReporting.vue'
import LxcCertificateManagement from '../components/parameters/certificate-management/LxcCertificateManagement.vue'
import LxcCertificates from '../components/parameters/certificates/LxcCertificates.vue'
import LxcConnectorPki from '../components/parameters/connector-pki/LxcConnectorPki.vue'
import LxcFirmwares from '../components/parameters/firmwares/LxcFirmwares.vue'
import LxcProfiles from '../components/userManagement/profile/profileList/LxcProfiles.vue'
import LxcUsers from '../components/userManagement/user/userList/LxcUsers.vue'
import LxcUserForm from '../components/userManagement/user/userForm/LxcUserForm.vue'
import LxcSectors from '../components/sectors/sectorList/LxcSectors.vue'
import LxcSectorDetail from '../components/sectors/sectorDetail/LxcSectorDetail.vue'
import LxcSectorDetailDevices from '../components/sectors/sectorDetail/LxcSectorDetailDevices.vue'
import LxcSectorDetailDtwins from '../components/sectors/sectorDetail/LxcSectorDetailDtwins.vue'
import LxcSectorDetailUsers from '../components/sectors/sectorDetail/LxcSectorDetailUsers.vue'
import LxcSectorDetailUserGroups from '../components/sectors/sectorDetail/LxcSectorDetailUserGroups.vue'
import LxcSectorDetailApplications from '../components/sectors/sectorDetail/LxcSectorDetailApplications.vue'
import LxcApplicationMgt from '../components/parameters/applicationMgt/LxcApplicationMgt.vue'
import LxcCampaignCreationForm from '~/components/campaigns/campaignForm/LxcCampaignForm.vue'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import { PATHS } from '~/constants/paths'
import { useUserSession } from '~/stores/useUserSession'
import { ACL_ROLES } from '~/types'
import LxcLicense from '~/components/parameters/license/LxcLicense.vue'
import LxcUserAccount from '~/components/userAccount/LxcUserAccount.vue'
import LxcAbout from '~/components/about/LxcAbout.vue'
import { isFeatureEnabled } from '~/composables/useFeature'
import { APP_FEATURES } from '~/types/appFeatures'
import LxcNotFoundVue from '~/components/errors/LxcNotFound.vue'
import LxcUnauthorized from '~/components/errors/LxcUnauthorized.vue'
import { useConfirmLeavePageStore } from '~/stores/useConfirmLeavePageStore'
import LxcConnectMobileApp from '~/components/connectMobileApp/LxcConnectMobileApp.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: LxcDashboard,
    meta: {
      auth: true,
      breadcrumb: BreadcrumbConfig.DASHBOARD,
    },
  },
  {
    path: PATHS.ERROR,
    component: LxcUnexpectedError,
    meta: {
      layout: 'preLogin',
    },
  },
  {
    path: PATHS.APPLICATIONS,
    component: LxcApplications,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BreadcrumbConfig.APPLICATION,
    },
  },
  {
    path: `${PATHS.APPLICATIONS}/:appId`,
    component: LxcApplicationDetail,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BreadcrumbConfig.APPLICATION,
    },
  },
  {
    path: PATHS.DEVICES_DVTM_ESOFT,
    component: LxcDevices,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DEVICE,
    },
  },
  {
    path: `${PATHS.DEVICES_DVTM_ESOFT}/:id`,
    component: LxcDeviceDetail,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DEVICE,
    },
  },
  {
    path: PATHS.DTWINS,
    component: LxcDtwins,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.DTWINS) && canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS}/:id`,
    component: LxcDtwinsForm,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.DTWINS) && canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: PATHS.CAMPAIGNS,
    component: LxcCampaigns,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:id/${PATHS.DETAILS_SUBPATH}`,
    component: LxcCampaignDetail,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:id/${PATHS.EDIT_SUBPATH}`,
    component: LxcCampaignCreationForm,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.MANAGE_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: PATHS.CAMPAIGNS_CREATE,
    component: LxcCampaignCreationForm,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.MANAGE_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS_REPORTS}/:id`,
    component: LxcCampaignReport,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: PATHS.LOGS,
    component: LxcLogs,
    meta: {
      anyCan: (to: any, from: any, anyCan: any) => isFeatureEnabled(APP_FEATURES.LOGS) && canWithRedirection(anyCan, [
        ACL_ROLES.CYBER_LOGS_VIEW,
        ACL_ROLES.DEVICE_LOGS_VIEW,
        ACL_ROLES.DEVICE_FLEET_LOGS_VIEW,
        ACL_ROLES.SYSTEM_LOGS_VIEW,
      ]),
      auth: true,
      breadcrumb: BreadcrumbConfig.LOGS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_GROUPS,
    component: LxcUserGroups,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.GROUP,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_GROUPS}/:code`,
    component: LxcUserGroupDetail,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.GROUP,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATE_ALERT_REPORTING,
    component: LxcCertificateAlertReporting,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.CONFIGURE_NOTIFICATION),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_DEVICE_CONFIGURATION,
    component: LxcCertificateManagement,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.MANAGE_CERTIFICATES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_CONNECTOR_PKI,
    component: LxcConnectorPki,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.CONFIGURE_PKI),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES,
    component: LxcCertificates,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    component: LxcCertificates,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_KEYSTORE}/:uuid`,
    component: LxcCertificates,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_FIRMWARES,
    component: LxcFirmwares,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.FOTA) && canWithRedirection(can, ACL_ROLES.DISPLAY_FIRMWARES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}/:uuid`,
    component: LxcCertificates,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}`,
    component: LxcCertificates,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_FIRMWARES}/:uuid`,
    component: LxcFirmwares,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.FOTA) && canWithRedirection(can, ACL_ROLES.MANAGE_FIRMWARES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_PROFILES,
    component: LxcProfiles,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.PROFILE,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_PROFILES}/:profileCode`,
    component: LxcProfiles,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.PROFILE,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT,
    component: LxcUsers,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_USERS,
    component: LxcUsers,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_USERS}/:id`,
    component: LxcUserForm,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
  },
  {
    path: PATHS.SECTORS,
    component: LxcSectors,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: `${PATHS.SECTORS}/:code`,
    component: LxcSectorDetail,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
    props: true,
    redirect: (to: RouteLocation) => {
      return `${PATHS.SECTORS}/${to.params.code}/${PATHS.DEVICES_DVTM_ESOFT_SUBPATH}`
    },
    children: [
      {
        path: PATHS.DEVICES_DVTM_ESOFT_SUBPATH,
        component: LxcSectorDetailDevices,
        meta: {
          can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
        },
      },
      {
        path: PATHS.DTWINS_SUBPATH,
        component: LxcSectorDetailDtwins,
        meta: {
          can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.DTWINS) && canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
        },
      },
      {
        path: PATHS.APPLICATIONS_SUBPATH,
        component: LxcSectorDetailApplications,
        meta: {
          can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
        },
      },
      {
        path: PATHS.GROUPS_SUBPATH,
        component: LxcSectorDetailUserGroups,
        meta: {
          can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
        },
      },
      {
        path: PATHS.USERS_SUBPATH,
        component: LxcSectorDetailUsers,
        meta: {
          can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
        },
      },
    ],
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS,
    component: LxcApplicationMgt,
    meta: {
      anyCan: (to: any, from: any, anyCan: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(anyCan, [
        ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW,
        ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW,
      ]),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_CREATE,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS}/:id`,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP}/:clientId`,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP}/:clientId`,
    component: LxcApplicationMgt,
    meta: {
      can: (to: any, from: any, can: any) => isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) && canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.ABOUT,
    component: LxcAbout,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.ABOUT,
    },
  },
  {
    path: PATHS.PARAMETERS_LICENSE,
    component: LxcLicense,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_ACCOUNT,
    component: LxcUserAccount,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.NOT_FOUND,
    component: LxcNotFoundVue,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.UNAUTHORIZED,
    component: LxcUnauthorized,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.CONNECT_MY_MOBILE_APP,
    component: LxcConnectMobileApp,
    meta: {
      can: (to: any, from: any, can: any) => canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
    },
  },
]

export const router = createRouter({
  history: createWebHistory(import.meta.env.LXC_FI_DEVICE_BASENAME),
  routes: setupLayouts(routes),
})

async function canWithRedirection(fn: any, permission: ACL_ROLES | ACL_ROLES[]) {
  const perm = fn(permission)
  if (!perm) {
    await router.push({ path: PATHS.UNAUTHORIZED })
  }
  return perm
}

/**
 * Manage route checks
 * Set current user if needed and clear code/session_state query params
 * Return false if not auth after current user should have been set to avoid showing part of application before auto redirecting to keycloak login
 */
router.beforeEach(
  async(to: RouteLocationNormalized): Promise<RouteLocationNormalized | boolean | void> => {
    const { setCurrentUser, isAuthenticated } = useUserSession()

    if (to.meta.auth && !isAuthenticated) {
      await setCurrentUser(to)

      delete to.query.code
      delete to.query.session_state
      // set isAuthenticated again to fix infinite loading time
      const { isAuthenticated } = useUserSession()

      if (isAuthenticated) {
        return to
      } else {
        return false
      }
    }

    // case checking display confirm
    const confirmLeavePageStore = useConfirmLeavePageStore()

    // need confirm before leaving the page
    if (confirmLeavePageStore.isActivated()) {
      // let redirection pass if it is done after confirm
      if (confirmLeavePageStore.confirmed()) {
        confirmLeavePageStore.reset()
      }
      // otherwise display the leaving page confirm message
      else {
        // provide the destination route in order to redirect after confirm
        confirmLeavePageStore.show(to.fullPath)
        return false // stop redirection for confirm
      }
    }
  },
)
