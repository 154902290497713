<script setup lang="ts">
</script>

<template>
  <lxc-container :px="0">
    <lxc-breadcrumb class="px-8" />
    <lxc-components-version />
    <lxc-download-logs />
  </lxc-container>
</template>
