<script setup lang="ts">
import { type DtwinI } from '@lxc/app-device-types'
import {
  FirmwareUpdateSourceOption,
  LxcDtwinsFirmwareInformationType,
} from '~/components/dtwins/dtwinsForm/firmware/information/LxcDtwinsFirmwareInformationType'
import { APP_NAME } from '~/constants/constants'
import { formatIsoDate } from '~/utils/date-tools'
import ILxcPlus from '~icons/lxc/plus'
import { useUserSession } from '~/stores/useUserSession'
import { Roles } from '~/types/roles'

const props = defineProps<{
  dtwin?: DtwinI | null
}>()

const store = useUserSession()
const isAllowedToUpdate = computed(() => store.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE)
  && store.userSession?.roles.includes(Roles.DVTM_FIRM_VIEW))

const { t } = useI18n()
const updateFirmwareModalShow: Ref<boolean> = ref(false)
const reloadOperationList: Ref<boolean> = ref(false)
const dateFormat: string = t('dateFormat.datetime')
let firmwareUpdateSource: string | undefined
const firmwareUpdateSourceCode = props.dtwin?.features.firmware?.reported?.firmwareUpdateSource

if (firmwareUpdateSourceCode !== undefined && !isNaN(firmwareUpdateSourceCode)) {
  switch (firmwareUpdateSourceCode) {
    case FirmwareUpdateSourceOption.FACTORY:
      firmwareUpdateSource = t('dtwins.form.firmware.information.attributes.firmwareUpdateSourceOption.factory')
      break
    case FirmwareUpdateSourceOption.APP:
      firmwareUpdateSource = APP_NAME
      break
    case FirmwareUpdateSourceOption.MOBILE_APP:
      firmwareUpdateSource = t('dtwins.form.firmware.information.attributes.firmwareUpdateSourceOption.mobileApp')
      break
    default:
      // Keep being undefined. This case will be processed in the LxcDtwinsFirmwareInformation.vue component
      break
  }
}

const information: Ref<LxcDtwinsFirmwareInformationType> = ref(
  new LxcDtwinsFirmwareInformationType({
    firmwareVersion: props.dtwin?.features.firmware?.reported?.firmwareVersion ?? undefined,
    firmwareUpdateSource,
    latestFirmwareUpdate: props.dtwin?.features.firmware?.reported?.$metadata?.firmwareVersion?.$lastValueUpdate
      ? formatIsoDate(props.dtwin?.features.firmware?.reported?.$metadata?.firmwareVersion?.$lastValueUpdate, dateFormat)
      : undefined,
  }),
)

const showUpdateFirmwareModal = () => {
  updateFirmwareModalShow.value = true
}

const doReloadOperationList = () => {
  reloadOperationList.value = !reloadOperationList.value
}

</script>

<template>
  <LxcDtwinsFirmwareInformation
    :data="information"
  />
  <div>
    <lxc-button
      type="primary"
      html-type="button"
      :icon="ILxcPlus"
      :title="$t('dtwins.form.firmware.updateFirmware')"
      :disabled="!isAllowedToUpdate"
      @click="showUpdateFirmwareModal"
    >
      {{ $t('dtwins.form.firmware.updateFirmware') }}
    </lxc-button>
  </div>

  <h3 class="mt-12">
    {{ $t('section.operations.title') }}
  </h3>
  <lxc-dtwin-firmware-operation-list
    :dtwin="dtwin"
    :reload="reloadOperationList"
  />
  <lxc-dtwin-update-firmware-modal
    v-model:show="updateFirmwareModalShow"
    :dtwin="dtwin"
    @change="doReloadOperationList"
  />
</template>
