import type { Dashboard, DashboardServices } from '@lxc/app-device-types'
import RestService from './rest.service'
import { getDateInTheFutureInMonth } from '~/utils/date-tools'

class DashboardService extends RestService {
  private DASHBOARD_BASE_URL = '/dashboard'
  private DASHBOARD_SERVICES_BASE_URL = '/dashboard/services'
  /**
   * Get dashboard data
   */
  getDashboard(sectors: string[], dashboardExpireSoonInMonth: number): Promise<Dashboard> {
    const params = {
      validity: getDateInTheFutureInMonth(dashboardExpireSoonInMonth, true),
      sectors,
    }

    return this.$api.get(this.DASHBOARD_BASE_URL, { params })
  }

  /**
   * Get dashboard services status
   */
  getDashboardServices(): Promise<DashboardServices> {
    return this.$api.get(this.DASHBOARD_SERVICES_BASE_URL)
  }
}

export default new DashboardService()
