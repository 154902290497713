import type { FilterOptions } from '~/types'

export const connectivityOptions: FilterOptions = {
  label: 'filters.connectivity',
  options: [
    {
      value: 'Active',
      label: 'filters.connectivityState.active',
    },
    {
      value: 'Inactive',
      label: 'filters.connectivityState.inactive',
    },
  ],
}
