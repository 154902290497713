<script lang="ts" setup>
import type { DtwinI, DtwinOperationLPP4I, PaginatedListI } from '@lxc/app-device-types'
import { DtwinOperationStatus, OperationServiceOrigin } from '@lxc/app-device-types'
import { useDtwinFirmareUnitaryUpdateOperations } from '~/composables/useDtwinOperationManager'
import { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE, DTWIN_OPERATION_TYPE } from '~/constants/constants'
import { Filters } from '~/types'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  dtwin?: DtwinI | null
  reload: boolean
}>()

const { t } = useI18n()

const {
  error,
  fetchData,
  isLoading,
  onSearch,
  results,
  search,
  setFilter,
  sort,
} = useDtwinFirmareUnitaryUpdateOperations(SearchMode.FILTER_SEARCH, '-launchPlannedAt')

const resultsLPP4 = results as Ref<PaginatedListI<DtwinOperationLPP4I>>

/**
 * Retrieve firmwares and set selected firmware if needed
 * @param page
 * @param pageSize
 */
async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  await fetchData(page, pageSize)
}

function initializeDefaultFilters() {
  setFilter(Filters.DTWIN_OPERATION_UID, props.dtwin?.uid ?? '')
  setFilter(Filters.DTWIN_OPERATION_TYPE, DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE)
  setFilter(Filters.DTWIN_OPERATION_SERVICE_ORIGIN, OperationServiceOrigin.UNITARY)
}

const reloadWithContext = () => {
  loadData(results.value?.pagination?.page, results.value?.pagination?.pageSize)
}

initializeDefaultFilters()

const onDeviceChange = async(dtwin?: DtwinI | null) => {
  setFilter(Filters.DTWIN_OPERATION_UID, dtwin?.uid ?? '')
  if (dtwin) {
    search()
  }
}

onSearch(loadData)

const dateFormat = t('operation.dateFormat')

const translatedError: ComputedRef<Error | null | undefined> = computed(() => error.value?.toError())

const getStatusTranslationFromEnum = (status: DtwinOperationStatus) => {
  let translation
  switch (status) {
    case DtwinOperationStatus.RUNNING:
      translation = t('operation.running')
      break
    case DtwinOperationStatus.SCHEDULED:
      translation = t('operation.scheduled')
      break
    case DtwinOperationStatus.QUEUED:
      translation = t('operation.queued')
      break
    case DtwinOperationStatus.DELIVERED:
      translation = t('operation.delivered')
      break
    case DtwinOperationStatus.RETRY:
      translation = t('operation.retry')
      break
    case DtwinOperationStatus.PENDING:
      translation = t('operation.pending')
      break
    case DtwinOperationStatus.CANCELLING:
      translation = t('operation.cancelling')
      break
    case DtwinOperationStatus.CANCELLED:
      translation = t('operation.cancelled')
      break
    case DtwinOperationStatus.FAILED:
      translation = t('operation.failed')
      break
    case DtwinOperationStatus.EXPIRED:
      translation = t('operation.expired')
      break
    case DtwinOperationStatus.DONE:
      translation = t('operation.done')
      break
  }
  if (translation === undefined) {
    translation = t('operation.unknown')
  }
  return translation
}

watch(() => props.dtwin, onDeviceChange)
watch(() => props.reload, reloadWithContext)
</script>

<template>
  <lxc-table
    :is-loading="isLoading"
    :error="translatedError"
    :data="resultsLPP4?.results"
    :empty-text="$t('operation.empty')"
    :context="resultsLPP4?.pagination"
    data-cy="product-details-operations-table"
    @change-page-and-page-size="loadData"
  >
    <lxc-table-column
      prop="customInputParameters.targetVersion"
      :label="$t('operation.firmware.update.version')"
    />

    <lxc-table-column
      prop="createdAt"
      :label="$t('operation.createdAt')"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.createdAt, dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="launchPlannedAt"
      :label="$t('operation.launchedAt')"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.launchPlannedAt, dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="modifiedAt"
      :label="$t('operation.updatedAt')"
    >
      <template #default="scope">
        {{ scope.row.updatedAt ? formatIsoDate(scope.row.updatedAt, dateFormat) : '' }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="status"
      :label="$t('operation.state')"
    >
      <template #default="scope">
        {{ getStatusTranslationFromEnum(scope.row.status) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      v-if="dtwin"
      prop="actions"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-dtwin-operation-actions
          :dtwin="dtwin"
          :operation="scope.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
