import type { ApiListResult } from '@lxc/app-device-types/dist/interfaces/api.interface'
import type {
  MobileInterfaceI,
} from '@lxc/app-device-types/dist/interfaces/mobile.interface'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'
import { DEFAULT_QR_CODE_HEIGHT, DEFAULT_QR_CODE_WIDTH } from '~/constants/constants'

class MobileInterfaceService extends RestService {
  protected BASE_URL = '/interfaces/mobile'

  async create(mobileInterface: MobileInterfaceI): Promise<MobileInterfaceI> {
    return this.$api.post(this.BASE_URL, { mobileInterface })
  }

  async findWithPagination(page: number, pageSize: number, search?: string | FiltersSelection, sort?: string|null): Promise<ApiListResult<MobileInterfaceI>> {
    const params = { page, pageSize, sort, search }

    return this.$api.get(this.BASE_URL, { params })
  }

  async findById(id: string): Promise<MobileInterfaceI> {
    return this.$api.get(`${this.BASE_URL}/${id}`)
  }

  async update(id: string, mobileInterface: MobileInterfaceI): Promise<MobileInterfaceI> {
    return this.$api.patch(`${this.BASE_URL}/${id}`, { mobileInterface })
  }

  async delete(id: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${id}`)
  }

  async generateQRCode(clientId: string, qrCodeWidth?: number, qrCodeHeight?: number): Promise<any> {
    const params = {
      qrCodeWidth: qrCodeWidth ?? DEFAULT_QR_CODE_WIDTH,
      qrCodeHeight: qrCodeHeight ?? DEFAULT_QR_CODE_HEIGHT,
    }
    return this.$api.get(`${this.BASE_URL}/${clientId}/qrcode`, {
      params,
      headers: { Accept: 'image/png' },
      responseType: 'arraybuffer',
    })
  }

  async downloadOfflineConfigurationZip(clientId: string, password: string): Promise<any> {
    return this.$api.post(
      `${this.BASE_URL}/${clientId}/configuration/offline`,
      { password },
      {
        headers: { Accept: 'application/octet-stream' },
        responseType: 'arraybuffer',
        timeout: Infinity,
      },
    )
  }
}

export default new MobileInterfaceService()
