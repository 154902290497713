// Explode this object into typescript object if needed. This config is specific to the Chart.js zoom plugin.
// Check this resource for more details: https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html
export const DEFAULT_CHARTJS_ZOOM_SUB_CONFIG: any = {
  wheel: {
    enabled: true,
  },
  pinch: {
    enabled: true,
  },
  drag: {
    enabled: true,
  },
  mode: 'x',
}
