<script setup lang="ts">
import { watch } from 'vue'
import type LxcTable from '@lxc/app-device-common/src/components/LxcTable.vue'
import type { KeystoreCertificateI } from '@lxc/app-device-types'
import type { LocationQuery } from 'vue-router'
import { useCertificatesACLRoles } from '~/composables/useCertificates'
import { useKeystore } from '~/composables/useKeystore'
import { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import { Filters } from '~/types'
import { formatIsoDate } from '~/utils/date-tools'
import type LxcError from '~/utils/LxcError'

const props = defineProps<{
  triggerSearch?: boolean
  isTagsLoading: boolean
  selectedCertificate?: KeystoreCertificateI | null
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const route = useRoute()
const router = useRouter()
const emit = defineEmits(['update:selectedCertificate', 'openCertificateDetail'])

const {
  isLoading,
  results,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  onSearch,
  search,
} = useKeystore(SearchMode.URL_SEARCH)

const { canManageCertificates, canViewCertificates } = useCertificatesACLRoles()

const searchQuery = ref<string>((filters.get(Filters.KEYSTORE_ALIAS) ?? '') as string)
const alias = ref<string | undefined | null>()
const tableRef = ref<InstanceType<typeof LxcTable>>()
const currentSelectedCertificate: Ref<KeystoreCertificateI | undefined | null> = ref()
const certificateDetailShown = ref<boolean>(false)
const canEditKeystoreCertificate: ComputedRef<boolean> = computed((): boolean => canManageCertificates() && !props.isTagsLoading)

/**
 * Retrieve selected certificates
 */

const findCertificate = (list: KeystoreCertificateI[], paramCertificate?: KeystoreCertificateI | null) => {
  return list.find((currentCertificate: KeystoreCertificateI) => currentCertificate.alias === paramCertificate?.alias)
}

const setSelection = () => {
  if (tableRef.value?.data) {
    tableRef.value.setCurrentRow(findCertificate(tableRef.value.data, props.selectedCertificate))
  }
}

watch(() => props.selectedCertificate, setSelection)

/**
 * Update the selected certificate or redirect to certificate details page
 */
function onSelectCertificate(certificate: KeystoreCertificateI) {
  alias.value = certificate.alias

  if (certificate?.alias && canViewCertificates()) {
    const targetUrl: Record<string, string | string[] | LocationQuery> = {
      path: `${PATHS.PARAMETERS_CERTIFICATES_KEYSTORE}/${certificate?.alias}`,
      query: route.query,
    }
    router.push(targetUrl)

    certificateDetailShown.value = true
  }
}

const onCloseCertificateDetail = () => {
  alias.value = null
  router.push({
    path: PATHS.PARAMETERS_CERTIFICATES,
    query: route.query,
  })
}

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_CERTIFICATES_KEYSTORE)) {
    alias.value = route?.params?.uuid as string | undefined

    if (alias.value) {
      certificateDetailShown.value = true
    }
  }
}

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue
  setFilter(Filters.KEYSTORE_ALIAS, searchQuery.value)
}

const onClearSearch = () => {
  setFilter(Filters.KEYSTORE_ALIAS, '')
  search()
}

/**
 * Retrieve certificates and set selected certificate if necesary
 * @param page
 * @param pageSize
 */
async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  await fetchData(page, pageSize)
  setTimeout(setSelection, 0)
}

function reloadWithContext() {
  loadData(results.value?.context?.page, results.value?.context?.pageSize)
}

watch(() => props.triggerSearch, search)
watch(() => currentSelectedCertificate.value, () => {
  emit('update:selectedCertificate', currentSelectedCertificate.value)
})
watch(() => props.selectedCertificate, () => {
  currentSelectedCertificate.value = props.selectedCertificate
})

onMounted(async() => {
  onSearch(loadData)
  initDisplayFromRoute()
})
</script>

<template>
  <lxc-search-bar
    :search-query="searchQuery"
    :search-placeholder="$t('certificates.keystore.filters.searchByAlias')"
    class="grow shrink basis-0"
    @clear="onClearSearch"
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-certificates-filters
    :filters="filters"
    :tags="tags"
    :is-tags-loading="isTagsLoading"
    :tag-filter-name="Filters.KEYSTORE_TAGS"
    :tags-error="tagsError"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    :data="results?.data"
    :context="results?.context"
    :is-loading="isLoading && !results"
    :error="error?.toError()"
    clickable
    :empty-text="$t('certificates.noCertificate')"
    @change-page-and-page-size="fetchData"
    @row-click="onSelectCertificate"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      prop="alias"
      :label="$t('certificates.alias')"
    />
    <lxc-table-column
      prop="tags"
      :label="$t('certificates.tag')"
      class="!py-0"
    >
      <template
        #default="scope"
      >
        <lxc-tag-set
          :data="scope.row.tags"
          :max="3"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="$t('certificates.expiration.timestamp.label')"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.expirationDate, $t('dateFormat.datetime')) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :width="72"
      class="!py-0"
    >
      <template
        #default="scope"
      >
        <lxc-keystore-csr-requires-upload-certificate
          :certificate="scope.row"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :width="184"
      class="!py-0"
    >
      <template #default="scope">
        <div class="flex gap-2">
          <lxc-keystore-list-download-action
            :certificate="scope.row"
          />
          <lxc-keystore-list-delete-action
            :certificate="scope.row"
            @change="reloadWithContext"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>

  <lxc-keystore-certificate-detail
    v-model:side-canvas-shown="certificateDetailShown"
    :alias="alias"
    :disabled="!canEditKeystoreCertificate"
    :is-tags-loading="isTagsLoading"
    :tags-error="tagsError"
    :tags="tags"
    @close="onCloseCertificateDetail"
  />
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
