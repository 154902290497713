import type { App, DirectiveBinding, Plugin } from 'vue'
import LxcDragNDropManager from './DragNDropManager'
import type { LxcDragnDropEventType, LxcDragnDropItem, LxcDragnDropOption } from '~/types/dragndrop'

/**
 * Plugin to bind the LxcDragNDropManager with VueJS nodes
 * directives:
 * - `lxc-drag-n-drop:drag` for the callback when the item dragging is starting
 * - `lxc-drag-n-drop:drop` for the callback when the item is dropped
 * - `lxc-drag-move` to specify the link between the start and the end.
 * That allow to have several drag and drop in a SPA.
 * - `lxc-drop-option` is to set the settings. See the Dragula library documtation for the options.
 * There is one option more: called `transform` to allow to create a new different node.
 */

let dragndropItemsManager: LxcDragNDropManager

export const LxcDragNDrop: Plugin<any[]> = {
  install: (app: App<any>, ..._options: any) => {
    dragndropItemsManager = new LxcDragNDropManager()

    app.directive('lxc-drag-n-drop', {
      beforeMount: (element: HTMLElement, binding: DirectiveBinding) => {
        const item: LxcDragnDropItem = {
          element,
          eventType: binding.arg as LxcDragnDropEventType,
          handler: binding.value as (target: Element, source?: Element, sibling?: Element) => void,
          hasBindTouchEvents: false,
          isMainDroppable: false,
        }
        dragndropItemsManager.add(item)
      },
      mounted: (element: HTMLElement) => {
        dragndropItemsManager.bindEvents(element)
      },
      unmounted: (element: HTMLElement) => {
        dragndropItemsManager.delete(element)
      },
    })

    app.directive('lxc-drag-move', {
      beforeMount: (element: HTMLElement, binding: DirectiveBinding<string>) => {
        dragndropItemsManager.setMove(element, binding.value)
      },
    })

    app.directive('lxc-drop-option', {
      beforeMount: (element: HTMLElement, binding: DirectiveBinding<LxcDragnDropOption>) => {
        dragndropItemsManager.setOption(element, binding.value)
      },
    })
  },
}
