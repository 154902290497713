<script setup lang="ts">
import { CampaignOperationState, OperationState } from '@lxc/app-device-types'

const props = defineProps<{
  state?: string
}>()

const { t } = useI18n()

const operationStatusColor = computed(() => {
  switch (props.state) {
    case OperationState.RUNNING:
    case OperationState.DONE:
    case CampaignOperationState.DONE:
    case CampaignOperationState.DELIVERED:
    case CampaignOperationState.OP_RUNNING:
      return '--rounded-icon-bg:#E7F7D0; --rounded-icon-border-color: #5F7349'
    case OperationState.CANCELLING:
    case OperationState.CANCELLED:
    case OperationState.EXPIRED:
    case OperationState.FAILED:
    case CampaignOperationState.CANCELLING:
    case CampaignOperationState.CANCELLED:
    case CampaignOperationState.ERROR:
    case CampaignOperationState.EXPIRED:
    case CampaignOperationState.OP_ERROR:
    case CampaignOperationState.OP_CANCELLING:
    case CampaignOperationState.OP_CANCELLED:
      return '--rounded-icon-bg:#FEEEEE; --rounded-icon-border-color: #C4314B'
    case OperationState.PENDING:
    case OperationState.RETRY:
    case CampaignOperationState.RETRY:
    case CampaignOperationState.OP_RETRY:
    case CampaignOperationState.PENDING:
    case CampaignOperationState.OP_PENDING:
    case CampaignOperationState.PAUSED:
      return '--rounded-icon-bg:#FFEBDA; --rounded-icon-border-color:#B36F37'
  }
})

const translatedOperationState = computed(() => {
  switch (props.state) {
    case OperationState.DONE:
    case CampaignOperationState.DONE:
      return t('operation.done')
    case OperationState.FAILED:
    case CampaignOperationState.ERROR:
    case CampaignOperationState.OP_ERROR:
      return t('operation.error')
    case OperationState.CANCELLING:
    case CampaignOperationState.CANCELLING:
    case CampaignOperationState.OP_CANCELLING:
      return t('operation.cancelling')
    case OperationState.CANCELLED:
    case CampaignOperationState.CANCELLED:
    case CampaignOperationState.OP_CANCELLED:
      return t('operation.cancelled')
    case CampaignOperationState.PAUSED:
      return t('operation.paused')
    case OperationState.RUNNING:
    case OperationState.RETRY:
    case CampaignOperationState.RETRY:
    case CampaignOperationState.OP_RETRY:
    case CampaignOperationState.OP_PENDING:
    case CampaignOperationState.DELIVERED:
    case CampaignOperationState.OP_RUNNING:
      return t('operation.running')
    case OperationState.PENDING:
    case CampaignOperationState.PENDING:
      return t('operation.scheduled')
    case OperationState.EXPIRED:
    case CampaignOperationState.EXPIRED:
      return t('operation.expired')
  }
})
</script>

<template>
  <div class="row-container">
    <div
      class="round-icon"
      :style="operationStatusColor"
    />
    <p> {{ translatedOperationState }} </p>
  </div>
</template>

<style scoped lang="scss">

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .round-icon {
    width: 10px;
    height:10px;
    border-radius: 50%;
    background-color: var(--rounded-icon-bg);
    border: 1px solid var(--rounded-icon-border-color);
    margin-right: 1rem;
  }
  p {
    margin: 0;
  }
}
</style>
