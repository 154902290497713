import { useFetchPage } from '~/composables/useFetchPage'
import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import { useSort } from '~/composables/useSort'
import operationManagerService from '~/services/operationManager.service'
import type { FiltersSelection } from '~/types'
import { Filters, FiltersType, OPERATOR_RSQL_ARRAY_CONTAINS, OPERATOR_RSQL_EQUAL, OPERATOR_RSQL_IN } from '~/types'

export function useDtwinFirmareUnitaryUpdateOperations(searchMode?: SearchMode, defaultSort?: string) {
  const service = operationManagerService.getOperations.bind(operationManagerService)

  const appliedFilters: FiltersSelection = new Map([
    [Filters.DTWIN_OPERATION_UID, { key: 'deviceTwinUid', operator: OPERATOR_RSQL_EQUAL, value: [] }],
    [Filters.DTWIN_OPERATION_TYPE, { key: 'type', operator: OPERATOR_RSQL_IN, value: [] }],
    [Filters.DTWIN_OPERATION_SERVICE_ORIGIN, { key: 'serviceOrigin', operator: OPERATOR_RSQL_EQUAL, value: [] }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RSQL, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  sort.value = defaultSort
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  return {
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    onSortChange,
    results,
    search,
    setFilter,
    sort,
  }
}

export function useDtwinFirmwareUpdateOperationModels(searchMode?: SearchMode) {
  const service = operationManagerService.getModels.bind(operationManagerService)

  const appliedFilters: FiltersSelection = new Map([
    [Filters.DTWIN_OPERATION_MODEL_TYPE, { key: 'type', operator: OPERATOR_RSQL_EQUAL, value: '' }],
    [Filters.DTWIN_OPERATION_MODEL_DEVICE_TYPE, { key: 'deviceTypes', operator: OPERATOR_RSQL_ARRAY_CONTAINS, value: [] }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RSQL, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  return {
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    onSortChange,
    results,
    search,
    setFilter,
    sort,
  }
}
