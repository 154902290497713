import type { ApiListResult, FirmwareI, FirmwareStatsI } from '@lxc/app-device-types'
import type { AxiosProgressEvent } from 'axios'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'

export class FirmwareService extends RestService {
  protected BASE_URL = '/firmwares'

  /**
   * Retrieve firmware
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   */
  getFirmwares(page?: number, pageSize?: number, search?: string | FiltersSelection, sort?: string | null): Promise<ApiListResult<FirmwareI>> {
    const params = { page, pageSize, search, sort }

    return this.$api.get(this.BASE_URL, { params })
  }

  /**
   * Get a firmware by its UUID
   * @param uuid Identifier of the firmware
   * @returns The firmware for the UUID
   */
  getFirmwareByUuid(uuid: string): Promise<FirmwareI> {
    return this.$api.get(`${this.BASE_URL}/${uuid}`)
  }

  /**
   * Get a firmware stats by its UUID
   * @param uuid Identifier of the firmware
   * @returns The firmware stats for the UUID
   */
  getFirmwareStatsByUuid(uuid: string): Promise<FirmwareStatsI> {
    return this.$api.get(`${this.BASE_URL}/${uuid}/stats`)
  }

  /**
   * Upload a firmware file
   * @param firmwareFile
   */
  uploadFirmwareFile(firmwareFile: File, progress: Ref<number | undefined | null>): Promise<FirmwareI> {
    const formData = new FormData()
    formData.append('firmwareFile', firmwareFile)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0
      },
      timeout: Infinity,
    }

    return this.$api.post(`${this.BASE_URL}/upload`, formData, config)
  }

  /**
   * Create a firmware
   * @param firmware
   * @returns
   */
  createFirmware(firmware: FirmwareI) {
    return this.$api.post(`${this.BASE_URL}`, { firmware })
  }

  /**
   * Activate a firmware
   * @param firmware
   * @returns
   */
  activateFirmware(firmware: FirmwareI) {
    return this.$api.patch(`${this.BASE_URL}/${firmware.uuid}/activate`)
  }

  /**
   * Deactivate a firmware
   * @param firmware
   * @returns
   */
  deactivateFirmware(firmware: FirmwareI) {
    return this.$api.patch(`${this.BASE_URL}/${firmware.uuid}/deactivate`)
  }

  /**
   * Delete a firmware
   * @param firmware
   * @returns
   */
  deleteFirmware(firmware: FirmwareI) {
    return this.$api.delete(`${this.BASE_URL}/${firmware.uuid}`)
  }
}

export default new FirmwareService()
