<script lang="ts" setup>
import type { UniqueLabel } from '@lxc/app-device-common'
import { useSerialize } from '@lxc/app-device-common'
import type { TruststoreCertificateDetailI, TruststoreCertificateRequestI } from '@lxc/app-device-types'
import { CACertificateItemType } from '@lxc/app-device-types'
import type { WritableComputedRef } from 'vue'
import ILxcAlertCircle from '~icons/lxc/alert-circle'
import { useExportCertificateAsString } from '~/composables/useCaCertificates'
import truststoreService from '~/services/truststore.service'
import type { TruststoreCertificateDetailForm } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import { getUniqueLabelList, getValuesFromUniqueLabels } from '~/utils/unique-label-tools'

const { t } = useI18n()
const serialize = useSerialize()
const { exportCertificateAsString } = useExportCertificateAsString()

const props = defineProps<{
  alias?: string | null
  disabled?: boolean
  isTagsLoading: boolean
  sideCanvasShown: boolean
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const emit = defineEmits([
  'close',
  'save',
  'update:sideCanvasShown',
])

const defaultCertificateForm: TruststoreCertificateDetailForm = {
  alias: '',
  certificate: '',
  tags: [],
  trustChain: [],
  type: CACertificateItemType.TRUSTCHAIN,
}

const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown
  },
  set(sideCanvasShown: boolean) {
    emit('update:sideCanvasShown', sideCanvasShown)
  },
})

const certificateForm: Ref<TruststoreCertificateDetailForm> = ref<TruststoreCertificateDetailForm>({
  ...defaultCertificateForm,
  trustChain: [...defaultCertificateForm.trustChain],
  tags: [...defaultCertificateForm.tags],
})
const certificateFormRef: Ref = ref()
const initCertificateFormStringified: Ref<string> = ref(serialize(defaultCertificateForm))
const closeLabel = t('button.close')
const downloadLabel = t('button.download')
const validateLabel = t('button.validate')
const loadingCertificateError: Ref<LxcError | null | undefined> = ref()
const loadingErrorVisible: ComputedRef<boolean> = computed(() => LxcError.check(loadingCertificateError.value))
const isCertificateLoading = ref<boolean>(false)
const isSaving = ref<boolean>(false)

const edited: ComputedRef<boolean> = computed(() => {
  return serialize(certificateForm.value) !== initCertificateFormStringified.value
})

function cloneCertificateForm(targetCertificateForm: Ref<TruststoreCertificateDetailForm>, certificateForm?: TruststoreCertificateDetailI | null) {
  const certificateTags: UniqueLabel[] | undefined = getUniqueLabelList(certificateForm?.tags, props.disabled)
  Object.assign(targetCertificateForm.value, certificateForm ?? defaultCertificateForm,
    {
      tags: [...(certificateTags ?? defaultCertificateForm.tags)],
      trustChain: [...(certificateForm?.trustChain ?? defaultCertificateForm.trustChain)],
    })
}

function setCertificateDetailForm(certificateDetailForm?: TruststoreCertificateDetailI | null) {
  cloneCertificateForm(certificateForm, certificateDetailForm)
  initCertificateFormStringified.value = serialize(certificateForm.value)
}

async function getCertificateDetail(): Promise<void> {
  if (props.alias) {
    isCertificateLoading.value = true
    const response = await truststoreService.getCertificate(props.alias)

    if (LxcError.check(response)) {
      loadingCertificateError.value = response
    } else {
      loadingCertificateError.value = null
      setCertificateDetailForm(response)
    }

    isCertificateLoading.value = false
  }
}

async function onSubmitSave(): Promise<void> {
  isSaving.value = true
  const certificateRequest: TruststoreCertificateRequestI = {
    alias: certificateForm.value.alias,
    certificate: certificateForm.value.certificate,
    tags: getValuesFromUniqueLabels(certificateForm.value.tags),
    type: certificateForm.value.type,
  }

  const response = await truststoreService.updateCertificate(certificateRequest)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    close()
    setCertificateDetailForm()
    emit('save')
  }

  isSaving.value = false
}

const onDownload = () => {
  if (certificateForm.value.certificate) {
    exportCertificateAsString(certificateForm.value.certificate, certificateForm.value.alias, certificateForm.value.type)
  }
}

function close() {
  formSideCanvasShown.value = false
  emit('close')
}

const onCancel = () => {
  close()
  setCertificateDetailForm()
}

const onPropsFormCanvasShowChange = (shown: boolean) => {
  if (shown) {
    getCertificateDetail()
  }
}

watch(() => formSideCanvasShown.value, onPropsFormCanvasShowChange)
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    :header="$t('certificates.truststore.editCertificate')"
    :close-tooltip="$t('button.close')"
    :confirm-enabled="edited"
    :confirm-title="$t('certificates.truststore.editCertificate')"
    :confirm-message="$t('certificates.truststore.cancelAction.confirm.message')"
    :confirm-ok-label="$t('button.confirm')"
    :confirm-cancel-label="$t('button.cancel')"
    @discard="onCancel"
  >
    <div>
      <lxc-form
        ref="certificateFormRef"
        :model="certificateForm"
      >
        <div
          v-if="isCertificateLoading"
          class="w-full h-5 inset-0 z-10"
        >
          <div class="flex flex-col items-center h-5 justify-center">
            <lxc-loader :size="20" />
          </div>
        </div>

        <lxc-alert
          v-if="loadingErrorVisible"
          :icon="ILxcAlertCircle"
          type="error"
        >
          <template #title>
            {{ $t('certificates.error.certificate.loading') }}
          </template>
          <p>{{ loadingCertificateError?.toError()?.message ?? '' }}</p>
        </lxc-alert>

        <lxc-truststore-generic-form
          v-model="certificateForm"
          :disabled="disabled"
          :edition="true"
          :is-tags-loading="isTagsLoading"
          :tags="tags"
          :tags-error="tagsError"
        />
        <div class="mt-6 pb-4">
          <lxc-button
            class=""
            type="secondary"
            html-type="button"
            :title="downloadLabel"
            @click="onDownload"
          >
            {{ downloadLabel }}
          </lxc-button>
        </div>
      </lxc-form>
    </div>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="closeLabel"
          @click="close"
        >
          {{ closeLabel }}
        </lxc-button>
        <lxc-button
          html-type="submit"
          :disabled="!edited || isSaving"
          :title="validateLabel"
          @click="onSubmitSave"
        >
          {{ validateLabel }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
