import { useFetchPage } from './useFetchPage'
import { useSort } from './useSort'
import { type SearchMode, useSearch } from '~/composables/useSearch'
import keystoreService from '~/services/keystore.service'
import type { FiltersSelection } from '~/types'
import { Filters, FiltersType, OPERATOR_RSQL_IN, OPERATOR_RSQL_LIKE } from '~/types'
import filtersUtils from '~/utils/filters.utils'

export function useKeystore(searchMode?: SearchMode) {
  const appliedFilters: FiltersSelection = new Map([
    [Filters.KEYSTORE_ALIAS, { key: 'alias', operator: OPERATOR_RSQL_LIKE, value: '', valueFormatter: filtersUtils.formatRSQLValue }],
    [Filters.KEYSTORE_TAGS, { key: 'tags', operator: OPERATOR_RSQL_IN, value: [] }],
  ])

  const service = keystoreService.getListOfCertificates.bind(keystoreService)
  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RSQL, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()

  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  return {
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    onSortChange,
    results,
    search,
    setFilter,
  }
}
