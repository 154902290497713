<script setup lang="ts">
import type { ImportResultsI } from '@lxc/app-device-types'
import { ImportMode } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import DtwinsService from '~/services/dtwins.service'
import { ACL_ROLES } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'
import uploadUtils from '~/utils/upload.utils'
import ILxcFile from '~icons/lxc/file'
import ILxcUploadCloud from '~icons/lxc/upload-cloud'

const { locale, t } = useI18n()

const acl = useAcl()
const isAllowedToImport: ComputedRef<boolean> = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM))

const importMode: Ref<ImportMode> = ref(ImportMode.REPORT)

const show: Ref<boolean> = ref(false)

const reload: Ref<boolean> = ref(false)

const importLoading: Ref<boolean> = ref(false)
const importResults: Ref<ImportResultsI | undefined> = ref()

const uploadFiles: Ref<FileList | undefined> = ref()
const uploadFilesProgress: Ref<number | undefined | null> = ref()
const uploadFilesError: Ref<Error | undefined | null> = ref()

// Disable the analyze/execution import button
const buttonImportDisabled: ComputedRef<boolean> = computed(() => {
  return uploadFiles?.value?.length === 0 // no file selected
    || (uploadFilesProgress.value && (uploadFilesProgress.value < 100)) // upload in progress
    || (importResults.value?.mode === ImportMode.REPORT && importResults.value?.results.filter((result: any) => !result.failed).length === 0) // no validated device after report
    || importResults.value?.mode === ImportMode.EXECUTION // import done
})

// Analyze/Execution import in progress
const importInProgress: ComputedRef<boolean> = computed(() => importLoading.value && uploadFilesProgress.value === 100)

/**
 * Check if the file can be uploaded, show an error if not
 * @param file File to check
 * @return true if the file can be uploaded, false otherwise
 */
function canUploadFile(file: File): boolean {
  let error: string | undefined
  uploadFilesError.value = uploadFilesError.value !== undefined ? null : undefined

  if (!uploadUtils.isZipFile(file)) {
    error = 'fileMandatory'
  } else if (!file.size) {
    error = 'fileEmpty'
  }

  if (error) {
    uploadFilesError.value = new Error(t(`dtwins.import.validation.${error}`))
  }

  return !error
}

const importDtwins = async() => {
  if (uploadFiles?.value?.length && uploadFiles?.value?.length > 0 && canUploadFile(uploadFiles?.value[0])) {
    importLoading.value = true

    importResults.value = undefined

    const response: ImportResultsI = await DtwinsService.import(uploadFiles.value[0], uploadFilesProgress, importMode.value)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      importResults.value = response

      // only reload the list if at least one import execution result succeed
      reload.value = importResults.value.mode === ImportMode.EXECUTION && importResults.value.results.some((result: any) => !result.failed)

      importMode.value = ImportMode.EXECUTION
    }

    importLoading.value = false
  }
}

const clearFileUploadStatus = () => {
  importMode.value = ImportMode.REPORT
  uploadFilesError.value = uploadFilesError.value !== undefined ? null : undefined
  uploadFilesProgress.value = null
  importResults.value = undefined
}

const clearAll = () => {
  uploadFiles.value = undefined
  clearFileUploadStatus()
}

watch(() => uploadFiles.value, clearFileUploadStatus)
watch(() => show.value, clearAll)
</script>

<template>
  <lxc-container>
    <div class="flex justify-between mb-4">
      <lxc-breadcrumb />
      <lxc-button
        class="self-center"
        :icon="ILxcUploadCloud"
        :title="$t('dtwins.import.title')"
        :disabled="!isAllowedToImport"
        @click="show = true"
      >
        {{ $t('dtwins.import.text') }}
      </lxc-button>
    </div>
    <lxc-dtwins-list v-model:reload="reload" />
    <lxc-side-canvas
      v-model:show="show"
    >
      <template #header>
        {{ $t('dtwins.import.header.text') }}
      </template>

      <div>
        <lxc-input
          v-model="uploadFiles"
          type="file"
          accept=".zip"
          :delete-tooltip="$t('input.deleteFile')"
          :error="uploadFilesError"
          :percentage="uploadFilesProgress"
          :size-unit="uploadUtils.getUnitTranslations()"
          :locale="locale"
          @blur="clearFileUploadStatus"
        >
          <template #placeholder>
            <div class="mx-8">
              <span class="text-primary-700 font-bold">{{
                $t('input.clickToUpload')
              }}</span>&nbsp;<span>{{ $t('input.orDragAndDrop', {format: '.ZIP'}) }}</span>
            </div>
          </template>
        </lxc-input>

        <div
          v-if="importMode === ImportMode.EXECUTION && importInProgress"
          class="mt-6 flex"
        >
          <lxc-loader
            :size="8"
            class="mr-4"
          />
          <h4>{{ $t(`dtwins.import.inProgress.${importMode}`) }}</h4>
        </div>
        <div
          v-if="importResults"
          class="mt-6"
        >
          <div class="flex">
            <div class="mr-4">
              <div class="rounded-full bg-primary-50 p-1 w-fit">
                <div class="rounded-full bg-primary-100 p-2 w-fit">
                  <ILxcFile
                    class="text-primary-600"
                    height="0.9rem"
                    width="0.9rem"
                  />
                </div>
              </div>
            </div>
            <h4>
              {{ $t(`dtwins.import.results.${importResults.mode}.total`, importResults.results.length) }}
            </h4>
          </div>

          <ul class="leading-10">
            <li>
              {{ $t(`dtwins.import.results.${importResults.mode}.succeed.exist`) }}&nbsp;<span
                class="text-white bg-primary-700 rounded-full px-2 py-1 w-fit"
              >
                {{ importResults.results.filter((result: any) => !result.failed && result.exist).length }}
              </span>
            </li>
            <li>
              {{ $t(`dtwins.import.results.${importResults.mode}.succeed.new`) }}&nbsp;<span
                class="text-white bg-primary-700 rounded-full px-2 py-1 w-fit"
              >
                {{ importResults.results.filter((result: any) => !result.failed && !result.exist).length }}
              </span>
            </li>
            <li>
              {{ $t(`dtwins.import.results.${importResults.mode}.failed`) }}&nbsp;<span
                class="text-white bg-warning-700 rounded-full px-2 py-1 w-fit"
              >
                {{ importResults.results.filter((result: any) => result.failed).length }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <template #footer>
        <div class="grid grid-cols-[max-content_auto] gap-4">
          <lxc-button
            type="secondary"
            :title="$t('button.close')"
            @click="show = false"
          >
            {{ $t('button.close') }}
          </lxc-button>
          <lxc-button
            :disabled="buttonImportDisabled"
            :title="$t(`dtwins.import.action.${importMode}`)"
            @click="importDtwins"
          >
            {{ $t(`dtwins.import.action.${importMode}`) }}
          </lxc-button>
        </div>
      </template>
    </lxc-side-canvas>
  </lxc-container>
</template>
