<script setup lang="ts">
import type { AsyncDeviceTwinsFirmwareStatisticsMap } from '@lxc/app-device-types'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'
import type { DashboardCardStatus } from '~/types/dashboard'
import type { Option } from '~/types/filters'
import { appModelTypeOptions } from '~/constants/applicationsFilters.config'
import { PATHS } from '~/constants/paths'
import { isFirmwareTileDisplayable, verifyCardStatus } from '~/utils/dashboard.utils'

const licenseStatusStore = useLicenseStatusStore()

const props = defineProps<{
  status: DashboardCardStatus
  firmwares?: AsyncDeviceTwinsFirmwareStatisticsMap
}>()

function getUriRedirect(firmwareName: string): string {
  let uri: string

  if (appModelTypeOptions.options.some((model: Option): boolean => model.value === firmwareName)) {
    uri = PATHS.APPLICATIONS
  } else {
    uri = PATHS.DEVICES_DVTM_ESOFT
  }

  return uri
}

</script>

<template>
  <lxc-dashboard-card
    v-if="licenseStatusStore.isLicenseFeatureAdvancedFleetManagementActive"
    :display-header-margin="false"
    :status="verifyCardStatus(props.status, props.firmwares)"
  >
    <div
      v-if="isFirmwareTileDisplayable(firmwares)"
      class="flex flex-wrap justify-evenly relative box-border gap-2.5"
    >
      <div
        v-for="(firmware, firmwarName) in firmwares"
        :key="firmwarName"
        class="grow shrink basis-0 flex justify-center"
      >
        <lxc-firmware-pie
          :firmware-name="firmwarName"
          :versions="firmware"
          :url-redirect="getUriRedirect(firmwarName)"
        />
      </div>
    </div>

    <div
      v-else
      class="h-[7.5rem] flex justify-center p-2.5 text-center"
    >
      <p>{{ $t('dashboard.firmwares.noData') }}</p>
    </div>
  </lxc-dashboard-card>
</template>
