<script setup lang="ts">
import ILxcInfoFilled from '~icons/lxc-custom/info-filled'

const props = defineProps<{
  title: string
}>()
</script>

<template>
  <div class="nota-bene-container">
    <ILxcInfoFilled class="nota-bene-icon" />
    <p class="nota-bene">
      {{ props.title }}
    </p>
  </div>
</template>

<style lang='scss' scoped>

.nota-bene-container {
  display: flex;
  flex-direction: row;
  align-items: first baseline;

  .nota-bene-icon {
    max-height: 12px;
    max-width: 12px;
    min-height: 11px;
    min-width: 11px;
  }
  .nota-bene {
    color: $description-text-color;
    font-size: $note-font-size;
    margin: 0;
    padding-left: 10px;
    white-space:pre-wrap;
    word-break:break-word;
  }
}
</style>
