<script setup lang="ts">
const emit = defineEmits(['select', 'update:modelValue'])
const props = defineProps<{
  checkboxProp: string
  data?: Array<any> | null
  disabled?: boolean
  disabledProp?: string
  emptyText?: string
  header?: string
  headerClass?: string|Array<string>
  isLoading?: boolean
  itemClass?: string|Array<string>
  modelValue?: Array<string>
  prop: string
}>()

const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    // Note: we are using destructuring assignment syntax here.
    emit('update:modelValue', selected)
    emit('select', selected)
  },
})

</script>
<template>
  <lxc-checklist
    v-model="selectedItems"
    :checkbox-prop="checkboxProp"
    :data="data"
    :empty-text="emptyText"
    :header="header"
    header-class="underline"
    :is-loading="isLoading"
    :item-class="itemClass"
    :prop="prop"
    :disabled="disabled"
    :disabled-prop="disabledProp"
  />
</template>
