import type {
  ApiListResult,
  AppDeviceState,
  AsyncDeviceTwinsFirmwareStatisticsMap,
  CampaignType,
  DeviceConfigsI,
  DeviceI,
  DeviceModelConfigurationI,
  DeviceStatsI,
  OperationI,
  OperationToUpdateI,
  OperationType,
} from '@lxc/app-device-types'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'

export class DeviceService extends RestService {
  protected BASE_URL = '/devices'
  private PARAMETERS_BASE_URL = '/parameters/device-certificate-management'

  /**
   * Retrieve devices
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  getDevices(page: number, pageSize: number, search?: string | FiltersSelection, sort?: string | null, otherParams?: Map<string, any>): Promise<ApiListResult<DeviceI>> {
    const params = {
      page,
      pageSize,
      sort,
      search,
      ...(otherParams ? Object.fromEntries(otherParams) : {}),
    }

    return this.$api.get(this.BASE_URL, { params })
  }

  /**
   * Get device details
   * @param id
   */
  getDeviceDetails(id: string): Promise<DeviceI> {
    return this.$api.get(`${this.BASE_URL}/${id}`)
  }

  /**
   * Get multiple device details
   * @param deviceIds
   */
  getDevicesDetails(deviceIds: string[]): Promise<Array<DeviceI>> {
    const data = {
      id: deviceIds,
    }
    const config = {
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
    }
    return this.$api.post(`${this.BASE_URL}/getDevicesDetails`, data, config)
  }

  /**
   * Update device state
   * @param id
   * @param state
   */
  updateDeviceState(id: string, state: AppDeviceState) {
    return this.$api.patch(`${this.BASE_URL}/${id}`, { state })
  }

  /**
   * Get device configuration
   */
  getDeviceConfiguration(): Promise<DeviceConfigsI> {
    return this.$api.get(`${this.PARAMETERS_BASE_URL}`)
  }

  /**
   * Update device configuration
   * @param device
   */
  updateDeviceConfiguration(device: DeviceModelConfigurationI[]) {
    return this.$api.patch(`${this.PARAMETERS_BASE_URL}`, { device })
  }

  /**
   * Delete a device
   * @param deviceId
   */
  deleteDevice(deviceId: string): Promise<any> {
    return this.$api.delete(`${this.BASE_URL}/${deviceId}`)
  }

  /**
   * Create a renew certificate operation
   * @param device
   */
  createOperationRenewCertificate(device: DeviceI, startTime: string): Promise<OperationI> {
    return this.$api.post(`${this.BASE_URL}/${device.id}/renewCertificate/${startTime}`)
  }

  /**
   * Update an operation
   * @param device
   * @param operationId
   * @param startTime
   * @returns
   */
  updateOperations(device: DeviceI, operationsToUpdate: OperationToUpdateI[]) {
    return this.$api.patch(`${this.BASE_URL}/${device.id}/operations`, { operationsToUpdate })
  }

  /**
   * Create a firmware upgrade operation
   * @param device
   * @param uuid
   */
  createOperationFirmwareUpgrade(device: DeviceI, uuid: string, startTime: string): Promise<OperationI> {
    return this.$api.post(`${this.BASE_URL}/${device.id}/firmwareUpgrade/${uuid}/${startTime}`)
  }

  /**
   * Get a device operations
   * @param deviceId
   * @param page
   * @param pageSize
   * @returns
   */
  getDeviceOperations(deviceId: string, page?: number, pageSize?: number, sort?: string, operationType?: OperationType, hasCallbackUrl?: boolean): Promise<ApiListResult<OperationI>> {
    let params: any = {}

    if (sort) {
      params = { ...params, sort }
    }

    if (page) {
      params = { ...params, page }
    }

    if (pageSize) {
      params = { ...params, pageSize }
    }

    if (operationType) {
      params = { ...params, operationType }
    }

    if (hasCallbackUrl !== undefined) {
      params = { ...params, hasCallbackUrl }
    }

    return this.$api.get(`${this.BASE_URL}/${deviceId}/operations`, { params })
  }

  /**
   * Cancel operations for a device
   * @param deviceTwinId
   * @param operationsIds
   */
  cancelOperations(deviceTwinId: string, operationsIds: string[]) {
    const operationsCancelPayload = [{ deviceTwinId, operationsIds }]

    return this.$api.post(`${this.BASE_URL}/operations/cancel`, { operationsCancelPayload })
  }

  /**
   * Get stats operation for a device
   * @param deviceId
   * @param campaignType
   * @returns
   */
  getStatsOperation(deviceId: string, campaignType: CampaignType): Promise<DeviceStatsI> {
    return this.$api.get(`${this.BASE_URL}/${deviceId}/operations/stats`, { params: { campaignType } })
  }

  /**
   * Get the firmware statistics filtered by sector codes
   * @param sectors
   * @returns
   */
  async getFirmwareStatistics(sectors: string[]): Promise<AsyncDeviceTwinsFirmwareStatisticsMap> {
    return this.$api.get(`${this.BASE_URL}/statistics/firmware`, {
      headers: {
        Accept: 'application/json',
      },
      params: {
        sectors,
      },
      timeout: Infinity,
    })
  }
}

export default new DeviceService()
