<script setup lang="ts">
import type { ReportingRecipientI, UserDataI } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import type { FormInstance } from 'element-plus'
import type { Ref } from 'vue'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import { useCertificateUpdateReporting } from '~/composables/useCertificateUpdateReporting'
import { useElTable } from '~/composables/useElTable'
import { PATHS } from '~/constants/paths'
import LxcError from '~/utils/LxcError'
import { NotificationKey, setDetailMessageAsListItem, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcArrowUpLeft from '~icons/lxc/arrow-up-left'
import ILxcPlus from '~icons/lxc/plus'

const { t } = useI18n()
const router = useRouter()
const { getCellStyle } = useElTable()

const {
  configuration,
  configurationBackup,
  error,
  isLoading,
  fetchConfiguration,
  updateConfiguration,
  addReportingRecipients,
  removeReportingRecipients,
} = useCertificateUpdateReporting()

const MIN_REPORTING_DAY = 1
const MAX_REPORTING_DAY = 7
const DEFAULT_REPORT_TIME = '00:00'
const END_REPORT_TIME = '23:00'
const STEP_REPORT_TIME = '01:00'
const TIME_FORMAT = t('dateFormat.certificateAlerting')

interface CertAlertingI {
  frequency: number
  time: string
}

const certAlertingFormRef = ref<FormInstance>()
const certAlertingFormRefForm = reactive<CertAlertingI>({
  frequency: MIN_REPORTING_DAY,
  time: DEFAULT_REPORT_TIME,
})

const selectedUsersToAdd: Ref<Array<UserDataI>> = ref([])
const selectedRecipientsToRemove: Ref<Array<ReportingRecipientI>> = ref([])
const recipients: Ref<Array<ReportingRecipientI>> = ref([])
const [addUsersModalVisible, toggleAddUsersModalVisible] = useToggle()
const [removeUsersModalVisible, toggleRemoveUsersModalVisible] = useToggle()

/**
 * Save the reporting configuration
 */
const saveConfiguration = async() => {
  if (certAlertingFormRef.value) {
    await certAlertingFormRef.value.validate(async(valid) => {
      if (valid) {
        const response = await updateConfiguration({
          frequency: certAlertingFormRefForm.frequency,
          time: {
            hours: dayjs(certAlertingFormRefForm.time, TIME_FORMAT).hour(),
            minutes: 0,
          },
        })

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
        }
      }
    })
  }
}

const cancelConfiguration = () => {
  configuration.value = Object.assign({}, configurationBackup.value)
  showNotificationSuccess(t(NotificationKey.cancel))
}

const rules = reactive({
  frequency: [
    { required: true, message: t('certificateAlerting.validation.frequency.required'), trigger: 'blur' },
  ],
  time: [
    { required: true, message: t('certificateAlerting.validation.time.required'), trigger: 'blur' },
  ],
})

/**
 * On row click
 * @param recipient
 */
function onRowClick(recipient: ReportingRecipientI) {
  router.push(`${PATHS.USER_MANAGEMENT_USERS}/${recipient.userId}`)
}

/**
 * Add selecte users as recipients
 */
const addSelectedUsers = async() => {
  if (configuration.value?.id) {
    const response = await addReportingRecipients(configuration.value.id, selectedUsersToAdd.value.map((user: UserDataI) => ({
      name: `${user.firstName} ${user.lastName}`,
      address: user.email,
      templateLang: user.language,
      userId: user.id,
    })))

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      const selectedUsersToAddList = selectedUsersToAdd.value
        .map(selectedUserToAdd => `${selectedUserToAdd.firstName} ${selectedUserToAdd.lastName}`)
        .map(setDetailMessageAsListItem)
        .join('')
      showNotificationSuccess(t('certificateAlerting.recipients.users.add.notification.success'), selectedUsersToAddList)

      selectedUsersToAdd.value = []

      fetchConfiguration()
    }
  }

  toggleAddUsersModalVisible(false)
}

/**
 * On select recipients
 * @param handledSelected
 */
const handleSelectRecipientsToRemove = (handledSelected: ReportingRecipientI[]) => {
  selectedRecipientsToRemove.value = handledSelected
}

/**
 * Return true if the recipient can be removed, false otherwise
 * @param recipient
 */
function canSelectRecipientToRemove(recipient: ReportingRecipientI) {
  return recipient.userId
}

/**
 * Remove selected recipients
 */
const removeSelectedRecipients = async() => {
  if (configuration.value?.id) {
    const response = await removeReportingRecipients(configuration.value.id, selectedRecipientsToRemove.value)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      const selectedRecipientsToRemoveList = selectedRecipientsToRemove.value
        .map(selectedRecipientToRemove => selectedRecipientToRemove.name)
        .map(setDetailMessageAsListItem)
        .join('')
      showNotificationSuccess(t('certificateAlerting.recipients.users.remove.notification.success'), selectedRecipientsToRemoveList)

      selectedRecipientsToRemove.value = []

      fetchConfiguration()
    }
  }

  toggleRemoveUsersModalVisible(false)
}

watch(() => configuration.value, () => {
  if (configuration.value) {
    certAlertingFormRefForm.frequency = configuration.value?.frequency ?? MIN_REPORTING_DAY
    certAlertingFormRefForm.time = dayjs(`${configuration.value?.time.hours.toString().padStart(2, '0')}:00`, 'HH:mm').format(TIME_FORMAT)

    recipients.value = configuration.value?.recipients || []
  }
})

onMounted(fetchConfiguration)
</script>

<template>
  <lxc-container>
    <lxc-breadcrumb
      :name="BreadcrumbConfig.CERT_ALERT_REPORTING.title"
      readonly
    />
    <lxc-container
      :is-loading="isLoading"
      :error="error"
      :py="0"
      :px="0"
    >
      <lxc-container
        :px="0"
      >
        <lxc-mandatory />

        <!-- Configuration -->
        <h2
          class="section-title"
        >
          {{ $t('certificateAlerting.reportingConfiguration.title') }}
        </h2>

        <lxc-information-row
          class="form-item-spacing"
          :title="$t('certificateAlerting.reportingConfiguration.information')"
        />

        <el-form
          ref="certAlertingFormRef"
          :rules="rules"
          :model="certAlertingFormRefForm"
          label-position="top"
          label-width="auto"
        >
          <el-form-item
            :label="$t('certificateAlerting.reportingConfiguration.frequency')"
            prop="frequency"
          >
            <el-input-number
              v-model="certAlertingFormRefForm.frequency"
              :min="MIN_REPORTING_DAY"
              :max="MAX_REPORTING_DAY"
              controls-position="right"
            />
          </el-form-item>

          <el-form-item
            :label="$t('certificateAlerting.reportingConfiguration.time')"
            prop="time"
          >
            <el-time-select
              v-model="certAlertingFormRefForm.time"
              :clearable="false"
              :start="DEFAULT_REPORT_TIME"
              :step="STEP_REPORT_TIME"
              :end="END_REPORT_TIME"
              :format="TIME_FORMAT"
              :placeholder="$t('certificateAlerting.reportingConfiguration.time')"
            />
          </el-form-item>
        </el-form>

        <lxc-cancel-or-submit-buttons
          @cancel="cancelConfiguration"
          @submit="saveConfiguration"
        />
        <el-divider />

        <!-- Recipients -->
        <div v-if="configuration && configuration.id">
          <h2
            class="section-title"
          >
            {{ $t('certificateAlerting.recipients.title') }}
          </h2>
          <lx-toolbar :is-right="true">
            <el-button
              type="primary"
              :icon="ILxcPlus"
              @click="toggleAddUsersModalVisible"
            >
              {{ $t('certificateAlerting.recipients.users.add.label') }}
            </el-button>

            <el-button
              :disabled="selectedRecipientsToRemove.length === 0"
              type="default"
              class="lxc-plain"
              :icon="ILxcArrowUpLeft"
              @click="toggleRemoveUsersModalVisible"
            >
              {{ $t('certificateAlerting.recipients.users.remove.label') }}
            </el-button>
          </lx-toolbar>

          <!-- Added users -->
          <el-table
            :data="recipients"
            :fit="true"
            :empty-text="$t('certificateAlerting.recipients.users.empty')"
            row-class-name="clickable"
            :cell-style="getCellStyle"
            @row-click="onRowClick"
            @select="handleSelectRecipientsToRemove"
            @select-all="handleSelectRecipientsToRemove"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="canSelectRecipientToRemove"
            />
            <el-table-column
              :label="$t('table.header.lastname')"
              class-name="name"
            >
              <template #default="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('table.header.email')"
            />
          </el-table>

          <!-- Modal to select users to add -->
          <lxc-modal
            v-if="addUsersModalVisible"
            :show-confirm="true"
            :dialog-visible="addUsersModalVisible"
            :title="$t('certificateAlerting.recipients.users.select.label')"
            width="70%"
            @confirm="addSelectedUsers"
            @cancel="toggleAddUsersModalVisible"
            @update:dialog-visible="toggleAddUsersModalVisible"
          >
            <lxc-user-list
              v-model:selected-users="selectedUsersToAdd"
              :user-ids-not-selectable="recipients.map(recipient => recipient.userId || '')"
            />
          </lxc-modal>

          <!-- Modal to confirm removing selected users -->
          <lxc-modal
            v-if="removeUsersModalVisible"
            :show-confirm="true"
            :dialog-visible="removeUsersModalVisible"
            :title="$t('certificateAlerting.recipients.users.remove.confirm.title')"
            @confirm="removeSelectedRecipients"
            @cancel="toggleRemoveUsersModalVisible"
            @update:dialog-visible="toggleRemoveUsersModalVisible"
          >
            {{ $t('certificateAlerting.recipients.users.remove.confirm.message') }}
            <ul>
              <li
                v-for="(recipient, i) in selectedRecipientsToRemove"
                :key="i"
              >
                {{ recipient.name }}
              </li>
            </ul>
          </lxc-modal>
        </div>
      </lxc-container>
    </lxc-container>
  </lxc-container>
</template>

<style lang="scss" scoped>
.lxc-mandatory {
  margin-bottom: 40px;
}

.form-item-spacing {
  margin: 20px 0;
}

// Hide disabled selection checkboxs
:deep(.el-table-column--selection) { .cell { .el-checkbox.is-disabled { display: none; } } }
</style>
