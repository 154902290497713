import type { SectorI, UserDataI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import { useSearch } from '~/composables/useSearch'
import type { SearchMode } from '~/composables/useSearch'
import UsersService from '~/services/users.service'
import type { FiltersSelection } from '~/types'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useSort } from '~/composables/useSort'
import { useFetchPage } from '~/composables/useFetchPage'

export function useUsers(searchMode?: SearchMode) {
  const service = UsersService.getUsers.bind(UsersService)
  const itemType = 'user'

  const { can } = useAcl()

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: 'name', operator: '=', value: '' }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RAW, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function getDescription(item: UserDataI) {
    return item ? `${item?.firstName} ${item?.lastName}` : ''
  }

  function canManageItems(_sector: SectorI) {
    return can(ACL_ROLES.DISPLAY_USERS)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.id)
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  }
}
