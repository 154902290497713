import { NO_CONTENT } from '~/components/dtwins/dtwinsForm/LxcDtwinsFormType'

export class LxcDtwinsDescriptionStateType {
  readonly lifeCycleState: string
  readonly firstConnection: string
  readonly lastConnection: string
  readonly batteryStatus: number | undefined
  readonly lastChange: string
  readonly configurationApplicationDate: string
  readonly lastRebootDate: string
  readonly firstCommissioningDate: string
  readonly certificateExpirationDate: string
  readonly lastCertificateUpdate: string
  readonly firmwareVersion: string

  constructor({
    lifeCycleState,
    firstConnection,
    lastConnection,
    batteryStatus,
    lastChange,
    configurationApplicationDate,
    lastRebootDate,
    firstCommissioningDate,
    certificateExpirationDate,
    lastCertificateUpdate,
    firmwareVersion,
  }: {
    lifeCycleState?: string
    firstConnection?: string
    lastConnection?: string
    batteryStatus?: number
    lastChange?: string
    configurationApplicationDate?: string
    lastRebootDate?: string
    firstCommissioningDate?: string
    certificateExpirationDate?: string
    lastCertificateUpdate?: string
    firmwareVersion?: string
  }) {
    this.lifeCycleState = lifeCycleState ?? NO_CONTENT
    this.firstConnection = firstConnection ?? NO_CONTENT
    this.lastConnection = lastConnection ?? NO_CONTENT
    this.batteryStatus = batteryStatus
    this.lastChange = lastChange ?? NO_CONTENT
    this.configurationApplicationDate = configurationApplicationDate ?? NO_CONTENT
    this.lastRebootDate = lastRebootDate ?? NO_CONTENT
    this.firstCommissioningDate = firstCommissioningDate ?? NO_CONTENT
    this.certificateExpirationDate = certificateExpirationDate ?? NO_CONTENT
    this.lastCertificateUpdate = lastCertificateUpdate ?? NO_CONTENT
    this.firmwareVersion = firmwareVersion ?? NO_CONTENT
  }
}
