<script setup lang="ts">
import type { PaginationI } from '@lxc/app-device-types'
import { DEFAULT_PAGE_SIZE } from '~/constants/constants'

const props = defineProps<{
  context?: PaginationI
  typeOfElement: string
}>()

const emit = defineEmits(['update:currentPage'])
const numericContext: ComputedRef<PaginationI> = computed(() => ({
  count: props.context?.count ?? 0,
  totalCount: props.context?.totalCount ?? 0,
  page: props.context?.page ?? 0,
  pageSize: props.context?.pageSize ?? 0,
}))

const firstElementIndex: ComputedRef<number> = computed(() => numericContext.value.pageSize * (numericContext.value.page - 1) + 1)
const lastElementIndex: ComputedRef<number> = computed(() => numericContext.value.pageSize * (numericContext.value.page - 1) + numericContext.value.count)

</script>

<template>
  <footer class="pagination-wrapper">
    <p v-if="context?.totalCount ?? 0 > 0">
      {{ firstElementIndex }} - {{ lastElementIndex }} {{ $t('pagination.on') }} {{ numericContext.totalCount }}
      {{ typeOfElement }}
    </p>
    <p v-else>
      {{ $t('pagination.none') }} {{ typeOfElement }}
    </p>
    <el-pagination
      :current-page="context?.page ?? 0"
      :page-size="DEFAULT_PAGE_SIZE"
      background
      layout="prev, pager, next"
      :total="context?.totalCount ?? 0"
      @current-change="$emit('update:currentPage', $event, numericContext.pageSize)"
    />
  </footer>
</template>

<style lang="scss" scoped>
.pagination-wrapper {
  margin: 2rem auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
