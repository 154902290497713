import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import type { ApplicationI, SectorI } from '@lxc/app-device-types'
import { CampaignType, OperationType } from '@lxc/app-device-types'
import ApplicationService from '~/services/application.service'
import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import type { FiltersSelection } from '~/types'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useSort } from '~/composables/useSort'
import { useFetchPage } from '~/composables/useFetchPage'
import { useUserSession } from '~/stores/useUserSession'
import { useConfigStore } from '~/stores/useConfigStore'

export function useApplication(searchMode?: SearchMode) {
  const service = ApplicationService.getAllApplications.bind(ApplicationService)
  const itemType = 'application'

  const { t } = useI18n()
  const { can } = useAcl()
  const { userSession } = useUserSession()
  const { defaultSectorCode } = useConfigStore()

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: 'name', operator: '=', value: '' }],
    [Filters.STATE, { key: 'state', operator: '=', value: [] }],
    [Filters.MODEL_TYPE, { key: 'lxcModelType', operator: '=', value: [] }],
    [Filters.MODEL_DECLINATION, { key: 'lxcModelDeclination', operator: '=', value: [] }],
    [Filters.CONNECTIVITY, { key: 'connectivityState', operator: '=', value: [] }],
    [Filters.DEVICE_FIRMWARE_VERSIONS, { key: 'firmwareVersion', operator: '=', value: [] }],
    [Filters.DEVICE_FIRMWARE_VERSION_INCLUDED_OR_NULL, { key: 'firmwareVersionIncludedOrNull', operator: '=', value: [] }],
    [Filters.CERTIFICATE_EXPIRE_AFTER, { key: 'certificateExpireAfter', operator: '=', value: '' }],
    [Filters.CERTIFICATE_EXPIRE_BEFORE, { key: 'certificateExpireBefore', operator: '=', value: '' }],
    [Filters.SECTORS, { key: 'sectors', operator: '=', value: [] }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.TWO_AMPERSAND_SEPARATOR, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function getDescription(item: ApplicationI) {
    return item?.name ?? ''
  }

  function canManageItems(sector: SectorI) {
    return !sector.isRootSector && can(ACL_ROLES.UPDATE_APPLICATIONS) && userSession?.sectors.map(sector => sector.code).includes(`${defaultSectorCode}`)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.id)
  }

  function hasActionScheduled(application: ApplicationI, campaignType: CampaignType): boolean | undefined {
    if (application.stats?.campaigns?.scheduled) {
      return true
    }

    if (application.stats?.operations) {
      for (const operationStat of application.stats.operations) {
        const operationTypeMatchCampaignType = (campaignType === CampaignType.CRTCLT_RENEWAL_DVC && operationStat.operationType === OperationType.CRTCLT_RENEWAL_DVC)
        || (campaignType === CampaignType.CRTCLT_RENEWAL_APP && operationStat.operationType === OperationType.CRTCLT_RENEWAL_APP)
        || (campaignType === CampaignType.FIRMWARE_UPGRADE_DVC && operationStat.operationType === OperationType.FIRMWARE_UPGRADE_DVC)

        const isOperationScheduled = (operationStat.pending && operationStat.pending > 0)
          || (operationStat.running && operationStat.running > 0)
          || (operationStat.retry && operationStat.retry > 0)

        if (operationTypeMatchCampaignType && isOperationScheduled) {
          return true
        }
      }
    }

    return false
  }

  function getHasActionStatusLabel(application: ApplicationI, campaignType: CampaignType) {
    const _hasActionScheduled: boolean | undefined = hasActionScheduled(application, campaignType)
    if (_hasActionScheduled === undefined) {
      return t('application.actions.scheduled.error')
    } else if (_hasActionScheduled) {
      return t('application.actions.scheduled.yes')
    } else {
      return t('application.actions.scheduled.no')
    }
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    hasActionScheduled,
    getHasActionStatusLabel,
    search,
    onSearch,
  }
}
