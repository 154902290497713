<script setup lang="ts">
import type LxcTabs from '@lxc/app-device-common/src/components/LxcTabs.vue'
import type { DtwinI } from '@lxc/app-device-types'
import LxcError from '~/utils/LxcError'
import DtwinsService from '~/services/dtwins.service'
import type LxcContainer from '~/components/shared/LxcContainer.vue'

const route = useRoute()

const dtwin: Ref<DtwinI | undefined | null> = ref()
const errorDtwin: Ref<LxcError | null> = ref(null)
const isLoadingDtwin = ref(true)
const breadcrumbContainerRef: Ref<HTMLElement | undefined> = ref()
const containerRef: Ref<InstanceType<typeof LxcContainer> | undefined> = ref()
const mainParentElement: ComputedRef<HTMLElement | undefined | null> = computed(() => breadcrumbContainerRef.value?.parentElement)
const containerPaddingTop: ComputedRef<number> = computed(() => containerRef.value?.paddingTop ?? 0)
const containerPaddingBottom: ComputedRef<number> = computed(() => containerRef.value?.paddingBottom ?? 0)
const tabsRef: Ref<InstanceType<typeof LxcTabs> | undefined> = ref()
const tabsContentHeight: Ref<number> = ref(0)
const tabsContentTop: Ref<number> = ref(0)
const parentScrollTop: Ref<number> = ref(0)

async function fetchDevice() {
  const response = await DtwinsService.getDtwin(route.params.id.toString(), true)

  if (LxcError.check(response)) {
    dtwin.value = null
    errorDtwin.value = response
  } else {
    dtwin.value = response
  }

  isLoadingDtwin.value = false
}

const isLoadingModels: Ref<boolean> = ref(false)
const errorModels: Ref<LxcError | undefined> = ref()
const models: Ref<any> = ref()

const fetchModels = async() => {
  isLoadingModels.value = true

  const response = await DtwinsService.getModels(1, 999999999)
  if (LxcError.check(response)) {
    errorModels.value = response
  } else {
    models.value = response.results
    fetchDevice()
  }

  isLoadingModels.value = false
}

function updateTabContentHeight() {
  let height = 0
  const tabsHeight = tabsRef.value?.tabsHeight

  if (breadcrumbContainerRef.value?.parentElement) {
    const containerHeight = breadcrumbContainerRef.value.parentElement.getBoundingClientRect().height - breadcrumbContainerRef.value.getBoundingClientRect().height

    if (containerRef.value && tabsHeight !== undefined) {
      height = containerHeight - containerPaddingTop.value - containerPaddingBottom.value - tabsHeight
    }
  }

  tabsContentHeight.value = height
}

function initTabContentTopPosition() {
  const breadcrumbHeight = breadcrumbContainerRef.value?.getBoundingClientRect().height ?? 0
  const tabsHeight = tabsRef.value?.tabsHeight ?? 0
  tabsContentTop.value = breadcrumbHeight + tabsHeight + containerPaddingTop.value
}

const onParentScroll = (evt: Event) => {
  const parentElm = evt.target as HTMLElement | undefined

  if (parentElm && parentElm === mainParentElement.value) {
    parentScrollTop.value = parentElm.scrollTop
  }
}

const onComponentUpdated = () => {
  updateTabContentHeight()
  initTabContentTopPosition()
}

fetchModels()
onUpdated(onComponentUpdated)
onMounted(() => {
  const parentElm = mainParentElement.value
  if (parentElm) {
    parentElm.addEventListener('scroll', onParentScroll)
  }
  window.addEventListener('resize', updateTabContentHeight)
})
onBeforeUnmount(() => {
  const parentElm = mainParentElement.value

  if (parentElm) {
    parent.removeEventListener('scroll', onParentScroll)
  }
  window.removeEventListener('resize', updateTabContentHeight)
})
</script>

<template>
  <div
    ref="breadcrumbContainerRef"
    class="flex justify-between"
  >
    <div class="flex px-8 pt-8">
      <lxc-breadcrumb
        :name="dtwin?.attributes.friendlyName ?? ''"
        back
      />
    </div>
  </div>

  <lxc-container
    ref="containerRef"
    :error="errorModels || errorDtwin"
    :is-loading="isLoadingModels || isLoadingDtwin"
  >
    <lxc-tabs
      ref="tabsRef"
    >
      <lxc-tab-pane
        :label="$t('dtwins.form.description.title')"
        name="description"
      >
        <lxc-dtwins-description
          v-model:dtwin="dtwin"
          :models="models"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('dtwins.form.firmware.title')"
        name="firmware"
      >
        <lxc-dtwins-firmware
          :dtwin="dtwin"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('dtwins.form.configuration.title')"
        name="configuration"
      >
        <lxc-dtwins-configuration
          :dtwin="dtwin"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('dtwins.form.dataviz.title')"
        name="dataviz"
      >
        <lxc-dtwins-dataviz
          :dtwin="dtwin"
          :offset-top="tabsContentTop"
          :scroll-top="parentScrollTop"
          :tabs-content-height="tabsContentHeight"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
