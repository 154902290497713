<script setup lang="ts">
import type { UniqueLabel } from '@lxc/app-device-common'
import { filterEmptyValues } from '@lxc/app-device-common'
import { ref } from 'vue'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  label: string
  modelValue?: Array<string>
}>()

const emit = defineEmits(['update:modelValue'])
const actionName = ref('')

const selectedActions = computed({
  get(): string[] {
    return props.modelValue ?? []
  },
  set(selected?: string[]) {
    emit('update:modelValue', selected)
  },
})
const isAnyAppliedFilter = computed(() => {
  return (props.modelValue?.length ?? 0) > 0
})

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>((props.modelValue ?? []).map(action => filtersUtils.getTag(props.label, action, action)))
})

const onTagDeleteClick = (label: string, uid?: string) => {
  const index = selectedActions.value?.indexOf(uid ?? label) ?? -1

  if (index >= 0) {
    selectedActions.value?.splice(index, 1)
  }
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <lxc-log-action-name-filter v-model="actionName" />
    <lxc-log-action-list-filter
      v-model="selectedActions"
      :header="label"
      :name="actionName"
    />
  </div>
</template>
