<script setup lang="ts">
import type { ApiListResult, UserDataI } from '@lxc/app-device-types'
import type { ElTable } from 'element-plus'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { useElTable } from '~/composables/useElTable'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import usersService from '~/services/users.service'
import { useConfigStore } from '~/stores/useConfigStore'
import { useUserSession } from '~/stores/useUserSession'
import LxcError from '~/utils/LxcError'
import ILxcPlus from '~icons/lxc/plus'

const props = defineProps<{
  actions?: boolean
  selectedUsers?: Array<UserDataI>
  userIdsNotSelectable?: Array<string>
}>()

const { userSession } = useUserSession()

const emit = defineEmits(['update:selectedUsers'])

const tableRef = ref<InstanceType<typeof ElTable>>()

const router = useRouter()
const { isModeAD } = storeToRefs(useConfigStore())
const { getCellStyle } = useElTable()

const users: Ref<ApiListResult<UserDataI>|null> = ref(null)
const isLoading = ref(false)
const error: Ref<LxcError|null> = ref(null)
const query = ref<string>('')

const rowSelection: Ref<Array<UserDataI>> = ref([])

/**
 * Select users
 */
const setSelection = () => {
  if (props.selectedUsers !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(row, props.selectedUsers?.some(selectedUser => selectedUser.id === row.id))
    }

    rowSelection.value = props.selectedUsers || []
  }
}

watch(() => props.selectedUsers, setSelection)

function onChangeSelectInput() {
  emit('update:selectedUsers', rowSelection.value)
}

/**
 * Retrieve users
 * @param page
 */
async function getUsers(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  isLoading.value = true
  const response = await usersService.getUsers(page, pageSize, query.value)

  if (LxcError.check(response)) {
    error.value = response
  } else {
    users.value = response
  }

  isLoading.value = false

  // Select users (must be asynchronous)
  setTimeout(setSelection, 0)
}

/**
 * Calcul and emit the selected users
 */
const handleSelect = (handledSelected: UserDataI[]) => {
  // Keep the selected users which were selected on another page
  emit('update:selectedUsers', props.selectedUsers?.filter(selectedUser => !tableRef.value || !tableRef.value.data.find(row => row.id === selectedUser.id))
    .concat(handledSelected))
}

/**
 * On row click
 * @param user
 */
function onRowClick(user: UserDataI) {
  router.push(`${PATHS.USER_MANAGEMENT_USERS}/${user.id}`)
}

/**
 * Return true if the user can be selected, false otherwise
 * @param user
 */
function canSelectRow(user: UserDataI) {
  return !props.userIdsNotSelectable || !props.userIdsNotSelectable?.includes(user.id)
}

/**
 * Redirect when creating a user
 */
function onCreateUser() {
  router.push(PATHS.USER_MANAGEMENT_USERS_NEW)
}

function onInputChange(event: string) {
  query.value = event
}

function onClear() {
  query.value = ''
  getUsers()
}

function reloadWithContext() {
  getUsers(users.value?.context?.page, users.value?.context?.pageSize)
}

/**
 * Checks whether the authenticated user is authorized to perform actions on the user in the list.
 * In the current version, only deletion is supported.
 * @param userId User ID on which to perform the action
 */
function isAllowedToPerformAction(userId: string): boolean {
  return userId !== userSession?.userId
}

onMounted(getUsers)
</script>

<template>
  <el-container direction="vertical">
    <lx-toolbar>
      <lx-search-bar
        :search-query="query"
        :is-loading="isLoading"
        :search-placeholder="$t('user.search')"
        @clear="onClear"
        @input-change="onInputChange"
        @search="getUsers"
      />
      <el-button
        v-if="actions && !isModeAD"
        data-cy="buttonCreateUser"
        type="primary"
        size="large"
        :icon="ILxcPlus"
        @click="onCreateUser"
      >
        {{ $t('user.button.create') }}
      </el-button>
    </lx-toolbar>

    <lxc-container
      :is-loading="isLoading && !users"
      :error="error"
    >
      <el-table
        ref="tableRef"
        v-loading="isLoading"
        :data="users?.data"
        :fit="true"
        :empty-text="$t('user.empty')"
        row-class-name="clickable"
        :cell-style="getCellStyle"
        @row-click="onRowClick"
        @select="handleSelect"
        @select-all="handleSelect"
      >
        <el-table-column
          v-if="selectedUsers !== undefined"
          type="selection"
          width="55"
          :selectable="canSelectRow"
        />
        <el-table-column :label="$t('table.header.lastname')">
          <template #default="scope">
            {{ scope.row.lastName.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('table.header.firstname')"
          class-name="firstname"
        >
          <template #default="scope">
            {{ scope.row.firstName }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('table.header.profile')">
          <template #default="scope">
            <el-tag
              v-for="(profile, index) in scope.row.userProfiles"
              :key="index"
            >
              {{ profile?.label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('table.header.email')"
        />
        <el-table-column
          :label="$t('table.header.groups')"
          class-name="no-no-wrap"
        >
          <template #default="scope">
            <el-tag
              v-for="(group, index) in scope.row.userGroups"
              :key="index"
            >
              {{ group?.label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="actions"
          width="50"
        >
          <template #default="scope">
            <lxc-user-actions
              v-if="!isModeAD && isAllowedToPerformAction(scope.row.id) && !scope.row.isProtected"
              :user="scope.row"
              @change="reloadWithContext"
            />
          </template>
        </el-table-column>
      </el-table>

      <div class="footer-wrapper">
        <el-select
          v-if="rowSelection?.length !== 0"
          v-model="rowSelection"
          class="footer-selection"
          value-key="id"
          multiple
          collapse-tags
          collapse-tags-tooltip
          reserve-keyword
          :teleported="false"
          @change="onChangeSelectInput"
        >
          <el-option
            v-for="user in rowSelection"
            :key="user.id"
            :label="`${user.firstName} ${user.lastName}`"
            :value="user"
          />
        </el-select>

        <lxc-pagination
          class="footer-pagination"
          :type-of-element="$t('pagination.user', users?.context?.totalCount ?? 0)"
          :context="users?.context"
          @update:current-page="getUsers"
        />
      </div>
    </lxc-container>
  </el-container>
</template>

<style lang='scss' scoped>
:deep(.firstname .cell) {
  text-transform: capitalize;
  margin: 0;
}

// Hide disabled selection checkboxs
:deep(.el-table-column--selection) { .cell { .el-checkbox.is-disabled { display: none; } } }

.footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .footer-pagination {
    flex: 1;
  }
}
</style>
