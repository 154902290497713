<script setup lang="ts">
import { useAcl } from 'vue-simple-acl'
import { useConfigStore } from '~/stores/useConfigStore'
import { ACL_ROLES } from '~/types'
import { APP_FEATURES } from '~/types/appFeatures'

const acl = useAcl()
const { featureToggle } = useConfigStore()
const canViewLogs = computed(() => featureToggle.isVisible(APP_FEATURES.LOGS) && acl.anyCan([
  ACL_ROLES.CYBER_LOGS_VIEW,
  ACL_ROLES.DEVICE_FLEET_LOGS_VIEW,
  ACL_ROLES.SYSTEM_LOGS_VIEW,
  ACL_ROLES.DEVICE_LOGS_VIEW,
]))

</script>
<template>
  <lxc-container
    v-if="canViewLogs"
  >
    <lxc-breadcrumb />
    <lxc-log-list />
  </lxc-container>
</template>
