import RestService from './rest.service'

export class CertificateTagsService extends RestService {
  protected BASE_URL = '/certificate-tags'

  /**
   * Get list of certificate tags
   * @return List
   */
  async getTags(): Promise<Array<string>> {
    return this.$api.get(this.BASE_URL)
  }
}

export default new CertificateTagsService()
