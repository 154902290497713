import type { ApiListResult, UserProfileI } from '@lxc/app-device-types'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import UserProfilesService from '~/services/userProfiles.service'
import LxcError from '~/utils/LxcError'

export function useProfiles() {
  const profiles = ref<ApiListResult<UserProfileI> | null>(null)
  const error: Ref<LxcError | null | undefined> = ref(null)
  const isLoading = ref(true)

  async function fetchUserProfiles(page: number = DEFAULT_FIRST_PAGE, profileLabel?: string, query?: string) {
    isLoading.value = true
    const response = await UserProfilesService.getUserProfiles(page, DEFAULT_PAGE_SIZE, profileLabel, query)

    if (LxcError.check(response)) {
      profiles.value = null
      error.value = response
    } else {
      profiles.value = response
      error.value = null
    }

    isLoading.value = false
  }

  return {
    isLoading,
    profiles,
    error,
    fetchUserProfiles,
  }
}
