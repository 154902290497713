<script setup lang="ts">

import type { PeriodI, UniqueLabel } from '@lxc/app-device-common'
import { IntlDateTools, equals, filterEmptyValues } from '@lxc/app-device-common'
import type {
  ApplicationI,
  AsyncLogI,
  DeviceI,
  LogComponentId,
  LogSortBy,
  LogSortDirection,
  RoleI,
  UserDataI,
  UserGroupI,
  UserProfileI,
  UserSessionSector,
} from '@lxc/app-device-types'
import { LogEntityClass, LogEventType, LogLevel } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import type { Ref } from 'vue'
import { watch } from 'vue'
import { useAcl } from 'vue-simple-acl'
import { v4 as uuidv4 } from 'uuid'
import { appModelTypeOptions } from '~/constants/applicationsFilters.config'
import { typeOptions } from '~/constants/deviceFilters.config'
import applicationService from '~/services/application.service'
import DeviceService from '~/services/device.service'
import SectorsService from '~/services/sectors.service'
import UserGroupsService from '~/services/userGroups.service'
import UserProfilesService from '~/services/userProfiles.service'
import UsersService from '~/services/users.service'
import type { FilterSelectionDefinition, FiltersSelection } from '~/types'
import { ACL_ROLES } from '~/types'
import { FilterInputType, Filters } from '~/types/filters'
import type { FilterFormSection, FilterSelectionValue, Option } from '~/types/filters'
import { LogEntitySubClass } from '~/types/logEntity'
import type { ReactiveArrayObject } from '~/types/reactiveArrayObject'
import { getTodayPeriod } from '~/utils/date-tools'
import filtersUtils from '~/utils/filters.utils'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'

const { locale, t } = useI18n()
const { can } = useAcl()

const props = defineProps<{
  filters: FiltersSelection
  rowsSelected: AsyncLogI[]
}>()

const updateInProgress: Ref<boolean> = ref(false)
const applyPending: Ref<boolean> = ref(false)
let onPropsEntityChangedPending = false

const emit = defineEmits([
  'change',
  'enter',
])

const filterDateFormat = 'YYYY-MM-DD'
const componentId: Ref<LogComponentId | undefined> = ref(props.filters.get(Filters.LOG_COMPONENT_ID)?.value as LogComponentId | undefined)
const entityClass: Ref<LogEntityClass | undefined> = ref(props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as LogEntityClass | undefined)
const entitySubClass: Ref<LogEntitySubClass | undefined> = ref()
const entityId: Ref<string[] | undefined> = ref(props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined)
const actions: Ref<string[] | undefined> = ref(props.filters.get(Filters.LOG_ACTION)?.value as string[] | undefined)
const devicesDvtmEsoft = reactive<ReactiveArrayObject<DeviceI>>({ value: [] })
const applications = reactive<ReactiveArrayObject<ApplicationI>>({ value: [] })
const users = reactive<ReactiveArrayObject<UserDataI>>({ value: [] })
const appliedEntitySubClass = ref<LogEntitySubClass | undefined>()
const appliedDevicesDvtmEsoft = reactive<ReactiveArrayObject<DeviceI>>({ value: [] })
const appliedApplications = reactive<ReactiveArrayObject<ApplicationI>>({ value: [] })
const appliedUserGroups = reactive<ReactiveArrayObject<UserGroupI>>({ value: [] })
const appliedProfiles = reactive<ReactiveArrayObject<UserProfileI>>({ value: [] })
const appliedRoles = reactive<ReactiveArrayObject<RoleI>>({ value: [] })
const appliedSectors = reactive<ReactiveArrayObject<UserSessionSector>>({ value: [] })
const appliedUsers = reactive<ReactiveArrayObject<UserDataI>>({ value: [] })
const sortBy: Ref<LogSortBy | undefined> = ref(props.filters.get(Filters.SORT_BY)?.value as LogSortBy | undefined)
const sortDirection: Ref<LogSortDirection | undefined> = ref(props.filters.get(Filters.SORT_DIRECTION)?.value as LogSortDirection | undefined)
const isDevicesLoading = ref(false)
const isApplicationsLoading = ref(false)
const isUsersLoading = ref(false)
const isUserGroupsLoading = ref(false)
const isProfileLoading = ref(false)
const isRolesLoading = ref(false)
const isSectorLoading = ref(false)
const tagsetId = `tagset-${uuidv4()}`
const tagsetSelector = `#${tagsetId}`

const deviceDvtmEsoftLabel = t('logs.filters.entityClass.value.device-dvtm-esoft')
const applicationLabel = t('logs.filters.applications.label')
const sectorLabel = t('logs.filters.entityClass.value.sector')
const profileLabel = t('logs.filters.entityClass.value.profile')
const roleLabel = t('logs.filters.entityClass.value.role')
const userGroupLabel = t('logs.filters.entityClass.value.group')
const userLabel = t('logs.filters.entityClass.value.user')
const actionLabel = t('logs.action.label')
const periodSeparator = ref(` ${t('logs.filters.timestamp.periodSeparator')} `)
const eventTypeLabel = t('logs.eventType.label')
const levelLabel = t('logs.level.label')
const editableEntityClasses = [LogEntityClass.DEVICE_DVTM_ESOFT, LogEntityClass.USER]
const editableEntitySubClasses: Record<string, Array<LogEntitySubClass>> = {
  'device-dvtm-esoft': [LogEntitySubClass.APPLICATION, LogEntitySubClass.DEVICE_DVTM_ESOFT],
}

const isEntityLoading = computed((): boolean => {
  return isDevicesLoading.value || isApplicationsLoading.value || isUsersLoading.value || isUserGroupsLoading.value || isProfileLoading.value || isSectorLoading.value || isRolesLoading.value
})

const selectedFiltersByTypes = computed<Array<string | LogComponentId | LogEntityClass | Array<string | LogLevel | undefined | null> | undefined | null>>(() =>
  [
    componentId.value,
    entityId.value,
    actions.value,
  ])

const formPeriod = computed({
  get(): PeriodI {
    const appliedStartStringDate = props.filters.get(Filters.LOG_START_DATE)?.value as string | undefined
    const appliedEndStringDate = props.filters.get(Filters.LOG_END_DATE)?.value as string | undefined

    if (appliedStartStringDate && appliedEndStringDate) {
      return {
        startDate: IntlDateTools.parse(appliedStartStringDate, filterDateFormat, locale.value),
        endDate: IntlDateTools.parse(appliedEndStringDate, filterDateFormat, locale.value),
      }
    } else {
      return getTodayPeriod()
    }
  },
  set(newValue: PeriodI) {
    if (newValue.startDate == null || newValue.endDate == null) {
      const todayPeriod = getTodayPeriod()
      if (!updateInProgress.value) {
        emit('change', Filters.LOG_START_DATE, dayjs(todayPeriod?.startDate).format(filterDateFormat))
        emit('change', Filters.LOG_END_DATE, dayjs(todayPeriod?.endDate).format(filterDateFormat))
        emit('enter')
      }
    } else {
      if (!updateInProgress.value) {
        emit('change', Filters.LOG_START_DATE, dayjs(newValue?.startDate).format(filterDateFormat))
        emit('change', Filters.LOG_END_DATE, dayjs(newValue?.endDate).format(filterDateFormat))
        emit('enter')
      }
    }
  },
})

function checkDeviceType(device?: DeviceI | ApplicationI): boolean {
  return (typeOptions.options.find((deviceType: Option) => (deviceType.value === device?.model?.type)) != null)
}

async function fetchAppliedEntities<T>(
  appliedList: ReactiveArrayObject<T>,
  service: any,
  detailsMethod: string,
  primaryKey: string,
  loading: Ref<boolean>,
  entityIds?: string[],
  list?: ReactiveArrayObject<T>): Promise<T[]> {
  let entitiesResult: T[] = []

  if (entityIds && !loading.value) {
    loading.value = true
    let entityIdsToFetch: string[] = []

    if (list != null) {
      entityIdsToFetch = entityIds.filter(paramId => list.value.every((currentEntigy: T) => (currentEntigy as any)[primaryKey] !== paramId) && appliedList.value.every(currentEntity => (currentEntity as any)[primaryKey] !== paramId))

      entitiesResult = list.value.filter((currentEntity: T) => entityIds.includes(((currentEntity as any)[primaryKey]) ?? '')).concat(appliedList.value.filter((currentEntity) => {
        return entityIds.includes((currentEntity as any)[primaryKey] ?? '')
          && !list.value.some((pEntity: T) => (pEntity as any)[primaryKey] === (currentEntity as any)[primaryKey])
      }))
    } else {
      entityIdsToFetch = entityIds.filter(paramId => appliedList.value.every(currentEntity => (currentEntity as any)[primaryKey] !== paramId))
    }

    if (entityIdsToFetch.length > 0) {
      // Fetch the devices name if the device entityId is in the URL when the page is loaded.
      const response = await service[detailsMethod](entityIdsToFetch)

      if (LxcError.check(response)) {
        if (response.status === 404 || response.status === 410) {
          // If the resource does not exist or is deleted, then display the filter with the id
          entitiesResult = entitiesResult.concat(entityIdsToFetch.map((entityId) => {
            const fakeEntity: any = {}
            fakeEntity[primaryKey] = entityId
            fakeEntity.label = entityId
            return fakeEntity as T
          }))
        } else {
          response.notify(NotificationKey.error)
        }
      } else {
        entitiesResult = entitiesResult.concat(response)
      }
    }

    loading.value = false
  }

  return entitiesResult
}

async function fetchAppliedDevicesDvtmEsoft(deviceIds?: string[]) {
  const devicesResult = await fetchAppliedEntities<DeviceI>(appliedDevicesDvtmEsoft, DeviceService, 'getDevicesDetails', 'id', isDevicesLoading, deviceIds, devicesDvtmEsoft)
  appliedDevicesDvtmEsoft.value = devicesResult.filter((currentDevice: DeviceI) => checkDeviceType(currentDevice))
}

function checkApplicationType(application?: DeviceI | ApplicationI): boolean {
  return (appModelTypeOptions.options.find((appType: Option) => (appType.value === application?.model?.type)) != null)
}

async function fetchAppliedApplications(applicationIds?: string[]) {
  const applicationResult = await fetchAppliedEntities<ApplicationI>(appliedApplications, applicationService, 'getApplicationsDetails', 'id', isApplicationsLoading, applicationIds, applications)
  appliedApplications.value = applicationResult.filter((currentApplication: ApplicationI) => checkApplicationType(currentApplication))
}

function getDeviceIds(devices: DeviceI[]): string[] {
  return devices.filter(device => device.id !== undefined).map(device => device.id as string)
}

function getApplicationIds(applications: ApplicationI[]): string[] {
  return applications.filter(app => app.id !== undefined).map(app => app.id as string)
}

function getUserIds(users: UserDataI[]): string[] {
  return users.filter(user => user.id !== undefined).map(user => user.id)
}

const onPropsEntityChanged = async(paramEntityClass?: string, paramEntityIds?: string[]) => {
  if (isEntityLoading.value) {
    onPropsEntityChangedPending = true
    return
  }

  switch (paramEntityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both DVTM devices and applications have the same entity class "device-dvtm-esoft"
      await fetchAppliedDevicesDvtmEsoft(paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      devicesDvtmEsoft.value = appliedDevicesDvtmEsoft.value?.slice(0) ?? []

      if (appliedDevicesDvtmEsoft.value.length !== 0) {
        applications.value = []
        entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT
      } else {
        await fetchAppliedApplications(paramEntityIds)
        applications.value = appliedApplications.value?.slice(0) ?? []

        if (appliedApplications.value.length !== 0) {
          entitySubClass.value = LogEntitySubClass.APPLICATION
          devicesDvtmEsoft.value = []
        } else {
          entitySubClass.value = undefined
          devicesDvtmEsoft.value = []
          applications.value = []
        }
      }
      break
    case LogEntityClass.DEVICE:
      break
    case LogEntityClass.USER:
      appliedUsers.value = await fetchAppliedEntities<UserDataI>(appliedUsers, UsersService, 'getUsersById', 'id', isUsersLoading, paramEntityIds, users)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      users.value = appliedUsers.value?.slice(0) ?? []
      break
    case LogEntityClass.GROUP:
      appliedUserGroups.value = await fetchAppliedEntities<UserGroupI>(appliedUserGroups, UserGroupsService, 'getUserGroupsByCode', 'code', isUserGroupsLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.PROFILE:
      appliedProfiles.value = await fetchAppliedEntities<UserProfileI>(appliedProfiles, UserProfilesService, 'getUserProfilesByCodes', 'code', isProfileLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.ROLE:
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.SECTOR:
      appliedSectors.value = await fetchAppliedEntities<UserSessionSector>(appliedSectors, SectorsService, 'getSectorsByCodes', 'code', isSectorLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    default:
      entityClass.value = undefined
      entityId.value = undefined
      devicesDvtmEsoft.value = []
      applications.value = []
      users.value = []
      break
  }
}

const onPropsEntityClassChanged = (entityClass?: string) => {
  onPropsEntityChanged(entityClass, props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined)
}
const onPropsEntitySubClassChanged = () => {
  onPropsEntityChanged(
    props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as LogEntityClass | undefined,
    props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined)
}

const onDevicesDvtmEsoftSelected = (devicesDvtmEsoft: ReactiveArrayObject<DeviceI>) => {
  if (!devicesDvtmEsoft.value.length) {
    entityClass.value = undefined
    entitySubClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT
    entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT
    entityId.value = getDeviceIds(devicesDvtmEsoft.value)
  }
}

const onEntityLoadingUpdated = (loading: boolean) => {
  if (!loading && onPropsEntityChangedPending) {
    onPropsEntityChangedPending = false
    onPropsEntityChanged(
      props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as LogEntityClass | undefined,
      props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined)
  }
}

const onApplicationsSelected = (applications: ReactiveArrayObject<ApplicationI>) => {
  if (!applications.value.length) {
    entityClass.value = undefined
    entitySubClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT
    entitySubClass.value = LogEntitySubClass.APPLICATION
    entityId.value = getApplicationIds(applications.value)
  }
}

const onUsersSelected = (users: ReactiveArrayObject<UserDataI>) => {
  updateInProgress.value = true

  if (!users.value.length) {
    entityClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.USER
    entitySubClass.value = undefined
    entityId.value = getUserIds(users.value)
  }

  updateInProgress.value = false
}

const onChange = (filter: Filters, value: FilterSelectionValue) => {
  emit('change', filter, value)
}

const onEnter = (event: Event) => {
  emit('enter', event)
}

const logLevelOptions: Ref<Array<Option | Record<string, any>>> = ref(Object.values(LogLevel).map((value) => {
  return {
    value,
    label: value ? `logs.level.value.${value}` : '',
  }
}))

const eventTypeOptions = computed<Array<Option>>((): Option[] => {
  return (Object.values(LogEventType).filter((value): boolean => {
    let permission: boolean

    switch (value) {
      case LogEventType.CYBER:
        permission = can(ACL_ROLES.CYBER_LOGS_VIEW)
        break
      case LogEventType.DEVICE:
        permission = can(ACL_ROLES.DEVICE_LOGS_VIEW)
        break
      case LogEventType.DEVICE_FLEET:
        permission = can(ACL_ROLES.DEVICE_FLEET_LOGS_VIEW)
        break
      case LogEventType.SYSTEM:
        permission = can(ACL_ROLES.SYSTEM_LOGS_VIEW)
        break
      default:
        permission = false
    }
    return permission
  }).map((value: LogEventType): Option => {
    const label = value ? t(`logs.eventType.value.${value}`) : ''
    return {
      value,
      label,
    }
  }))
})

const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: Filters.LOG_LEVEL,
    footerEnabled: true,
    footerId: 'level-footer',
    header: levelLabel,
    id: 'level',
    inputType: FilterInputType.CHECKBOX,
    menuLabel: levelLabel,
    options: logLevelOptions,
    tagPrefix: levelLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.LOG_EVENT_TYPE,
    footerId: 'eventType-footer',
    footerEnabled: true,
    header: eventTypeLabel,
    inputType: FilterInputType.CHECKBOX,
    id: 'eventType',
    menuLabel: eventTypeLabel,
    options: eventTypeOptions,
    tagPrefix: eventTypeLabel,
    translate: false,
  },
  {
    disabled: false,
    filter: Filters.LOG_ACTION,
    footerEnabled: true,
    footerId: 'action-footer',
    header: actionLabel,
    id: 'action',
    inputType: FilterInputType.CUSTOM,
    menuLabel: actionLabel,
    tagPrefix: actionLabel,
    translate: false,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerEnabled: true,
    footerId: 'user-footer',
    header: userLabel,
    id: 'user',
    inputType: FilterInputType.CUSTOM,
    menuLabel: userLabel,
    tagPrefix: userLabel,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerEnabled: true,
    footerId: 'device-dvtm-esoft-device-footer',
    header: deviceDvtmEsoftLabel,
    id: 'device-dvtm-esoft-device',
    inputType: FilterInputType.CUSTOM,
    menuLabel: deviceDvtmEsoftLabel,
    tagPrefix: deviceDvtmEsoftLabel,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerId: 'device-dvtm-esoft-application-footer',
    footerEnabled: true,
    header: applicationLabel,
    inputType: FilterInputType.CUSTOM,
    id: 'device-dvtm-esoft-application',
    menuLabel: applicationLabel,
    tagPrefix: applicationLabel,
  },
]

function getFilterFormSectionById(id: string, subclassId?: string): FilterFormSection | undefined {
  return filterFormSections.find((formSection) => {
    const testId = !subclassId ? id : (`${id}-${subclassId}`)
    return formSection.id === testId
  })
}

function onEntityClassAndSubChanged(newEntityClass?: LogEntityClass, newEntitySubClass?: LogEntitySubClass) {
  for (const currentClass of editableEntityClasses) {
    let filterFormSection: FilterFormSection | undefined

    if (currentClass !== LogEntityClass.DEVICE_DVTM_ESOFT) {
      filterFormSection = getFilterFormSectionById(currentClass as string)

      if (filterFormSection) {
        filterFormSection.disabled = !!newEntityClass && (currentClass !== newEntityClass)
      }
    } else {
      for (const currentSubClass of editableEntitySubClasses[currentClass as string]) {
        filterFormSection = getFilterFormSectionById(currentClass as string, currentSubClass)

        if (filterFormSection) {
          filterFormSection.disabled = !!newEntityClass && (currentClass !== newEntityClass || currentSubClass !== newEntitySubClass)
        }
      }
    }
  }
}

function onEntityClassChanged(newEntityClass?: LogEntityClass) {
  onEntityClassAndSubChanged(newEntityClass, entitySubClass.value)
}

function onEntitySubClassChanged(newEntitySubClass?: LogEntitySubClass) {
  onEntityClassAndSubChanged(entityClass.value, newEntitySubClass)
}

function onAppliedFilterChange() {
  actions.value = props.filters.get(Filters.LOG_ACTION)?.value as string[] | undefined
  const appliedEntityClass = props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as string | undefined
  const appliedEntityId = props.filters.get(Filters.LOG_ENTITY_ID)?.value as Array<string> | undefined

  if (appliedEntityClass !== entityClass.value) {
    onPropsEntityClassChanged(appliedEntityClass)
  } else if (!equals(appliedEntityId && entityId.value)) {
    onPropsEntityChanged(appliedEntityClass, appliedEntityId)
  } else if (appliedEntitySubClass.value !== entitySubClass.value) {
    onPropsEntitySubClassChanged()
  }
}

function applyFilter() {
  if (!updateInProgress.value) {
    emit('change', Filters.LOG_ACTION, actions.value?.slice(0) ?? [])
    emit('change', Filters.LOG_ENTITY_CLASS, entityClass.value ?? '')
    emit('change', Filters.LOG_ENTITY_ID, entityId.value?.slice(0) ?? [])
    appliedEntitySubClass.value = entitySubClass.value
    emit('enter')
  } else {
    applyPending.value = true
  }
}

const onUpdateInProgressChanged = (inProgress: boolean) => {
  if (!inProgress && applyPending.value) {
    applyFilter()
    applyPending.value = false
  }
}

function clearFilter() {
  updateInProgress.value = true
  componentId.value = undefined
  entityClass.value = undefined
  entitySubClass.value = undefined
  entityId.value = []
  actions.value = []
  sortBy.value = undefined
  sortDirection.value = undefined
  devicesDvtmEsoft.value = []
  applications.value = []
  users.value = []
  appliedDevicesDvtmEsoft.value = []
  updateInProgress.value = false
  applyFilter()
}

function discardFilter() {
  updateInProgress.value = true
  componentId.value = props.filters.get(Filters.LOG_COMPONENT_ID)?.value as LogComponentId | undefined
  entityClass.value = props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as LogEntityClass | undefined
  entitySubClass.value = appliedEntitySubClass.value
  entityId.value = props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined
  actions.value = props.filters.get(Filters.LOG_ACTION)?.value as string[] | undefined
  sortBy.value = props.filters.get(Filters.SORT_BY)?.value as LogSortBy | undefined
  sortDirection.value = props.filters.get(Filters.SORT_DIRECTION)?.value as LogSortDirection | undefined

  switch (entityClass.value) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      devicesDvtmEsoft.value = appliedDevicesDvtmEsoft.value?.slice(0) ?? []
      applications.value = appliedApplications.value?.slice(0) ?? []
      break
    case LogEntityClass.USER:
      users.value = appliedUsers.value?.slice(0) ?? []
      break
    default:
      break
  }

  updateInProgress.value = false
}

function onFilterShowing() {
  onEntityClassAndSubChanged(entityClass.value, entitySubClass.value)
}

function updateEntityFilterSection(paramFilterMap: FiltersSelection,
  entityClass: LogEntityClass,
  entitySubclass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramUserGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
) {
  let entityIds: string[] = []

  switch (entityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubclass === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        if (paramDevicesDvtmEsoft) {
          entityIds = filterEmptyValues<string>(paramDevicesDvtmEsoft.map(deviceDvtmEsoft => deviceDvtmEsoft.id))
        }
      } else if (entitySubclass === LogEntitySubClass.APPLICATION) {
        if (paramApplications) {
          entityIds = filterEmptyValues<string>(paramApplications.map(application => application.id))
        }
      }
      break
    case LogEntityClass.GROUP:
      if (paramUserGroups) {
        entityIds = filterEmptyValues<string>(paramUserGroups.map(userGroup => userGroup.code))
      }
      break
    case LogEntityClass.PROFILE:
      if (paramProfiles) {
        entityIds = filterEmptyValues<string>(paramProfiles.map(profile => profile.code))
      }
      break
    case LogEntityClass.ROLE:
      if (paramRoles) {
        entityIds = filterEmptyValues<string>(paramRoles.map(role => role.id ? String(role.id) : undefined))
      }
      break
    case LogEntityClass.SECTOR:
      if (paramSectors) {
        entityIds = filterEmptyValues<string>(paramSectors.map(sector => sector.code))
      }
      break
    case LogEntityClass.USER:
      if (paramUsers) {
        entityIds = filterEmptyValues<string>(paramUsers.map(user => user.id))
      }
      break
    default:
      break
  }

  paramFilterMap.set(Filters.LOG_ENTITY_CLASS, {
    key: 'entityClass',
    operator: '=',
    value: entityClass,
  })

  paramFilterMap.set(Filters.LOG_ENTITY_ID, {
    key: 'entityId',
    operator: '=',
    value: entityIds,
  })
}

function updateFilterSelection(
  filters: FiltersSelection,
  actions?: string[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
): FiltersSelection {
  filters.set(Filters.LOG_ACTION, {
    key: 'action',
    operator: '=',
    value: actions ?? [],
  })

  if (entityClass) {
    updateEntityFilterSection(filters, entityClass, entitySubClass, paramDevicesDvtmEsoft, paramApplications, paramUsers, paramGroups, paramProfiles, paramRoles, paramSectors)
  }

  return filters
}

function deleteDeviceDvtmEsoftFilter(id: string) {
  const index = devicesDvtmEsoft.value.findIndex(device => device.id === id)

  if (index >= 0) {
    devicesDvtmEsoft.value.splice(index, 1)
  }
}

function deleteApplicationFilter(id: string) {
  const index = applications.value.findIndex(application => application.id === id)

  if (index >= 0) {
    applications.value.splice(index, 1)
  }
}

function deleteUserFilter(id: string) {
  const index = users.value.findIndex(user => user.id === id)

  if (index >= 0) {
    users.value.splice(index, 1)
  }
}

function deleteEntityTag(id: string) {
  switch (entityClass.value) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both DVTM E-Soft devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubClass.value === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        deleteDeviceDvtmEsoftFilter(id)
      } else if (entitySubClass.value === LogEntitySubClass.APPLICATION) {
        deleteApplicationFilter(id)
      }
      break
    case LogEntityClass.DEVICE:
      break
    case LogEntityClass.USER:
      deleteUserFilter(id)
      break
    default:
      break
  }
}

function deleleTagFromList(valueToDelete: string, list?: string[] | null) {
  if (list) {
    const index = list.findIndex(value => value === valueToDelete)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

function buildEntityTagFilter(
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
): UniqueLabel[] {
  let tagFilters: UniqueLabel[] = []
  switch (entityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubClass === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        if (paramDevicesDvtmEsoft != null && Array.isArray(paramDevicesDvtmEsoft)) {
          tagFilters = filterEmptyValues<UniqueLabel>(paramDevicesDvtmEsoft.map(deviceDvtmEsoft => filtersUtils.getDeviceDvtmEsoftTag(deviceDvtmEsoft, deviceDvtmEsoftLabel)))
        }
      } else if (entitySubClass === LogEntitySubClass.APPLICATION) {
        if (paramApplications != null && Array.isArray(paramApplications)) {
          tagFilters = filterEmptyValues<UniqueLabel>(paramApplications.map(application => filtersUtils.getApplicationTag(application, applicationLabel)))
        }
      }
      break
    case LogEntityClass.GROUP:
      if (paramGroups != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(paramGroups.map(userGroup => filtersUtils.getUserGroupTag(userGroup, userGroupLabel)))
      }
      break
    case LogEntityClass.PROFILE:
      if (paramProfiles != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(paramProfiles.map(profile => filtersUtils.getProfileTag(profile, profileLabel)))
      }
      break
    case LogEntityClass.ROLE:
      if (paramRoles != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(paramRoles.map(role => filtersUtils.getRoleTag(role, roleLabel)))
      }
      break
    case LogEntityClass.SECTOR:
      if (paramSectors != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(paramSectors.map(sector => filtersUtils.getSectorTag(sector, sectorLabel)))
      }
      break
    case LogEntityClass.USER:
      if (paramUsers != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(paramUsers.map(user => filtersUtils.getUserTag(user, userLabel)))
      }
      break
    default:
  }

  return tagFilters
}

function buildFilterTags(
  actions?: string[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
): UniqueLabel[] {
  const tagFilters: UniqueLabel[] = []

  if (actions && actions.length !== 0) {
    for (const action of actions) {
      const filterTag = filtersUtils.getTag(actionLabel, action, action)
      if (filterTag) {
        tagFilters.push(filterTag)
      }
    }
  }

  if (entityClass) {
    tagFilters.push(...buildEntityTagFilter(entityClass, entitySubClass, paramDevicesDvtmEsoft, paramApplications, paramUsers, paramGroups, paramProfiles, paramRoles, paramSectors))
  }

  return tagFilters
}

const selectedFilterTags = computed((): UniqueLabel[] => {
  return buildFilterTags(actions.value, entityClass.value, entitySubClass.value, devicesDvtmEsoft.value, applications.value, users.value)
})

const selectedFilterMap = computed((): FiltersSelection => {
  const filterSelection: FiltersSelection = new Map<Filters, FilterSelectionDefinition>()
  return updateFilterSelection(filterSelection, actions.value, entityClass.value, entitySubClass.value, devicesDvtmEsoft.value, applications.value, users.value)
})

const appliedFilterTags = computed((): UniqueLabel[] => {
  const appliedActions = props.filters.get(Filters.LOG_ACTION)?.value as string[] | undefined
  const appliedEntityClass = props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as LogEntityClass | undefined
  return buildFilterTags(appliedActions, appliedEntityClass, appliedEntitySubClass.value, appliedDevicesDvtmEsoft.value, appliedApplications.value, appliedUsers.value, appliedUserGroups.value, appliedProfiles.value, appliedRoles.value, appliedSectors.value)
})

function onDeletSelectedTag(tag: string, uid?: string) {
  updateInProgress.value = true
  deleleTagFromList(uid ?? tag, actions.value)
  deleteEntityTag(uid ?? tag)
  updateInProgress.value = false
}

function onAppliedTagDeleteClick(tag: string, uid?: string) {
  updateInProgress.value = true
  deleleTagFromList(uid ?? tag, actions.value)
  deleleTagFromList(uid ?? tag, entityId.value)

  if (!entityId.value?.length && entityClass.value !== undefined) {
    entityClass.value = undefined
    entitySubClass.value = undefined
  }

  updateInProgress.value = false
  applyFilter()
}

watch(() => applications, onApplicationsSelected, { deep: true })
watch(() => devicesDvtmEsoft, onDevicesDvtmEsoftSelected, { deep: true })
watch(() => entityClass.value, onEntityClassChanged)
watch(() => entitySubClass.value, onEntitySubClassChanged)
watch(() => isEntityLoading.value, onEntityLoadingUpdated)
watch(() => users, onUsersSelected, { deep: true })
watch(() => updateInProgress.value, onUpdateInProgressChanged)

onMounted(() => {
  onAppliedFilterChange()
})

</script>
<template>
  <div class="relative flex justify-between pt-8">
    <lxc-log-download
      :data="props.rowsSelected"
    />
    <div class="relative flex justify-end">
      <lxc-period-picker
        v-model="formPeriod"
        type="primary"
        button-size
        :formatter="$t('logs.filters.timestamp.formatter')"
        :i18n="locale"
        :separator="periodSeparator"
        :placeholder="$t('logs.filters.timestamp.placeholder')"
        class="mr-3"
      />

      <lxc-filters
        :applied-tags="appliedFilterTags"
        :filter-sections="filterFormSections"
        :filters="props.filters"
        :filters-by-type="selectedFiltersByTypes"
        :selected-filters="selectedFilterMap"
        :selected-tags="selectedFilterTags"
        :teleported-tags="tagsetSelector"
        @apply="applyFilter"
        @applied-filter-change="onAppliedFilterChange"
        @change="onChange"
        @delete="onAppliedTagDeleteClick"
        @delete-selected="onDeletSelectedTag"
        @enter="onEnter"
        @discard="discardFilter"
        @reset="clearFilter"
        @showing="onFilterShowing"
      >
        <template #action>
          <lxc-log-action-filter
            v-model="actions"
            :label="actionLabel"
          />
        </template>

        <template #device-dvtm-esoft-device>
          <lxc-log-device-filter
            v-model="devicesDvtmEsoft.value"
            :label="deviceDvtmEsoftLabel"
          />
        </template>

        <template #device-dvtm-esoft-application>
          <lxc-log-application-filter
            v-model="applications.value"
            :label="applicationLabel"
          />
        </template>

        <template #user>
          <lxc-log-user-filter
            v-model="users.value"
            :label="userLabel"
          />
        </template>
      </lxc-filters>
    </div>
  </div>
  <div
    :id="tagsetId"
  />
</template>
