<script setup lang="ts">
import type { Ref } from 'vue'
import { onMounted } from 'vue'
import type { SectorI, UserGroupI } from '@lxc/app-device-types'
import { useUserGroups } from '~/composables/useUserGroups'
import { PATHS } from '~/constants/paths'
import { SectorResource } from '~/types'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { router } from '~/plugins/router'

const { t } = useI18n()
const props = defineProps<{
  sector: SectorI
}>()

const {
  isLoading,
  results: userGroups,
  error,
  fetchData,
  canManageItems,
  getDescription,
} = useUserGroups()

const selectedItems: Ref<any[]> = ref([])
const displayActions = computed(() => canManageItems(props.sector))

onMounted(fetchSectorUserGroups)

async function fetchSectorUserGroups(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()
  params.set('sectorsInclude', [props.sector.code])
  await fetchData(page, pageSize, undefined, params)
}

async function refresh() {
  await fetchSectorUserGroups()
  selectedItems.value = []
}

function onRowClick(userGroup: UserGroupI) {
  router.push(`${PATHS.USER_MANAGEMENT_GROUPS}/${userGroup.code}`)
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="code"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.USER_GROUP"
    @change="refresh"
  >
    <el-table-column
      prop="label"
      :label="$t('table.header.label')"
      data-cy="col-label"
    />
  </lxc-sector-toolbar>

  <lxc-container
    :is-loading="isLoading && !userGroups"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="code"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="userGroups"
      :display-actions="displayActions"
      :resource="SectorResource.USER_GROUP"
      :get-description="getDescription"
      @pagination:get-next-page="fetchSectorUserGroups"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <el-table-column
        prop="label"
        :label="$t('table.header.label')"
        data-cy="col-label"
      />
    </lxc-sectors-details-table>
  </lxc-container>
</template>
