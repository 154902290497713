/**
 * Store of the gobal leave page confirm message
 *  - Used by component /src/App.vue to display a confirm message about leaving the current page
 *  - Used by the beforeEach router (src/plugins/router.ts) to show the leave page confirm message if it is activated
 * Use method activate(true/false) in components in order to activate/deactivate the leave page confirm message
 */
import { defineStore } from 'pinia'
import { router } from '~/plugins/router'

export const useConfirmLeavePageStore = defineStore('useConfirmLeavePageStore', () => {
  const state = reactive<{
    count: number
    shown: boolean
    toFullPath?: string | null
  }>({
    count: 0,
    shown: false,
    toFullPath: undefined,
  })

  /**
   * Check if the leave page confirm message is activated and must be displayed
   * @returns true if leave page confirm is activated, false otherwise
   */
  function isActivated() {
    return state.count > 0
  }

  /**
   * Activate/deactivate the leave page confirm message in order to be or not displayed
   * @param active boolean true to activate the leave page confirm message, false otherwise
   */
  function activate(active: boolean) {
    if (active) {
      state.count++
    } else {
      state.count--
    }
  }

  /**
   * Show the leave page confirm message
   * @param toFullPath the page to redirect to after confirm
   */
  function show(toFullPath: string) {
    state.toFullPath = toFullPath
    state.shown = true
  }

  /**
   * Check if the leave page is confirmed or not
   * @returns true if the user has confirmed leaving the page, false otherwise
   */
  function confirmed(): boolean {
    return !!state.toFullPath
  }

  /**
   * Confirm the leave page
   */
  function confirm() {
    if (state.toFullPath) {
      router.push(state.toFullPath)
    }
  }

  /**
   * Cancel the leave page
   */
  function cancel() {
    state.toFullPath = null
  }

  /**
   * Reset the leave page state
   */
  function reset() {
    state.count = 0
    state.shown = false
    state.toFullPath = null
  }

  return {
    ...toRefs(state),
    isActivated,
    activate,
    show,
    confirmed,
    confirm,
    cancel,
    reset,
  }
})
