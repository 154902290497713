import type { ApiListResult, CSRI, KeystoreCSRRequestI, KeystoreCertificateDetailI, KeystoreCertificateI, KeystoreSelfsignedCertificateRequestI } from '@lxc/app-device-types'
import { CACertificateItemType } from '@lxc/app-device-types'
import type { AxiosProgressEvent } from 'axios'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'

export class KeystoreService extends RestService {
  protected BASE_URL = '/keystore'
  protected CSR_PATH = '/csr'
  protected CERTIFICATE_PATH = '/certificate'
  /**
   * Get list of certificates on Keystore
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @return List of keystore certificates with context
   */
  getListOfCertificates(page: number, pageSize: number, search?: string | FiltersSelection, sort?: string): Promise<ApiListResult<KeystoreCertificateI>> {
    const params = { page, pageSize, search, sort }
    return this.$api.get(this.BASE_URL, { params })
  }

  /**
   * Get a certificate by its alias
   * @param alias Identifier of the certificate
   * @returns The certificate detail for the alias
   */
  getCertificate(alias: string): Promise<KeystoreCertificateDetailI> {
    return this.$api.get(`${this.BASE_URL}/${alias}`)
  }

  /**
   * Request a self-signed certificate
   * @param certificateRequest selfsigned certificate request data
   */
  generateSelfSignedCertificate(certificateRequest: KeystoreSelfsignedCertificateRequestI): Promise<void> {
    const config = {
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
    }

    return this.$api.post(`${this.BASE_URL}${this.CERTIFICATE_PATH}`, { certificateRequest }, config)
  }

  /**
   * Request a CSR
   * @param csrRequest the CSR request data
   * @returns the CSR for the alias
   */
  generateCertificateSigningRequest(csrRequest: KeystoreCSRRequestI): Promise<CSRI> {
    const config = {
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
    }

    return this.$api.post(`${this.BASE_URL}${this.CSR_PATH}`, { csrRequest }, config)
  }

  /**
   * Post a certificate udate into keystore for an alias
   * @param alias Identifier of the certificate
   * @param type enum CERTIFICATE or CSR
   * @param certificate certificate data
   * @param tags Array of objects (Root Type for Tags)
   */
  updateCertificate(alias: string, type: CACertificateItemType, progress: Ref<number | undefined | null>, tags: string[], certificate?: File): Promise<void> {
    const formData = new FormData()
    const isCSRUploading = type === CACertificateItemType.CSR && certificate != null

    if (isCSRUploading) {
      formData.append('certificate', certificate, certificate.name)
    }

    if (tags.length) {
      tags.forEach(tag => formData.append('tags[]', tag))
    } else {
      formData.append('tags[]', '')
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (isCSRUploading) {
          progress.value = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0
        }
      },
    }

    return this.$api.put(`${this.BASE_URL}${this.CERTIFICATE_PATH}/${alias}`, formData, config)
  }

  /**
   * Delete a key entry by alias
   * @param alias Identifier of the certificate
   */
  deleteCertificate(alias: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${alias}`)
  }
}

export default new KeystoreService()
