import type { UniqueLabel } from '@lxc/app-device-common'

export function getUniqueLabelList(values?: Array<string>, disabled = false, deletable = true): Array<UniqueLabel> | undefined {
  return values?.sort().map((value: string): UniqueLabel => {
    return {
      label: value,
      uid: value,
      deletable,
      disabled,
    }
  })
}

export function getValuesFromUniqueLabels(uniqueLabels?: Array<UniqueLabel>): Array<string> | undefined {
  return uniqueLabels?.map((uniqueLabel: UniqueLabel) => uniqueLabel.uid)
}
