<script lang="ts" setup>
import type { SectorI, SectorItemActionResult } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import LxcInformationRow from '~/components/shared/LxcInformationRow.vue'
import { useApplication } from '~/composables/useApplication'
import { useDevices } from '~/composables/useDevices'
import { useDtwins } from '~/composables/useDtwins'
import { useToggle } from '~/composables/useToggle'
import { useUserGroups } from '~/composables/useUserGroups'
import { useUsers } from '~/composables/useUsers'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import SectorsService from '~/services/sectors.service'
import { useConfigStore } from '~/stores/useConfigStore'
import { Filters, SectorResource } from '~/types'
import LxcError from '~/utils/LxcError'
import {
  NotificationKey,
  setDetailMessageAsListItem,
  showNotificationError,
  showNotificationSuccess,
  showNotificationWarning,
} from '~/utils/notifications-tools'
import ILxcArrowUpLeft from '~icons/lxc/arrow-up-left'
import ILxcPlus from '~icons/lxc/plus'

const props = defineProps<{
  primaryKey: string
  sector: SectorI
  selectedItems: any[]
  resource: SectorResource
}>()
const emit = defineEmits(['change'])
defineExpose({
  openDetachModal,
})

const { t } = useI18n()
const [attachModalVisible, toggleAttachModalVisible] = useToggle()
const [detachModalVisible, toggleDetachModalVisible] = useToggle()

function getSearchComposable() {
  switch (props.resource) {
    case SectorResource.APPLICATION:
      return useApplication
    case SectorResource.DEVICE:
      return useDevices
    case SectorResource.DTWINS:
      return useDtwins
    case SectorResource.USER_GROUP:
      return useUserGroups
    case SectorResource.USER:
      return useUsers
  }
}

const useSearch = getSearchComposable()
const { defaultSectorCode } = storeToRefs(useConfigStore())
const {
  itemType,
  isLoading,
  results,
  error,
  getDescription,
  filters,
  fetchData,
  setFilter,
  canManageItems,
  getItemIds,
} = useSearch!()

const attachSelectedItems = ref<any[]>([])
const detachSelectedItems = ref<any[]>([])
const displayActions = computed(() => canManageItems(props.sector))
const detachDisabled: ComputedRef<boolean> = computed(() => !props.selectedItems?.length)

const FilterKey = computed(() => {
  switch (props.resource) {
    case SectorResource.DTWINS:
      return Filters.DTWIN_NAME_OR_SERIAL_NUMBER
    default:
      return Filters.NAME
  }
})

/**
 * Fetch items
 * @param page
 */
async function fetchItems(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()

  switch (props.resource) {
    case SectorResource.DTWINS:
      setFilter(Filters.DTWIN_IN_SECTORS, [defaultSectorCode.value!])
      setFilter(Filters.DTWIN_NOT_IN_SECTORS, [props.sector.code])
      break
    default:
      setFilter(Filters.SECTORS, [defaultSectorCode.value!])
      params.set('sectorsExclude', [props.sector.code])
  }

  await fetchData(page, pageSize, undefined, params)
}

/**
 * Clearing search input and refetch
 */
async function onSearchClear() {
  setFilter(FilterKey.value, '')
  await fetchItems()
}

/**
 * Search items
 */
async function search() {
  const params = new Map()

  switch (props.resource) {
    case SectorResource.DTWINS:
      setFilter(Filters.DTWIN_NOT_IN_SECTORS, [props.sector.code])
      break
    default:
      params.set('sectorsExclude', [props.sector.code])
  }

  await fetchData(DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE, undefined, params)
}

/**
 * Attach item(s) to the sector
 */
async function onAttachToSector() {
  if (attachSelectedItems.value.length > 0) {
    const response = await SectorsService.attachToSector(props.sector.code, getItemIds(attachSelectedItems), props.resource)
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error)
    } else {
      const responseWithItemDescription = response.map(action => ({
        ...action,
        name: getDescription(attachSelectedItems.value.find(item => item[props.primaryKey] === action.name)),
      }))
      displayActionStatus(responseWithItemDescription, `${NotificationKey.attachSuccess}.${itemType}`, `${NotificationKey.attachError}.${itemType}`)
    }

    clearSelectionAndCloseAttachModal()
    emit('change')
  } else {
    showNotificationWarning(t('sectors.modals.attach.errors.list_empty'))
  }
}

function clearSelectionAndCloseAttachModal() {
  toggleAttachModalVisible(false)
  attachSelectedItems.value = []
}

function clearSelectionAndCloseDetachModal() {
  toggleDetachModalVisible(false)
  detachSelectedItems.value = []
}

/**
 * Detach item(s) from the sector
 */
async function detachFromSector() {
  if (detachSelectedItems.value.length > 0) {
    const detachResponse = await SectorsService.detachFromSector(props.sector.code, getItemIds(detachSelectedItems), props.resource)
    if (LxcError.check(detachResponse)) {
      detachResponse.notify(NotificationKey.error)
    } else {
      const responseWithItemDescription = detachResponse.map(action => ({
        ...action,
        name: getDescription(detachSelectedItems.value.find(item => item[props.primaryKey] === action.name)),
      }))
      displayActionStatus(responseWithItemDescription, `${NotificationKey.detachSuccess}.${itemType}`, `${NotificationKey.detachError}.${itemType}`)
    }

    clearSelectionAndCloseDetachModal()
    emit('change')
  } else {
    showNotificationWarning(t('sectors.modals.confirmDetach.errors.list_empty'))
  }
}

/**
 * Show Action Status
 * @param data
 * @param successMessage
 * @param errorMessage
 */
function displayActionStatus(data: SectorItemActionResult[], successMessage: string, errorMessage: string) {
  const { notificationNumber: nbSuccess, msg: success } = getByStatus(data, true)
  const { notificationNumber: nbError, msg: errors } = getByStatus(data, false)

  if (nbError) {
    showNotificationError(t(errorMessage, { sectorName: props.sector.label }, nbError), errors)
  }

  if (nbSuccess > 0) {
    showNotificationSuccess(t(successMessage, { sectorName: props.sector.label }, nbSuccess), success)
  }
}

function getByStatus(data: any[], success: boolean): { msg: string; notificationNumber: number } {
  const notificationsByStatus = data.filter(d => d.success === success)

  const msg = notificationsByStatus
    .map(d => d.name)
    .filter(Boolean)
    .map(setDetailMessageAsListItem)
    .join('')

  return {
    msg,
    notificationNumber: notificationsByStatus.length,
  }
}

/**
 * Open Attach modal, fetch items before
 */
async function openAttachModal() {
  toggleAttachModalVisible()
  await fetchItems()
}

/**
 * Open detach modal, accept single or multiple item as arg
 * @param data
 */
function openDetachModal(data: any) {
  detachSelectedItems.value = !data.length ? [data] : data
  toggleDetachModalVisible()
}
</script>

<template>
  <lx-toolbar :is-right="true">
    <el-button
      v-if="displayActions"
      type="primary"
      size="large"
      :icon="ILxcPlus"
      @click="openAttachModal"
    >
      {{ $t(`sectors.details.buttons.attach.${resource}`) }}
    </el-button>

    <el-button
      v-if="displayActions"
      :disabled="detachDisabled"
      type="default"
      size="large"
      class="lxc-plain"
      :icon="ILxcArrowUpLeft"
      @click="openDetachModal(selectedItems)"
    >
      {{ $t('sectors.details.buttons.detach') }}
    </el-button>
  </lx-toolbar>

  <lxc-modal
    v-if="attachModalVisible"
    :show-confirm="true"
    :dialog-visible="attachModalVisible"
    :title="$t(`sectors.modals.attach.${resource}.title`)"
    width="70%"
    @confirm="onAttachToSector"
    @cancel="clearSelectionAndCloseAttachModal"
    @update:dialog-visible="toggleAttachModalVisible"
  >
    <div>
      <p class="modal-description">
        {{ $t(`sectors.modals.attach.${resource}.description`) }}
      </p>
      <lx-search-bar
        class="search-bar"
        :search-query="filters.get(FilterKey)"
        :is-loading="isLoading"
        :search-placeholder="$t('label.search')"
        @input-change="setFilter(FilterKey, $event)"
        @clear="onSearchClear"
        @search="search"
      />
      <lxc-container
        :is-loading="isLoading && !results"
        :error="error"
      >
        <lxc-sectors-details-table
          v-model="attachSelectedItems"
          :primary-key="primaryKey"
          :resource="resource"
          :is-loading="isLoading"
          :display-actions="false"
          :selection="true"
          :items="results"
          :get-description="getDescription"
          @pagination:get-next-page="fetchItems"
        >
          <slot />
        </lxc-sectors-details-table>
      </lxc-container>
    </div>
  </lxc-modal>

  <lxc-modal
    :show-confirm="true"
    :dialog-visible="detachModalVisible"
    :title="$t(`sectors.modals.confirmDetach.${resource}.title`)"
    @confirm="detachFromSector"
    @cancel="clearSelectionAndCloseDetachModal"
    @update:dialog-visible="toggleAttachModalVisible"
  >
    <div>
      <lxc-information-row :title="$t(`sectors.modals.confirmDetach.${props.resource}.warning`, detachSelectedItems.length)" />
      <p class="update-description">
        {{
          $t(`sectors.modals.confirmDetach.${props.resource}.description`, {
            sectorLabel: sector.label,
            name: getDescription(detachSelectedItems[0])
          }, detachSelectedItems.length)
        }}
      </p>
      <ul v-if="detachSelectedItems.length > 1">
        <li
          v-for="item in detachSelectedItems"
          :key="item"
        >
          {{ getDescription(item) }}
        </li>
      </ul>
    </div>
  </lxc-modal>
</template>

<style lang='scss' scoped>
.update-description {
  font-size: 16px;
  word-break: break-word;
}

.modal-description {
  margin-bottom: 0.5rem;
  font-size: 14px;
}
</style>
