<script lang='ts' setup>

import { userTabsItems } from '~/components/userManagement/tab-user-management.config'

const tabItems = userTabsItems

</script>
<template>
  <el-container
    data-cy="page-users-component"
    direction="vertical"
    class="pt-8"
  >
    <lxc-breadcrumb class="mb-8 px-8" />
    <lxc-tab-bar
      :tabs-items="tabItems"
      active-page-index="0"
    />
    <lxc-user-list :actions="true" />
  </el-container>
</template>
