import type {
  ApiListResult,
  DtwinI,
  DtwinModelI,
  ImportMode,
  ImportResultsI,
  PaginatedListI,
} from '@lxc/app-device-types'
import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import type { DeviceTypeI } from '@lxc/app-device-types/dist/interfaces/mobile.interface'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'

export class DtwinsService extends RestService {
  protected BASE_URL = '/dtwins'
  protected PROXY_URL_DTWINS = '/proxy?serviceId=DTWINS'
  protected PROXY_URL_DATAM = '/proxy?serviceId=DATAM'

  /**
   * Retrieve the device models
   * @param page
   * @param pageSize
   * @returns
   */
  getModels(page: number, pageSize: number): Promise<PaginatedListI<DtwinModelI>> {
    const params: Record<string, any> = {
      page,
      pageSize,
    }

    const target = `deviceModels?${new URLSearchParams(params).toString()}`

    return this.$api.get(this.PROXY_URL_DTWINS, { params: { target } })
  }

  /**
   * Fetch LPP4/LogUp devices.
   * @param page
   * @param pageSize
   * @param filter
   */
  async getDtwins(page: number, pageSize: number, filter?: string | FiltersSelection): Promise<ApiListResult<DtwinI>> {
    const params: Record<string, any> = {
      page,
      pageSize,
      filter,
    }
    const config: AxiosRequestConfig = {
      params,
    }

    return await this.$api.get(`${this.BASE_URL}`, config)
      .then((response) => {
        if (!(response as unknown as PaginatedListI<DtwinI>).pagination && !(response as unknown as PaginatedListI<DtwinI>).results) {
          return response
        }
        return {
          context: (response as unknown as PaginatedListI<DtwinI>).pagination,
          data: (response as unknown as PaginatedListI<DtwinI>).results,
        }
      })
      .catch(error => error)
  }

  /**
   * Fetch device types
   * @returns
   */
  async getDtwinTypes(): Promise<Array<DeviceTypeI>> {
    return this.$api.get(`${this.BASE_URL}/types`)
  }

  /**
   * Retrieve device
   * @param id
   * @param showMetadata If set to true, ask the dtwin to add the device metadata. Default: false.
   */
  getDtwin(id: string, showMetadata?: boolean): Promise<DtwinI> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, { params: { target: `deviceTwins/${id}?showMetadata=${showMetadata ?? false}` } })
  }

  /**
   * Update device properties
   * @param uid
   * @param data
   */
  patchDtwin(uid: string, data: Record<string, any>): Promise<void> {
    return this.$api.patch(`${this.PROXY_URL_DTWINS}`, data, {
      params: {
        target: `deviceTwins/${uid}/attributes`,
      },
    })
  }

  /**
   * Import devices
   */
  import(file: File, progress: Ref<number | undefined | null>, analyze: ImportMode): Promise<ImportResultsI> {
    const formData = new FormData()
    formData.append('file', file)
    return this.$api.post(`${this.BASE_URL}/import?mode=${analyze}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0
      },
      timeout: Infinity,
    })
  }

  /**
   * Retrieve device configuration
   * @param id
   * @returns
   */
  getDtwinTelemetries(id: string): Promise<any> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, { params: { target: `deviceTwins/${id}/dataviz/configuration` } })
  }

  getDtwinTelemetryValues(id: string, name: string, startTimestampNs: number, endTimestampNs: number, calculatedTelemetry: boolean): Promise<any> {
    return this.$api.get(
      this.PROXY_URL_DATAM,
      {
        params: {
          target: `devices/${id}/telemetry/${name}?startDateTime=${startTimestampNs}&endDateTime=${endTimestampNs}&calculatedTelemetry=${calculatedTelemetry}`,
        },
      },
    )
  }
}

export default new DtwinsService()
