<script setup lang="ts">
import type { DeviceTypeI, MobileInterfaceI } from '@lxc/app-device-types/dist/interfaces/mobile.interface'
import { useAcl } from 'vue-simple-acl'
import { useMobileInterface } from '~/composables/useMobileInterface'
import { PATHS } from '~/constants/paths'
import { ACL_ROLES } from '~/types'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  interfaceFormShown: boolean
}>()

const {
  isLoading,
  results,
  error,
  fetchData,
} = useMobileInterface()

const route = useRoute()
const router = useRouter()

const emits = defineEmits([
  'update:sideCanvasShown',
])

const selectedInterfaceClientId: Ref<string|undefined> = ref()

const acl = useAcl()
const canManageMobileApp = computed(() => acl.can(ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM))

const formSideCanvasShown = computed({
  get: () => {
    return props.interfaceFormShown
  },
  set: (shown: boolean) => {
    emits('update:sideCanvasShown', shown)
  },
})

const formatDeviceTypes = (deviceTypes: Array<DeviceTypeI>): Array<string> => {
  const extractedDeviceTypeFriendlyName: Array<string> = []
  for (const deviceType of deviceTypes) {
    extractedDeviceTypeFriendlyName.push(deviceType.friendlyName ?? 'unknown')
  }

  let res: Array<string> = []
  if (extractedDeviceTypeFriendlyName.length > 0) {
    res = extractedDeviceTypeFriendlyName
  }
  return res
}

const loadData = async(page?: number, pageSize?: number) => {
  if (page && pageSize) {
    await fetchData(page, pageSize)
  } else {
    await fetchData(results.value?.context.page, results.value?.context.pageSize)
  }
}

const displaySelectedInterfaceDetails = (interfaceClientId: string) => {
  selectedInterfaceClientId.value = interfaceClientId
  formSideCanvasShown.value = true
}

const onSelectInterface = async(selectedInterface: MobileInterfaceI) => {
  if (selectedInterface.clientId) {
    displaySelectedInterfaceDetails(selectedInterface.clientId)
    await router.push(`${PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP}/${selectedInterface.clientId}`)
  }
}

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP)
    && route.params.clientId) {
    displaySelectedInterfaceDetails(route.params.clientId as string)
  }
}

const onClearInterfaceClientId = () => {
  selectedInterfaceClientId.value = undefined
}

onMounted(async() => {
  await loadData()
  initDisplayFromRoute()
})
</script>
<template>
  <lxc-table
    :is-loading="isLoading"
    :data="results?.data"
    :context="results?.context"
    :error="error?.toError()"
    data-cy="interface-table"
    :clickable="true"
    :empty-text="$t('applicationMgt.tabs.mobileApp.empty')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectInterface"
  >
    <lxc-table-column
      prop="organization.name"
      :label="$t('applicationMgt.tabs.mobileApp.column.organizationName')"
    />
    <lxc-table-column
      prop="name"
      :label="$t('applicationMgt.tabs.mobileApp.column.name')"
    />
    <lxc-table-column
      :label="$t('applicationMgt.tabs.mobileApp.column.deviceTypes')"
      class="!py-0"
    >
      <template #default="scope">
        <div class="flex gap-1">
          <lxc-tag
            v-for="deviceType in formatDeviceTypes(scope.row.deviceTypes)"
            :key="scope.row.deviceTypes.indexOf(deviceType)"
          >
            {{ deviceType }}
          </lxc-tag>
        </div>
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="updatedAt"
      :label="$t('applicationMgt.tabs.mobileApp.column.updatedAt')"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.updatedAt, $t('dateFormat.datetime')) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :width="100"
      class="!py-0"
    >
      <template #default="scope">
        <div
          class="flex"
        >
          <lxc-mobile-application-delete-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :disabled="!canManageMobileApp"
            @deleted="loadData"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-mobile-application-form
    v-model:side-canvas-shown="formSideCanvasShown"
    :selected-interface-client-id="selectedInterfaceClientId"
    @interface-created="loadData"
    @interface-updated="loadData"
    @clear-interface-client-id="onClearInterfaceClientId"
  />
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
