enum Order {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

interface Sort {
  prop: string
  order: Order
}

export function useSort() {
  const sort: Ref<string|null|undefined> = ref()

  /**
   * Callback on sort changed => build & update the query sort value
   * @param event Sort
   */
  async function onSortChange(event: Sort) {
    if (event.prop) {
      switch (event.order) {
        case Order.DESCENDING:
          sort.value = `-${event.prop}`
          break
        case Order.ASCENDING:
          sort.value = `+${event.prop}`
          break
        default:
          sort.value = null
      }
    } else {
      sort.value = null
    }
  }

  return {
    sort,
    onSortChange,
  }
}
