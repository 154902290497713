<script setup lang="ts">
import { type CampaignI, CampaignState } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import ILxStepDone from '~icons/lxc-business/step-done'
import ILxStepInProgress from '~icons/lxc-business/step-in-progress'
import ILxStepToDo from '~icons/lxc-business/step-to-do'

const props = defineProps<{
  campaign?: CampaignI | null
}>()

const { t } = useI18n()

const formatCampaignDate: any = (date: string) => date ? dayjs(date).format(t('dateFormat.date')) : ''

enum Step{Creation, CreationToLaunch, Launch, LaunchToClosing, Closing}

const step = computed(() => {
  let step = Step.Creation
  const now = dayjs()
  const plannedStartAt = dayjs(props.campaign?.config?.plannedStartAt)
  const plannedEndAt = dayjs(props.campaign?.config?.plannedEndAt)

  if (props.campaign?.state) {
    if (now < plannedStartAt && [CampaignState.SCHEDULED, CampaignState.PAUSED].includes(props.campaign?.state)) {
      step = Step.Creation
    }
    else if ([CampaignState.SCHEDULED].includes(props.campaign?.state)) {
      step = Step.CreationToLaunch
    }
    else if (now < plannedEndAt && [CampaignState.RUNNING].includes(props.campaign?.state)) {
      step = Step.Launch
    }
    else if ([CampaignState.RUNNING].includes(props.campaign?.state)) {
      step = Step.LaunchToClosing
    }
    else if ([CampaignState.COMPLETE, CampaignState.CANCELLED, CampaignState.COMPLETE_WITH_ERRORS, CampaignState.PLATFORM_ERROR].includes(props.campaign?.state)) {
      step = Step.Closing
    }
    else if (now > plannedStartAt && now < plannedEndAt) {
      step = Step.CreationToLaunch
    }
    else if (now > plannedEndAt) {
      step = Step.LaunchToClosing
    }
  }
  else {
    if (now < plannedStartAt) {
      step = Step.Creation
    }
    else if (now > plannedStartAt && now < plannedEndAt) {
      step = Step.CreationToLaunch
    }
    else if (now > plannedEndAt) {
      step = Step.LaunchToClosing
    }
  }

  return step
})

</script>

<template>
  <ol class="relative ml-2 border-l border-gray-200 dark:border-gray-700">
    <li class="mb-10 ml-6  ">
      <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-white ">
        <ILxStepDone />
      </span>
      {{ $t('campaign.details.characteristics.lifeCyle.creation',{value: formatCampaignDate(campaign?.createdAt)}) }}
    </li>
    <li class="mb-10 ml-6">
      <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-white ">
        <ILxStepToDo v-if="step==Step.Creation" />
        <ILxStepInProgress v-if="step==Step.CreationToLaunch" />
        <ILxStepDone v-if="[Step.Launch, Step.LaunchToClosing, Step.Closing].includes(step)" />
      </span>
      {{ $t('campaign.details.characteristics.lifeCyle.launch',{value: formatCampaignDate(campaign?.config?.plannedStartAt)}) }}
    </li>
    <li class="mb-10 ml-6">
      <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-white ">
        <ILxStepToDo v-if="[Step.Creation, Step.CreationToLaunch, Step.Launch].includes(step)" />
        <ILxStepInProgress v-if="step==Step.LaunchToClosing" />
        <ILxStepDone v-if="step==Step.Closing" />
      </span>
      {{ $t('campaign.details.characteristics.lifeCyle.closing',{value: formatCampaignDate(campaign?.config?.plannedEndAt)}) }}
    </li>
  </ol>
</template>
