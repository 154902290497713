<script setup lang="ts">
import type { UniqueLabel } from '@lxc/app-device-common'
import { filterEmptyValues } from '@lxc/app-device-common'
import type { DeviceI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  label: string
  modelValue: DeviceI[]
}>()

const emit = defineEmits(['update:modelValue'])
const deviceName = ref('')
const deviceTypes: Ref<string[]> = ref([])
const deviceDeclinations: Ref<string[]> = ref([])

const selectedDevicesDvtmEsoft = computed({
  get(): DeviceI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<DeviceI>) {
    emit('update:modelValue', selected)
  },
})
const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(props.modelValue.map(deviceDvtmEsoft => filtersUtils.getDeviceDvtmEsoftTag(deviceDvtmEsoft, props.label)))
})

function deleleTagFromList(id: string) {
  if (selectedDevicesDvtmEsoft.value) {
    const index = selectedDevicesDvtmEsoft.value.findIndex(deviceDvtmEsoft => deviceDvtmEsoft.id === id)

    if (index != null && index >= 0) {
      selectedDevicesDvtmEsoft.value.splice(index, 1)
    }
  }
}
const onTagDeleteClick = (label: string, uid?: string) => {
  deleleTagFromList(uid ?? label)
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900">
    <div class="flex p-6 gap-6 flex-nowrap">
      <div class="flex-1 items-start">
        <lxc-log-device-type-filter v-model="deviceTypes" />
      </div>
      <div class="flex-1 items-start">
        <lxc-log-device-declination-filter
          v-model="deviceDeclinations"
          :types="deviceTypes"
        />
      </div>
    </div>
    <lxc-log-device-name-filter v-model="deviceName" />
    <lxc-log-device-list-filter
      v-model="selectedDevicesDvtmEsoft"
      :declinations="deviceDeclinations"
      :name="deviceName"
      :types="deviceTypes"
    />
  </div>
</template>
