<script setup lang="ts">
import type { DateShortcutCallback, PeriodI } from '@lxc/app-device-common'
import dayjs from 'dayjs'
import { Z_INDEX } from '@lxc/app-device-common/src/constants'
import { formatIsoDateToUtc } from '~/utils/date-tools'
import type { FilterSelectionValue } from '~/types'
import { Visibility } from '~/types'
const { locale } = useI18n()
const emit = defineEmits(['change', 'update:endDate', 'update:startDate'])

const props = defineProps<{
  startDate?: FilterSelectionValue | null
  endDate?: FilterSelectionValue | null
  visibiliy?: Visibility
  filterPanelVisibiliy?: Visibility
  shortcuts?: DateShortcutCallback
}>()

const dateFilterWrapper = ref<HTMLElement | undefined | null>()
const pickerLayer = ref<HTMLElement | undefined | null>()

const formPeriod = computed({
  get(): PeriodI {
    let period: PeriodI = {
      startDate: null,
      endDate: null,
    }

    if (props.startDate
        && props.endDate
        && typeof props.startDate === 'string'
        && typeof props.endDate === 'string') {
      period = {
        startDate: dayjs(props.startDate).utc(false).toDate(),
        endDate: dayjs(props.endDate).subtract(1, 'day').add(1, 'second').utc(false).toDate(), // opposite of the set function in order to avoid loop
      }
    }

    return period
  },
  set(newPeriod: PeriodI) {
    const startDate = newPeriod.startDate ? formatIsoDateToUtc(newPeriod.startDate) : ''
    const endDate = newPeriod.endDate ? formatIsoDateToUtc(dayjs(newPeriod.endDate).add(1, 'day').subtract(1, 'second')) : '' // add 1 day and withdraw 1 second in order to include the end day
    emit('update:startDate', startDate)
    emit('update:endDate', endDate)
    emit('change')
  },
})

const setCurrentComponentPosition = () => {
  if (dateFilterWrapper.value != null && pickerLayer.value != null) {
    const creationDateClientRect = dateFilterWrapper.value.getBoundingClientRect()
    pickerLayer.value.style.top = `${(creationDateClientRect.top)}px`
    pickerLayer.value.style.left = `${(creationDateClientRect.left)}px`
    pickerLayer.value.style.width = `${creationDateClientRect.width}px`
    pickerLayer.value.style.height = `${creationDateClientRect.height}px`
  }
}

const onVisibilityChange = (visibiliy?: Visibility) => {
  switch (visibiliy) {
    case Visibility.SHOWN:
    case Visibility.HIDDEN:
      setCurrentComponentPosition()
      break
  }
}

const onFilterPanelVisiblityChange = (visibiliy?: Visibility) => {
  switch (visibiliy) {
    case Visibility.SHOWN:
    case Visibility.HIDDEN:
      setCurrentComponentPosition()
      break
  }
}

const zIndex = Z_INDEX.SIDE_CANVAS + 200

const layerClass = computed((): string[] => {
  const layerClass = [
    'rounded-lg',
    'bg-white',
    'text-gray-900',
    'p-6',
    'date-picker-layer',
    'absolute',
    `z-[${zIndex}]`,
  ]

  if ((props.filterPanelVisibiliy === undefined || props.filterPanelVisibiliy === Visibility.SHOWN)
    && (props.visibiliy === undefined
  || props.visibiliy === Visibility.SHOWN)) {
    layerClass.push('block')
  } else {
    layerClass.push('hidden')
  }

  return layerClass
})

window.addEventListener('resize', setCurrentComponentPosition)
watch(() => props.filterPanelVisibiliy, onFilterPanelVisiblityChange)
watch(() => props.visibiliy, onVisibilityChange)
watch(() => pickerLayer.value, setCurrentComponentPosition)
watch(() => dateFilterWrapper.value, setCurrentComponentPosition)

</script>

<template>
  <div
    ref="dateFilterWrapper"
    class="w-full h-[6.08875rem]"
  />
  <Teleport
    to="body"
  >
    <div
      ref="pickerLayer"
      :class="layerClass"
    >
      <lxc-period-picker
        v-model="formPeriod"
        type="primary"
        button-size
        :formatter="$t('filters.creationDate.formatter.input')"
        :i18n="locale"
        :placeholder="$t('filters.creationDate.placeholder')"
        :separator="` ${$t('filters.creationDate.periodSeparator')} `"
        :shortcuts="shortcuts"
        class="mr-3"
      />
    </div>
  </Teleport>
</template>
