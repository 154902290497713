import type { LicenseStatusDatasharingFeatureI, LicenseStatusFeatureByDeviceTypeI } from '@lxc/app-device-types'
import { LicenseStatus, LicenseStatusFeatureByDeviceTypeStatus } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { StatusCodes } from 'http-status-codes'
import licenseService from '~/services/license.service'
import { FeaturesName } from '~/types/deviceFeatures'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'

export function useLicenseStatus() {
  const { t } = useI18n()
  const error = ref()

  const isBeyondExpirationDate: Ref<boolean> = ref(false)
  const isBeyondLimitOfAllDeviceType: Ref<boolean> = ref(false)

  const licenseExpirationDateWarning: Ref<LicenseWarningMessageI|undefined|null> = ref()
  const featureBeyondLimitWarnings: Ref<LicenseWarningMessageI[]> = ref([])

  function getlicenseFeaturesName() {
    const licenseFeaturesName: Ref<string[]> = ref([])
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())
    const featureByDeviceTypesExcludingDatasharing = licenseStatus.value?.featureByDeviceTypes.filter(
      feature => feature.name !== FeaturesName.DATA_SHARING)

    if (featureByDeviceTypesExcludingDatasharing) {
      for (const featureByDeviceType of featureByDeviceTypesExcludingDatasharing) {
        if (
          featureByDeviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED
          && !licenseFeaturesName.value.includes(featureByDeviceType.name)
        ) {
          licenseFeaturesName.value.push(featureByDeviceType.name)
        }
      }
      if (licenseStatus.value?.datasharingFeature) {
        licenseFeaturesName.value.push(FeaturesName.DATA_SHARING)
      }
    }

    return licenseFeaturesName
  }

  /**
   * Local interfaces
   */
  interface LicenseWarningMessageI {
    message: string
    alertType?: string
    featureName?: string
  }

  enum AlertTypeEnum {
    WARNING = 'warning',
    ERROR = 'error',
  }

  /** */

  /**
   * Get the license status
   */
  async function getLicenseStatus() {
    const response = await licenseService.getLicenseStatus()

    const {
      licenseStatus,
      isLicenseFeatureAdvancedFleetManagementPresent,
      isLicenseFeatureAdvancedFleetManagementActive,
      isLicenseFeatureDataSharingPresent,
      isLicenseFeatureDataSharingActive,
    } = storeToRefs(useLicenseStatusStore())

    if (LxcError.check(response)) {
      if (response.status !== StatusCodes.NOT_FOUND) {
        response.notify(NotificationKey.error)
        error.value = response
      }
      else {
        licenseStatus.value = null
      }
    } else {
      // update license status store
      licenseStatus.value = response
      isLicenseFeatureAdvancedFleetManagementPresent.value = await isLicenseFeaturePresent(FeaturesName.ADVANCED_FLEET_MGT)
      isLicenseFeatureAdvancedFleetManagementActive.value = await isLicenseFeatureActive(FeaturesName.ADVANCED_FLEET_MGT)
      isLicenseFeatureDataSharingPresent.value = licenseStatus.value.datasharingFeature !== undefined
      isLicenseFeatureDataSharingActive.value = licenseStatus.value.datasharingFeature !== undefined
    }

    return response
  }

  /**
   * Check Date warnings for the license
   */
  function checkDateWarningMessages() {
    licenseExpirationDateWarning.value = null
    isBeyondExpirationDate.value = false
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())
    const licenseFeaturesName = getlicenseFeaturesName()

    switch (licenseStatus.value?.statusOfLicense) {
      case LicenseStatus.WARNING_DATE:
        licenseExpirationDateWarning.value = {
          message: t('license.warningMessages.betweenMarginAndTolerancyOfExpirationDate', {
            featureName: t(`license.content.feature.${licenseFeaturesName.value[0]}`),
            count: licenseFeaturesName.value.length,
          }),
          alertType: AlertTypeEnum.WARNING,
        }
        break
      case LicenseStatus.EXPIRED_DATE:
        licenseExpirationDateWarning.value = {
          message: t('license.warningMessages.beyondLimitOfExpirationDate', {
            featureName: t(`license.content.feature.${licenseFeaturesName.value[0]}`),
            count: licenseFeaturesName.value.length,
          }),
          alertType: AlertTypeEnum.ERROR,
        }
        isBeyondExpirationDate.value = true
        break
    }
  }

  function checkDatasharingThresholdReached(datasharingFeature: LicenseStatusDatasharingFeatureI) {
    if (datasharingFeature.currentNumberOfInterfaces >= datasharingFeature.maxNumberOfInterfaces) {
      featureBeyondLimitWarnings.value.push(
        {
          message: t('license.warningMessages.atLeastOneDeviceBeyondLimit'),
          alertType: AlertTypeEnum.ERROR,
          featureName: FeaturesName.DATA_SHARING,
        },
      )
    }
  }

  /**
   * Check criteria limit warnings for the license for a feature in package
   * @param featureName
   */
  function checkCriteriaLimitWarningMessages(featureName: string) {
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())
    if (licenseStatus.value) {
      // Get all device types which are activated, warning or suspended for the feature
      const activatedFeaturesInPackage: LicenseStatusFeatureByDeviceTypeI[] = licenseStatus.value.featureByDeviceTypes.filter((feature) => {
        return feature.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED
        && feature.name !== FeaturesName.DATA_SHARING
        && feature.name === featureName
      })

      // Only check the warning criteria limit if there is at least on device type activated, warning or suspended for the feature
      // Otherwise, the feature is considered to be not activated so no need to check criteria limit
      if (activatedFeaturesInPackage.length > 0) {
        // Count the device types which are suspended
        const countDeviceTypeBeyondTheLimit = activatedFeaturesInPackage.filter((feature) => {
          return feature.status === LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE && feature.name === featureName
        }).length

        // Count the device types which are warning
        const countDeviceTypeBetweenMarginAndLimit = activatedFeaturesInPackage.filter((feature) => {
          return feature.status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE && feature.name === featureName
        }).length

        // Checks if all device type criteria are suspended (beyond the limit)
        if (countDeviceTypeBeyondTheLimit === activatedFeaturesInPackage.length) {
          featureBeyondLimitWarnings.value.push({
            message: t('license.warningMessages.beyondLimitOfAllDeviceCriteria', {
              featureName: t(`license.content.feature.${featureName}`),
            }),
            alertType: AlertTypeEnum.ERROR,
            featureName,
          })
          if (featureName === FeaturesName.ADVANCED_FLEET_MGT) {
            isBeyondLimitOfAllDeviceType.value = true
          }

          // Check if at least one device type criteria is suspended (beyond the limit)
        } else if (countDeviceTypeBeyondTheLimit > 0) {
          featureBeyondLimitWarnings.value.push({
            message: t('license.warningMessages.atLeastOneDeviceBeyondLimit'),
            alertType: AlertTypeEnum.ERROR,
            featureName,
          })

          // Check if at least one device type criteria is between margin and limit but not above the tolerancy
        } else if (countDeviceTypeBetweenMarginAndLimit > 0) {
          featureBeyondLimitWarnings.value.push({
            message: t('license.warningMessages.betweenMarginAndLimitOfAtLeastOneDevice', {
              count: countDeviceTypeBetweenMarginAndLimit,
            }),
            alertType: AlertTypeEnum.WARNING,
            featureName,
          })
        }
      }
    }
  }

  /**
   * Check and build all the warning about the license
   * The license status must be fetched before calling this function, otherwise no check will be done
   */
  async function checkWarnings() {
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())

    checkDateWarningMessages()

    // the license is not expired => check the features warning
    if (licenseStatus.value?.statusOfLicense !== LicenseStatus.EXPIRED_DATE) {
      const licenseFeaturesName = getlicenseFeaturesName()
      featureBeyondLimitWarnings.value = []
      isBeyondLimitOfAllDeviceType.value = false
      licenseFeaturesName.value.forEach(feature => checkCriteriaLimitWarningMessages(feature))

      if (licenseStatus.value?.datasharingFeature) {
        checkDatasharingThresholdReached(licenseStatus.value.datasharingFeature)
      }
    }
  }

  /**
   * Return true if the feature is present, false otherwise, whatever the license and features status
   * @param featureName
   * @returns
   */
  async function isLicenseFeaturePresent(featureName: FeaturesName) {
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())
    return licenseStatus.value?.featureByDeviceTypes.find(featureByDeviceType => featureByDeviceType.name === featureName) !== undefined
  }

  /**
   * Return true if the feature is active (different from NOT_ACTIVATED and SUSPENDED_DEVICE) for at least one device type, false otherwise
   * @param featureName
   * @returns
   */
  async function isLicenseFeatureActive(featureName: FeaturesName) {
    const { licenseStatus } = storeToRefs(useLicenseStatusStore())
    return licenseStatus.value?.featureByDeviceTypes.find(
      (featureByDeviceType) => {
        return featureByDeviceType.name === featureName
          && featureByDeviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED
          && featureByDeviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE
      },
    ) !== undefined
  }

  return {
    featureBeyondLimitWarnings,
    isBeyondExpirationDate,
    isBeyondLimitOfAllDeviceType,
    licenseExpirationDateWarning,
    getLicenseStatus,
    checkWarnings,
  }
}
