<script setup lang="ts">
import type { DtwinI } from '@lxc/app-device-types'
import { v4 as uuidv4 } from 'uuid'
import type { Chart, ChartJsPluginConfig, Limits } from '~/components/dtwins/dtwinsForm/LxcDtwinsDataviz.model'
import { DEFAULT_CHARTJS_ZOOM_SUB_CONFIG } from '~/components/shared/LxcChartLocal.model'
import LxcErrorComponent from '~/components/shared/LxcError.vue'
import dtwinsService from '~/services/dtwins.service'
import type { LxcDragnDropOption } from '~/types/dragndrop'
import { useUserSession } from '~/stores/useUserSession'
import type { Serie } from '~/types/chart'
import { Granularity, MAX_SERIES_BY_CHART } from '~/types/chart'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError } from '~/utils/notifications-tools'

const props = defineProps<{
  dtwin?: DtwinI | null
  offsetTop: number
  scrollTop: number
  tabsContentHeight: number
}>()

const { t } = useI18n()

const { userSession } = useUserSession()
const mirrorContainerRef: Ref<HTMLElement | undefined> = ref()
const initDropOption: LxcDragnDropOption = {
  copy: true,
  invalid: (el?: Element, handle?: Element) => {
    return (el?.classList.contains('lxc-chart') || el?.closest('.lxc-chart') !== null || el?.classList.contains('lxc-chart-drop-area')) ?? false
  },
  mirrorContainer: mirrorContainerRef.value,
  revertOnSpill: false,
  transform: true,
}

const series: Ref<Array<Serie>> = ref([])
const seriesListRef: Ref<HTMLElement | undefined> = ref()
const isLoading: Ref<boolean> = ref(false)
const error: Ref<LxcError|undefined> = ref()
/** Use directly CSS styles because tailwind CSS arbitrary values do not work with this value */
const serieStyleHeight: ComputedRef<string> = computed(() => {
  let offset = 0

  if (props.scrollTop > 0) {
    offset = props.scrollTop <= props.offsetTop ? props.scrollTop : props.offsetTop
  }

  const maxHeight = (props.tabsContentHeight + offset) / 16
  return `max-height: ${maxHeight}rem;`
})

const timescales = [
  { value: Granularity.DAY, label: t('dtwins.form.dataviz.day') },
  { value: Granularity.WEEK, label: t('dtwins.form.dataviz.week') },
  { value: Granularity.MONTH, label: t('dtwins.form.dataviz.month') },
  { value: Granularity.QUARTER, label: t('dtwins.form.dataviz.quarter') },
]
const selectTimescale: Ref<string> = ref(Granularity.DAY)
const dayInMilliseconds = 24 * 60 * 60 * 1000
const weekInMilliseconds = dayInMilliseconds * 7
const monthInMilliseconds = dayInMilliseconds * 31
const quarterInMilliseconds = monthInMilliseconds * 3
const chartLimits: ComputedRef<Limits> = computed(() => {
  const now = (new Date()).valueOf()
  let min: number
  switch (selectTimescale.value) {
    case Granularity.WEEK:
      min = now - weekInMilliseconds
      break
    case Granularity.MONTH:
      min = now - monthInMilliseconds
      break
    case Granularity.QUARTER:
      min = now - quarterInMilliseconds
      break
    case Granularity.DAY:
    default:
      min = now - dayInMilliseconds
      break
  }
  return {
    x: {
      min,
      max: now,
    },
  }
})
const pluginProperties: ComputedRef<ChartJsPluginConfig> = computed(() => {
  return {
    legend: {
      display: false,
    },
    zoom: {
      limits: chartLimits.value,
      zoom: DEFAULT_CHARTJS_ZOOM_SUB_CONFIG,
    },
  }
})

/**
 * Fetch the list of series
 */
async function fetchSeries() {
  if (!props.dtwin?.uid) {
    return
  }

  isLoading.value = true

  const response = await dtwinsService.getDtwinTelemetries(props.dtwin.uid)

  if (LxcError.check(response)) {
    error.value = response
  } else {
    series.value = []

    if (response.information_List) {
      for (const telemetry of response.information_List) {
        if (telemetry.gts) {
          series.value.push({
            name: telemetry.gts,
            label: telemetry.label,
            unit: telemetry.unit,
            unitSymbol: telemetry.unitSymbol,
            precision: telemetry.precision,
            granularity: telemetry.granularity,
            isCalculated: telemetry.warpScriptProvided,
            values: [],
            isVisible: true,
          })
        }
      }
    }

    error.value = undefined
  }

  isLoading.value = false
}

const charts: Ref<Array<Chart>> = ref([])

/**
 * Create an empty chart and return it
 */
const createChart = function(): Chart {
  const chart: Chart = {
    uuid: uuidv4(),
    series: [],
  }

  charts.value.push(chart)

  return chart
}

/**
 * Update charts values
 */
const updateChartsValues = async(filterChart?: Chart, filterSerie?: Serie) => {
  for (const chart of charts.value) {
    if (!filterChart || chart.uuid === filterChart.uuid) {
      for (const serie of chart.series) {
        if (!filterSerie || serie.name === filterSerie.name) {
          await setSerieValues(serie)
        }
      }
    }
  }
}
// After each plugin configuration update, refresh the chart data
watch(() => pluginProperties.value, async() => {
  await updateChartsValues()
})

const moveId: Ref<string> = ref('seriesMove')
const cloneDropOption: () => LxcDragnDropOption = () => {
  return {
    ...initDropOption,
  }
}

/**
 * Build serie dataset and values
 */
const buildSerie = function(serie: Serie, response: any) {
  if (response.results.length > 0) {
    for (const label of response.results[0].labels) {
      // case when the values are boolean => set stepped serie
      if (label.key === 'format' && label.value === 'BOOLEAN') {
        if (!serie.datasetProperties) {
          serie.datasetProperties = {}
        }
        serie.datasetProperties.stepped = 'after'
        if (!serie.scaleProperties) {
          serie.scaleProperties = {
            min: 0,
            max: 1,
            ticks: {
              stepSize: 1,
            },
            title: {
              display: false,
            },
          }
        }
      }
    }

    serie.values = response.results[0].values.map((value: any) => {
      return {
        timestamp: value.timestamp / 1000,
        value: value.value,
      }
    })
  }
}

/**
 * Fetch and set a serie values
 */
async function setSerieValues(serie: Serie) {
  if (!props.dtwin?.uid) {
    return
  }

  const response = await dtwinsService.getDtwinTelemetryValues(
    props.dtwin.uid,
    serie.name,
    (chartLimits.value.x?.min ?? 0) * 1000,
    (chartLimits.value.x?.max ?? 0) * 1000,
    serie.isCalculated ?? false,
  )

  if (LxcError.check(response)) {
    showNotificationError(t(NotificationKey.error))
  } else {
    buildSerie(serie, response)
  }
}

/**
 * Add a serie to a chart if it does not already exist and if limit of MAX_SERIES_BY_CHART is not exceeded
 * Update the serie values after being added
 * @param serie
 * @param chart
 */
const addSerieToChart = async(serie: Serie, chart: Chart) => {
  if (chart.series.length < MAX_SERIES_BY_CHART && !chart.series.find(s => s.name === serie.name)) {
    const copySerie = { ...serie }
    chart.series.push(copySerie)
    await updateChartsValues(chart, copySerie)
  }
}

/**
 * Drop a serie to a chart
 */
const onDropSerie = async(element: HTMLElement, chartUuid?: string) => {
  const serie = series.value.find(serie => serie.name === element.dataset.serieName)

  if (serie) {
    const chart: Chart = chartUuid
      ? charts.value.find(chart => chart.uuid === chartUuid) || createChart()
      : createChart()

    await addSerieToChart(serie, chart)
  }
}

/**
 * Use a callback to remove the other parameters (currently hidden) that are sent by the callback `v-lxc-drag-n-drop:drop`
 * @param element the dropped Element
 */
const onDropOnCard = (element: HTMLElement) => {
  onDropSerie(element)
}

const onSerieDoubleClick = async(serie: Serie) => {
  // Get the first chart without this serie or create it if there is no more
  const chart: Chart = charts.value.find(
    (chart: Chart) => chart.series.length < MAX_SERIES_BY_CHART
      && chart.series.filter((s: Serie) => serie.name === s.name).length === 0,
  ) || createChart()

  await addSerieToChart(serie, chart)
}

onMounted(fetchSeries)
</script>

<template>
  <div
    v-if="isLoading"
    class="flex flex-1 items-center justify-center"
  >
    <lxc-loader :size="28" />
  </div>
  <lxc-error-component
    v-else-if="error"
    :error="error"
  />
  <div
    v-else-if="series.length === 0"
    class="text-center"
  >
    {{ $t('dtwins.form.dataviz.noData') }}
  </div>
  <div
    v-else
    ref="mirrorContainerRef"
    class="flex h-full items-start"
  >
    <!-- serie list -->
    <div
      ref="seriesListRef"
      class="w-1/5 grow-0 shrink-1 overflow-y-auto min-w-64 sticky top-0 border border-solid border-gray-200"
      :style="serieStyleHeight"
    >
      <div
        v-lxc-drag-n-drop:drag
        v-lxc-drag-move="moveId"
        class="grid gap-2 py-4 px-3"
      >
        <div
          v-for="(serie, i) of series"
          :key="i"
          class="bg-white border border-gray-300 rounded-lg p-2 cursor-grab overflow-x-hidden dtwin-data-viz-serie-label"
          draggable="true"
          :data-serie-name="serie.name"
          @dblclick="onSerieDoubleClick(serie)"
        >
          <div
            class="flex items-center overflow-x-hidden justify-between select-none h-8"
          >
            <div
              class="truncate flex-auto"
              :title="`${serie.label} ${serie.unit ?? ''}`"
            >
              {{ serie.label }}
            </div>
            <div>
              <span
                v-if="serie.unit"
                class="font-semibold truncate flex-none w-14"
              >
                {{ serie.unit }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right panel -->
    <div class="flex-1 px-6 py-4 grow shrink basis-0 border-solid border-gray-200 border-r border-t border-b">
      <div>
        <!-- header-->
        <div class="flex justify-end">
          <div class="inline-flex">
            <div>
              <lxc-select
                v-model="selectTimescale"
                :attributes="{ 'data-cy': 'select-input' }"
              >
                <lxc-option
                  v-for="timescale in timescales"
                  :key="timescale.label"
                  :label="timescale.label"
                  :value="timescale.value"
                  :attributes="{ 'data-cy': 'select-input-option-' + timescale.value }"
                />
              </lxc-select>
            </div>
          </div>
        </div>

        <!-- charts, only display charts with at least one serie -->
        <div>
          <div
            v-for="(notEmptyChart, i) in charts.filter(chart => chart.series.length > 0)"
            :key="i"
            v-lxc-drag-n-drop:drop="(source: HTMLElement) => {
              onDropSerie(source, notEmptyChart.uuid)
            }"
            v-lxc-drag-move="moveId"
            v-lxc-drop-option="cloneDropOption()"
            class="mt-4"
          >
            <lxc-chart-local
              v-model:series="notEmptyChart.series"
              :plugin-properties="pluginProperties"
              :locale="userSession?.language"
            />
          </div>
          <lxc-card
            v-lxc-drag-n-drop:drop="onDropOnCard"
            v-lxc-drag-move="moveId"
            v-lxc-drop-option="cloneDropOption()"
            class="mt-4 text-center justify-center select-none"
            body-class="lxc-chart-drop-area"
          >
            {{ $t('dtwins.form.dataviz.newChart') }}
          </lxc-card>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gu-mirror {
  &.dtwin-data-viz-serie-label {
    cursor: grabbing;

    &.gu-drag-over {
      cursor: copy;
    }
  }
}
</style>
