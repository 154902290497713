<script setup lang="ts">
import type { ConnectivityStatusParameters, DtwinModelI, PaginatedListI } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import { useDtwin, useDtwins } from '~/composables/useDtwins'
import { SearchMode } from '~/composables/useSearch'
import { useSector } from '~/composables/useSector'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import DtwinsService from '~/services/dtwins.service'
import { useSectorStore } from '~/stores/useSectorStore'
import { Filters } from '~/types'
import LxcError from '~/utils/LxcError'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  reload: boolean
}>()

const emit = defineEmits(['update:reload'])

const {
  isLoading,
  results,
  error,
  setFilter,
  fetchData,
  onSearch,
  search,
  filters,
} = useDtwins(SearchMode.URL_SEARCH)

const {
  getDtwinTypeLabel,
} = useDtwin()

const { selectedSectors, availableSectors } = storeToRefs(useSectorStore())
const { getSectorLabel } = useSector()

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => loadData())

watch(() => props.reload, (reload) => {
  if (reload) {
    fetchData()
    emit('update:reload', false)
  }
})

const searchQuery = ref<string>((filters.get(Filters.DTWIN_NAME_OR_SERIAL_NUMBER) ?? '') as string)

const isLoadingModels: Ref<boolean> = ref(false)
const errorModels: Ref<LxcError | undefined> = ref()
const models: Ref<Array<DtwinModelI> | undefined> = ref()

const fetchModels = async() => {
  isLoadingModels.value = true

  const response: PaginatedListI<DtwinModelI> = await DtwinsService.getModels(1, 999999999)
  if (LxcError.check(response)) {
    errorModels.value = response
  } else {
    models.value = response.results
  }

  isLoadingModels.value = false
}

/**
 * Retrieve devices by sectors
 * @param page
 * @param pageSize
 */
async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  setFilter(
    Filters.DTWIN_IN_SECTORS,
    (selectedSectors.value.length > 0 ? selectedSectors : availableSectors).value.map(sector => sector.code), // Filter by the available sectors if no sectors are selected
  )

  await fetchData(page, pageSize)
}

function getConnectivityStatusParameters(deviceModelUid: string): ConnectivityStatusParameters | undefined {
  return models.value?.find((model: DtwinModelI) => model.uid === deviceModelUid)?.connectivityStatusParameters
}

const selectedDeviceModelFilter = computed(() => {
  return filters.get(Filters.DTWIN_MODEL_TYPE)
})

const getQuickFilterButtonType = (selectedModelTypeUid: string) => {
  return selectedDeviceModelFilter?.value?.value.includes(selectedModelTypeUid) ? 'primary' : 'secondary'
}

async function getDevicesByModel(modelUid: string) {
  selectedDeviceModelFilter?.value?.value.includes(modelUid)
    ? setFilter(
      Filters.DTWIN_MODEL_TYPE,
      [],
    )
    : setFilter(
      Filters.DTWIN_MODEL_TYPE,
      [modelUid],
    )
  search()
}

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue
  setFilter(Filters.DTWIN_NAME_OR_SERIAL_NUMBER, searchQuery.value)
}

const onClearSearch = () => {
  setFilter(Filters.DTWIN_NAME_OR_SERIAL_NUMBER, '')
  search()
}

onMounted(async() => {
  await fetchModels()
  onSearch(loadData)
})
</script>

<template>
  <lxc-container
    :error="errorModels"
    :is-loading="isLoadingModels"
    :px="0"
    :py="0"
  >
    <div class="mb-6 mt-2 pt-0.5">
      <lxc-search-bar
        :search-query="searchQuery"
        :search-placeholder="$t('dtwins.filters.searchByNameOrSerialNumber')"
        @clear="onClearSearch"
        @input-change="onSearchInputChanged"
        @search="search"
      />
    </div>
    <div
      class="flex gap-2 mb-6"
    >
      <lxc-button
        v-for="model in models"
        :key="model.uid"
        :title="model.friendlyName"
        :type="getQuickFilterButtonType(model.uid)"
        @click="() => getDevicesByModel(model.uid)"
      >
        {{ model.friendlyName }}
      </lxc-button>
    </div>
    <lxc-table
      :context="results?.context"
      :data="results?.data"
      :is-loading="isLoading"
      :error="error?.toError()"
      :empty-text="$t('dtwins.empty')"
      clickable
      @change-page-and-page-size="fetchData"
      @row-click="$router.push(`${PATHS.DTWINS}/${$event.uid}`)"
    >
      <lxc-table-column
        prop="attributes.friendlyName"
        :label="$t('dtwins.list.attributes.friendlyName')"
      />
      <lxc-table-column
        prop="attributes.type"
        :label="$t('dtwins.list.attributes.type')"
      >
        <template
          v-if="models"
          #default="scope"
        >
          {{ getDtwinTypeLabel(models, scope.row) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="attributes.serialNumber"
        :label="$t('dtwins.list.attributes.serialNumber')"
      />
      <lxc-table-column
        prop="attributes.sectorId"
        :label="$t('dtwins.list.attributes.sector')"
      >
        <template #default="scope">
          {{ getSectorLabel(scope.row.attributes.sectorId) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('dtwins.list.attributes.connectivity.name')"
        class="!py-0"
      >
        <template
          #default="scope"
        >
          <LxcDtwinsListConnectivityStatus
            v-if="scope.row.connectivityStatus"
            :connectivity-status="scope.row.connectivityStatus"
            :connectivity-status-parameters="getConnectivityStatusParameters(scope.row.deviceModelUid)"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('dtwins.list.attributes.metastatus.name')"
        class="!py-0"
      >
        <template
          #default="scope"
        >
          <LxcDtwinsListMetadataStatus
            v-if="scope.row.operationStatuses"
            :operation-statuses="scope.row.operationStatuses"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('dtwins.list.attributes.state')"
      >
        <template #default="scope">
          {{ $t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('dtwins.list.attributes.createdAt')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.createdAt, $t('dateFormat.datetime')) }}
        </template>
      </lxc-table-column>
    </lxc-table>
  </lxc-container>
</template>
