import { useAcl } from 'vue-simple-acl'
import { type FirmwareI } from '@lxc/app-device-types'
import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import type { FilterSelectionValue, FiltersSelection } from '~/types'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useFetchPage } from '~/composables/useFetchPage'
import { useSort } from '~/composables/useSort'
import FirmwareService from '~/services/firmware.service'
import LxcError from '~/utils/LxcError'

export function useFirmware(searchMode?: SearchMode) {
  const service = FirmwareService.getFirmwares.bind(FirmwareService)

  const appliedFilters: FiltersSelection = new Map([
    [Filters.FIRMWARE_NAME_OR_VERSION, { key: ['name', 'version'], operator: '=', value: '', valueFormatter: (value: FilterSelectionValue) => `*${value}*` }],
    [Filters.STATUS, { key: 'status', operator: '=', value: [] }],
    [Filters.RANGE, { key: 'lxcModelType', operator: '=', value: [] }],
    [Filters.MODEL_DECLINATION, { key: 'lxcModelDeclination', operator: '=', value: [] }],
    [Filters.FIRMWARE_VERSIONS, { key: 'compatibleSwVersion', operator: '=', value: [] }],
    [Filters.HARDWARE_VERSIONS, { key: 'compatibleHwVersion', operator: '=', value: [] }],
    [Filters.DATE_ADDED_AFTER, { key: 'creationDate', operator: '>=', value: '' }],
    [Filters.DATE_ADDED_BEFORE, { key: 'creationDate', operator: '<=', value: '' }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.PIPE_SEPARATOR, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
  }
}

export function useDeleteFirmware() {
  const acl = useAcl()
  const isDeleteLoading = ref<boolean>(false)
  const deleteError = ref<LxcError | null>(null)
  const [deleteConfirmDialogVisible, toggleDeleteConfirmVisible] = useToggle()
  const isEmbedded = ref<boolean>(false)

  async function checkFirmwareStateBeforeDelection(event: Event, uuid: string) {
    event.preventDefault()
    event.stopPropagation()
    isDeleteLoading.value = true
    deleteError.value = null
    const response = await FirmwareService.getFirmwareStatsByUuid(uuid)

    if (LxcError.check(response)) {
      deleteError.value = response
    } else {
      isEmbedded.value = response.relatedCampaignCount !== 0 || response.relatedOperationCount !== 0
      toggleDeleteConfirmVisible(true)
    }

    isDeleteLoading.value = false
  }
  /**
   * Call delete firmware, display notification and close confirm dialog
   */
  async function deleteFirmware(firmware: FirmwareI): Promise<void> {
    deleteError.value = null
    isDeleteLoading.value = true
    toggleDeleteConfirmVisible()
    const response = await FirmwareService.deleteFirmware(firmware)

    if (LxcError.check(response)) {
      deleteError.value = response
    }

    isDeleteLoading.value = false
  }

  const canDeleteFirmware = computed(() => acl.can(ACL_ROLES.MANAGE_FIRMWARES))

  return {
    canDeleteFirmware,
    checkFirmwareStateBeforeDelection,
    deleteError,
    deleteFirmware,
    deleteConfirmDialogVisible,
    isDeleteLoading,
    isEmbedded,
    toggleDeleteConfirmVisible,
  }
}

export function useActivateFirmware() {
  const acl = useAcl()
  const isActivateLoading = ref<boolean>(false)
  const activateError = ref<LxcError | null>(null)
  const [activateConfirmDialogVisible, toggleActivateConfirmVisible] = useToggle()

  /**
   * Call activate firmware, display notification and close confirm dialog
   */
  async function activateFirmware(firmware: FirmwareI): Promise<void> {
    activateError.value = null

    if (firmware) {
      toggleActivateConfirmVisible()
      isActivateLoading.value = true

      const response = await FirmwareService.activateFirmware(firmware)

      if (LxcError.check(response)) {
        activateError.value = response
      }

      isActivateLoading.value = false
    }
  }

  const canActivateFirmware = computed(() => acl.can(ACL_ROLES.MANAGE_FIRMWARES))

  return {
    activateConfirmDialogVisible,
    activateFirmware,
    activateError,
    canActivateFirmware,
    isActivateLoading,
    toggleActivateConfirmVisible,
  }
}

export function useDeactivateFirmware() {
  const acl = useAcl()
  const isDeactivateLoading = ref<boolean>(false)
  const deactivateError = ref<LxcError | null>(null)
  const [deactivateConfirmDialogVisible, toggleDeactivateConfirmVisible] = useToggle()

  /**
   * Call activate firmware, display notification and close confirm dialog
   */
  async function deactivateFirmware(firmware: FirmwareI): Promise<void> {
    deactivateError.value = null

    if (firmware) {
      toggleDeactivateConfirmVisible()
      isDeactivateLoading.value = true

      const response = await FirmwareService.deactivateFirmware(firmware)

      if (LxcError.check(response)) {
        deactivateError.value = response
      }

      isDeactivateLoading.value = false
    }
  }

  const canDeactivateFirmware = computed(() => acl.can(ACL_ROLES.MANAGE_FIRMWARES))

  return {
    deactivateConfirmDialogVisible,
    deactivateFirmware,
    deactivateError,
    canDeactivateFirmware,
    isDeactivateLoading,
    toggleDeactivateConfirmVisible,
  }
}
