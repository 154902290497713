<script lang='ts' setup>
import type LxcError from '~/utils/LxcError'

const { t, te, locale } = useI18n()
const props = defineProps<{
  error?: LxcError
}>()

interface ErrorTranslationI {
  title: string
  subtitle: string
  type: string
}

class ErrorTranslation implements ErrorTranslationI {
  title: string
  subtitle: string
  type: string

  constructor(error?: LxcError) {
    if (te(`errors.${error?.code}.title`) && te(`errors.${error?.code}.subtitle`) && te(`errors.${error?.code}.type`)) {
      this.title = t(`errors.${props.error?.code}.title`)
      this.subtitle = t(`errors.${props.error?.code}.subtitle`)
      this.type = t(`errors.${props.error?.code}.type`)
    }
    else {
      console.error(`keys [${error?.code}.title, ${error?.code}.subtitle, ${error?.code}.type] doesn't exist in 'errors.json' files - language [${locale.value}]`)
      this.title = t('errors.UNEXPECTED.title')
      this.subtitle = t('errors.UNEXPECTED.subtitle')
      this.type = t('errors.UNEXPECTED.type')
    }
  }
}

const errorTranslation = computed(() => new ErrorTranslation(props.error))

</script>

<template>
  <el-result
    :icon="errorTranslation.type"
    :title="errorTranslation.title"
    :sub-title="errorTranslation.subtitle"
  >
    <slot />
  </el-result>
</template>
