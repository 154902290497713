<script lang="ts" setup>
import { useSerialize } from '@lxc/app-device-common'
import type { TruststoreCertificateRequestI, TruststoreUploadedCertificateI } from '@lxc/app-device-types'
import { CACertificateItemType, ErrorCode } from '@lxc/app-device-types'
import type { Rules } from 'async-validator'
import type { WritableComputedRef } from 'vue'
import { VALIDATION_REGEXP } from '~/constants/constants'
import truststoreService from '~/services/truststore.service'
import type { TruststoreCertificateUploadForm } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import { getValuesFromUniqueLabels } from '~/utils/unique-label-tools'
import uploadUtils from '~/utils/upload.utils'

const props = defineProps<{
  disabled?: boolean
  isTagsLoading: boolean
  sideCanvasShown: boolean
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const serialize = useSerialize()
const emit = defineEmits([
  'save',
  'update:sideCanvasShown',
])
const { locale, t } = useI18n()

const defaultCertificateForm: TruststoreCertificateUploadForm = {
  alias: '',
  certificate: '',
  tags: [],
  trustChain: [],
  type: CACertificateItemType.TRUSTCHAIN,
}

// File must be less than 750MB
const MAX_FILE_SIZE_BYTES_ALLOWED = 750_000_000
const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown
  },
  set(sideCanvasShown: boolean) {
    emit('update:sideCanvasShown', sideCanvasShown)
  },
})
// deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
const certificateForm: Ref<TruststoreCertificateUploadForm> = ref<TruststoreCertificateUploadForm>({
  ...defaultCertificateForm,
  tags: [...defaultCertificateForm.tags],
  trustChain: [...defaultCertificateForm.trustChain],
})
const certificateFormRef: Ref = ref()
const closeLabel = t('button.close')
const validateLabel = t('button.validate')
const importLabel = t('button.import')
const files: Ref<FileList | undefined | null> = ref()
const fileProgress: Ref<number | undefined | null> = ref()
const filesUploadError: Ref<Error | undefined | null> = ref()
const isFileError: ComputedRef<boolean> = computed(() => (filesUploadError.value instanceof Error))
const initCertificateFormStringified: Ref<string> = ref(serialize(defaultCertificateForm))
const filesLoading: ComputedRef<boolean> = computed(() => !!fileProgress.value && (fileProgress.value < 100))
const formats: Array<string> = ['.pem', '.crt', '.cer']
const accept = formats.join(',')
const formatLabel = formats.map(extension => extension.toUpperCase()).join(', ')

const fileSelected: ComputedRef<boolean> = computed(() => !!files?.value?.length)
const isCertificateUploaded: ComputedRef<boolean> = computed(() => {
  return !!certificateForm.value.certificate && fileSelected.value
})
const isSaving = ref<boolean>(false)
const edited: ComputedRef<boolean> = computed(() => {
  const stringifiedForm = serialize(certificateForm.value)
  return stringifiedForm !== initCertificateFormStringified.value || (files.value != null && files.value.length !== 0)
})
const rules: ComputedRef<Rules> = computed(() => {
  const rulesConfig: Rules = {
    alias: [
      {
        required: true,
        message: t('certificates.validation.alias.required'),
        type: 'string',
        whitespace: false,
      },
      {
        type: 'string',
        message: t('certificates.validation.alias.invalid'),
        pattern: VALIDATION_REGEXP.CERTIFICATE_ALIAS,
      },
    ],
  }

  return rulesConfig
})

function cloneCertificateForm(targetCertificateForm: Ref<TruststoreCertificateUploadForm>) {
  Object.assign(targetCertificateForm.value, defaultCertificateForm,
    {
      tags: [...defaultCertificateForm.tags],
      trustChain: [...defaultCertificateForm.trustChain],
    })
}

function resetCertificateForm() {
  files.value = files.value !== undefined ? null : undefined
  clearCertificateUploadStatus()
  cloneCertificateForm(certificateForm)
  initCertificateFormStringified.value = serialize(certificateForm.value)
  // set timeout to be sure that the clearValidate is processed after updating the form inputs
  setTimeout(certificateFormRef.value?.clearValidate, 0)
}

function clearCertificateUploadStatus() {
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined
  fileProgress.value = fileProgress.value !== undefined ? null : undefined
}

function canUploadFile(file: File): boolean {
  let error: string | undefined
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined

  if (!uploadUtils.isCertificateFile(file)) {
    error = 'extension'
  } else if (!file.size) {
    error = 'fileEmpty'
  } else if (file.size > MAX_FILE_SIZE_BYTES_ALLOWED) {
    error = 'fileSize'
  }

  if (error) {
    filesUploadError.value = new Error(t(`certificates.validation.certificate.${error}`))
  }

  return !error
}

/**
 * Upload the selected referenced file and display add certificate form
 */
const uploadCertificate = async() => {
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined

  if (files?.value?.length && files?.value?.length !== 0 && canUploadFile(files?.value[0])) {
    const response: TruststoreUploadedCertificateI = await truststoreService.uploadCertificate(files.value[0], fileProgress)

    if (LxcError.check(response)) {
      const error = response.toError(NotificationKey.uploadError)

      if (response.code === ErrorCode.BAD_PARAMETER) {
        error.message = t('certificates.validation.certificate.invalid')
        filesUploadError.value = error
      } else {
        filesUploadError.value = error
      }
    } else {
      // only update the certificate form data the list if at least one result succeed
      certificateForm.value.certificate = response.certificate
      certificateForm.value.trustChain = response.trustChain
    }
  }
}

async function validate(): Promise<boolean> {
  return await certificateFormRef.value?.validate().catch((_: any) => false)
}

function close() {
  formSideCanvasShown.value = false
}

async function saveForm(): Promise<void> {
  isSaving.value = true
  const certificateRequest: TruststoreCertificateRequestI = {
    alias: certificateForm.value.alias.trim(),
    certificate: certificateForm.value.certificate,
    tags: getValuesFromUniqueLabels(certificateForm.value.tags),
    type: certificateForm.value.type,
  }

  const response = await truststoreService.addCertificate(certificateRequest)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    close()
    resetCertificateForm()
    emit('save')
  }

  isSaving.value = false
}

const onSubmitSave = async(): Promise<boolean> => {
  const dataValid = await validate()

  if (dataValid) {
    saveForm()
  }

  return dataValid
}

const onCancel = () => {
  close()
  resetCertificateForm()
}

watch(() => files.value, clearCertificateUploadStatus)
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    :header="$t('certificates.truststore.uploadCertificate')"
    :close-tooltip="$t('button.close')"
    :confirm-enabled="edited"
    :confirm-title="$t('certificates.truststore.uploadCertificate')"
    :confirm-message="$t('certificates.truststore.cancelAction.confirm.message')"
    :confirm-ok-label="$t('button.confirm')"
    :confirm-cancel-label="$t('button.cancel')"
    @discard="onCancel"
  >
    <div v-if="!isCertificateUploaded">
      <lxc-input
        v-model="files"
        type="file"
        :accept="accept"
        :delete-tooltip="$t('input.deleteFile')"
        :disabled="disabled"
        :error="filesUploadError"
        :locale="locale"
        :multiple="false"
        :percentage="fileProgress"
        :size-unit="uploadUtils.getUnitTranslations()"
        @blur="clearCertificateUploadStatus"
      >
        <template #placeholder>
          <div class="mx-8">
            <span class="text-primary-700 font-bold">{{ $t('input.clickToUpload') }}</span> <span>{{ $t('input.orDragAndDrop', { format: formatLabel }) }}</span>
          </div>
        </template>
      </lxc-input>
    </div>

    <div v-if="isCertificateUploaded">
      <lxc-form
        ref="certificateFormRef"
        :model="certificateForm"
        :rules="rules"
        @submit.prevent="validate"
      >
        <lxc-truststore-generic-form
          v-model="certificateForm"
          :disabled="disabled"
          :edition="false"
          :is-tags-loading="isTagsLoading"
          :tags="tags"
          :tags-error="tagsError"
        />
      </lxc-form>
    </div>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="closeLabel"
          @click="close"
        >
          {{ closeLabel }}
        </lxc-button>
        <lxc-button
          v-if="!isCertificateUploaded"
          html-type="button"
          :disabled="filesLoading || !fileSelected || isFileError"
          :title="importLabel"
          @click="uploadCertificate"
        >
          {{ importLabel }}
        </lxc-button>
        <lxc-button
          v-else
          html-type="submit"
          :disabled="!edited || isFileError || isSaving"
          :title="validateLabel"
          @click="onSubmitSave"
        >
          {{ validateLabel }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
