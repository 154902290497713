<script lang="ts" setup>import type { KeystoreCertificateI } from '@lxc/app-device-types'
import { useCertificatesACLRoles } from '~/composables/useCertificates'
import ILxcLightTrash2 from '~icons/lxc-light/trash-2'
import truststoreService from '~/services/truststore.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
const { canManageCertificates } = useCertificatesACLRoles()
const { t } = useI18n()

const [deleteConfirmDialogVisible, toggleDeleteConfirmVisible] = useToggle()
const props = defineProps<{
  certificate: KeystoreCertificateI
}>()

const isDeleteLoading = ref<boolean>(false)
const deleteDisabled: ComputedRef<boolean> = computed(() => !canManageCertificates() || isDeleteLoading.value)
const error = ref<LxcError | null>(null)
const emit = defineEmits(['change'])

const onDeletCertificateAndPropagateChange = async() => {
  error.value = null
  isDeleteLoading.value = true
  toggleDeleteConfirmVisible()
  const response = await truststoreService.deleteCertificate(props.certificate.alias)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    emit('change')
  }

  isDeleteLoading.value = false
}

const onDeleteCertificateClick = () => {
  toggleDeleteConfirmVisible(true)
}
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="deleteDisabled"
    :icon="(!isDeleteLoading) ? ILxcLightTrash2 : undefined"
    :title="t('certificates.delete')"
    class="invisible"
    @click.stop.prevent="onDeleteCertificateClick"
  >
    <lxc-loader
      v-if="isDeleteLoading"
    />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="deleteConfirmDialogVisible"
    :title="$t('certificates.delete')"
    :description="$t('certificates.list.action.delete.description', { name: certificate.alias })"
    color-theme="danger"
    @cancel="toggleDeleteConfirmVisible"
    @confirm="onDeletCertificateAndPropagateChange"
  />
</template>
<style lang="scss" scoped>
  button {
    :deep(svg) {
      height: 20px;
      width: 20px;
    }
  }
</style>
