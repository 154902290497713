import { NO_CONTENT } from '~/components/dtwins/dtwinsForm/LxcDtwinsFormType'

export class LxcDtwinsFirmwareInformationType {
  readonly firmwareVersion: string
  readonly firmwareUpdateSource: string
  readonly latestFirmwareUpdate: string

  constructor({
    firmwareVersion,
    firmwareUpdateSource,
    latestFirmwareUpdate,
  }: {
    firmwareVersion?: string
    firmwareUpdateSource?: string
    latestFirmwareUpdate?: string
  }) {
    this.firmwareVersion = firmwareVersion ?? NO_CONTENT
    this.firmwareUpdateSource = firmwareUpdateSource ?? NO_CONTENT
    this.latestFirmwareUpdate = latestFirmwareUpdate ?? NO_CONTENT
  }
}

export enum FirmwareUpdateSourceOption {
  FACTORY = 1,
  APP = 2,
  MOBILE_APP = 3,
}
