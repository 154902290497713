<script setup lang="ts">
import { useSerialize } from '@lxc/app-device-common'
import type {
  CreateInterfaceRequestPayloadI,
  CreateInterfaceResponseI,
  SectorI,
  UpdateInterfaceRequestPayloadI,
} from '@lxc/app-device-types'
import { InterfaceAuthenticationType } from '@lxc/app-device-types'
import { StatusCodes } from 'http-status-codes'
import { storeToRefs } from 'pinia'
import { useAppInterface } from '~/composables/useAppInterface'
import { useLicenseStatus } from '~/composables/useLicenseStatus'
import { useProfiles } from '~/composables/useProfiles'
import { PATHS } from '~/constants/paths'
import applicationMgtService from '~/services/applicationMgt.service'
import { useSectorStore } from '~/stores/useSectorStore'
import { useUserSession } from '~/stores/useUserSession'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcPlus from '~icons/lxc/plus'

const props = defineProps<{
  sideCanvasShown: boolean
  selectedInterfaceClientId?: string
}>()

const emit = defineEmits([
  'update:sideCanvasShown',
  'interfaceCreated',
  'interfaceUpdated',
  'clearInterfaceClientId',
])

const { t } = useI18n()
const { userSession } = useUserSession()
const {
  profiles,
  fetchUserProfiles,
} = useProfiles()
const { allSectors } = storeToRefs(useSectorStore())
const { retrieveAllSectors } = useSectorStore()
const router = useRouter()
const { canViewThirdPartyApps, canManageThirdPartyApps } = useAppInterface()
const serialize = useSerialize()
const { getLicenseStatus } = useLicenseStatus()

const sectorOptions: Ref<SectorI[]> = ref([])
const showModalSuccess: Ref<boolean> = ref(false)
const newInterfaceResponse: Ref<CreateInterfaceResponseI|undefined> = ref()
const error: Ref<LxcError|undefined|null> = ref()
const isLoading: Ref<boolean> = ref(false)

const formSideCanvasShown = computed({
  get: () => {
    return props.sideCanvasShown
  },
  set: (shown: boolean) => {
    emit('update:sideCanvasShown', shown)
  },
})

const isInterfaceUpdate: ComputedRef<boolean> = computed(() => {
  return !!props.selectedInterfaceClientId
})
const sidePanelHeader: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value
    ? t('applicationMgt.updateInterface')
    : t('applicationMgt.newInterface')
})
const closeButtonLabel: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value
    ? t('button.cancel')
    : t('button.close')
})
const validateButtonLabel: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value
    ? t('button.validate')
    : t('applicationMgt.generateNewInterface')
})

interface InterfacePayloadI {
  organizationName: string|undefined
  name: string|undefined
  profile: string|undefined
  sectors: Array<string>|undefined
  authenticationType: InterfaceAuthenticationType|undefined
  clientId: string|undefined
}

const interfaceFormRef: Ref<HTMLFormElement | undefined | null> = ref()
const interfaceForm = reactive<InterfacePayloadI>({
  organizationName: userSession?.organisation.name,
  name: '',
  profile: undefined,
  sectors: undefined,
  authenticationType: InterfaceAuthenticationType.CLIENTGRANT,
  clientId: undefined,
})

let initInterfaceFormStringified: string = serialize(interfaceForm)
const edited: ComputedRef<boolean> = computed(() => {
  const stringifiedForm = serialize(interfaceForm)
  return stringifiedForm !== initInterfaceFormStringified
})
const isSaving: Ref<boolean> = ref(false)

async function getInterfaceDetails() {
  if (props.selectedInterfaceClientId) {
    isLoading.value = true
    const response = await applicationMgtService.getAppInterfaceByClientId(props.selectedInterfaceClientId)
    if (LxcError.check(response)) {
      error.value = response
    } else {
      Object.assign(interfaceForm, {
        name: response.name,
        profile: response.profileCode,
        sectors: response.sectorCodes,
        clientId: response.clientId,
      })
    }
    isLoading.value = false
  } else {
    Object.assign(interfaceForm, {
      name: '',
      profile: undefined,
      sectors: undefined,
      clientId: undefined,
    })
  }
  initInterfaceFormStringified = serialize(interfaceForm)
}

const isSelectedSectors = (rule: any, value: any, callback: any) => {
  return value && value.length > 0
    ? callback()
    : callback(new Error(t('applicationMgt.tabs.thirdPartyApp.form.validation.sectors')))
}

const isInterfaceNameValid = (rule: any, value: any, callback: any) => {
  const validator = /^[a-zA-Z0-9_]+$/
  if (!validator.test(value)) {
    return callback(new Error(t('applicationMgt.tabs.thirdPartyApp.form.validation.nameFormat')))
  }
  return callback()
}

const interfaceFormRules = {
  organizationName: [
    { required: true, message: t('applicationMgt.tabs.thirdPartyApp.form.validation.organizationName'), trigger: 'change' },
  ],
  name: [
    { required: true, message: t('applicationMgt.tabs.thirdPartyApp.form.validation.name'), whitespace: true, trigger: 'blur' },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true, trigger: 'blur' },
    { validator: isInterfaceNameValid, trigger: 'blur' },
  ],
  profile: [
    { required: true, message: t('applicationMgt.tabs.thirdPartyApp.form.validation.profile'), trigger: 'change' },
  ],
  sectors: [
    { required: true, validator: isSelectedSectors, trigger: 'update:model-value' },
  ],
  authenticationType: [
    { required: true, message: t('applicationMgt.tabs.thirdPartyApp.form.validation.authenticationType'), trigger: 'change' },
  ],
}

async function isFormValid() {
  return await interfaceFormRef.value?.validate().catch((_: any) => false)
}

async function onSubmit() {
  if (await isFormValid() && userSession && canManageThirdPartyApps()) {
    isSaving.value = true
    if (isInterfaceUpdate && props.selectedInterfaceClientId) {
      const updateInterfacePayload: UpdateInterfaceRequestPayloadI = {
        profileCode: interfaceForm.profile ?? '',
        sectorCodes: interfaceForm.sectors ?? [],
      }

      // Update the interface
      const response = await applicationMgtService.updateAppInterface(props.selectedInterfaceClientId, updateInterfacePayload)
      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError)
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess))
        emit('interfaceUpdated')
        onClose()
      }
    } else {
      const newInterface: CreateInterfaceRequestPayloadI = {
        authenticationType: interfaceForm.authenticationType ?? InterfaceAuthenticationType.CLIENTGRANT,
        organizationCode: userSession.organisation.code,
        organizationName: interfaceForm.organizationName ?? userSession.organisation.name,
        name: interfaceForm.name ?? '',
        profileCode: interfaceForm.profile ?? '',
        sectorCodes: interfaceForm.sectors ?? [],
      }

      // Create a new interface
      const response = await applicationMgtService.createAppInterface(newInterface)
      if (LxcError.check(response)) {
        switch (response.status) {
          case StatusCodes.CONFLICT:
            showNotificationError(
              t(NotificationKey.saveError),
              t('applicationMgt.tabs.thirdPartyApp.form.validation.uniqueName'),
            )
            break
          case StatusCodes.PAYMENT_REQUIRED:
            showNotificationError(
              t(NotificationKey.saveError),
              t('applicationMgt.tabs.thirdPartyApp.licenseMessage.disableNewInterface'),
            )
            break
          default:
            response.notify(NotificationKey.saveError)
            break
        }
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess))
        showModalSuccess.value = true
        newInterfaceResponse.value = response
        emit('interfaceCreated')
        onClose()
      }
    }
    isSaving.value = false
  }
}

function onClose() {
  formSideCanvasShown.value = false
  emit('clearInterfaceClientId')
  setTimeout(interfaceFormRef.value?.clearValidate, 0)
  error.value = undefined
  router.push(`${PATHS.PARAMETERS_APPLICATIONS}`)
}

function onCloseResponseModal() {
  showModalSuccess.value = false
  getLicenseStatus()
}

onMounted(async() => {
  await fetchUserProfiles()
  await retrieveAllSectors()
  sectorOptions.value = allSectors.value
})

watch(() => props.sideCanvasShown, getInterfaceDetails)

const radioButtonWrapperClass: Array<string> = [
  'py-3', 'rounded-xl', 'px-4', 'bg-white', 'border', 'border-gray-300', 'w-36',
]
</script>
<template>
  <lxc-side-canvas
    v-if="canViewThirdPartyApps()"
    v-model:show="formSideCanvasShown"
    :header="sidePanelHeader"
    :close-tooltip="$t('filters.close')"
    @discard="onClose"
  >
    <lxc-container
      :px="0"
      :py="0"
      :error="error"
      :is-loading="isLoading"
    >
      <h6
        class="mb-4"
      >
        {{ $t('applicationMgt.tabs.thirdPartyApp.form.title') }}
      </h6>
      <lxc-form
        ref="interfaceFormRef"
        :model="interfaceForm"
        :rules="interfaceFormRules"
        class="flex flex-col gap-2"
      >
        <lxc-form-item
          :label="$t('applicationMgt.tabs.thirdPartyApp.form.name')"
          prop="name"
        >
          <lxc-input
            v-model="interfaceForm.name"
            :disabled="isInterfaceUpdate"
            type="text"
          />
        </lxc-form-item>
        <lxc-form-item
          :label="$t('applicationMgt.tabs.thirdPartyApp.form.profile.label')"
          prop="profile"
        >
          <lxc-select
            v-model="interfaceForm.profile"
            :disabled="!canManageThirdPartyApps()"
            :placeholder="$t('applicationMgt.tabs.thirdPartyApp.form.profile.placeholder')"
          >
            <lxc-option
              v-for="profile in profiles?.data"
              :key="profile.code"
              :value="profile.code"
              :label="profile.label"
            />
          </lxc-select>
        </lxc-form-item>
        <lxc-form-item
          :label="$t('applicationMgt.tabs.thirdPartyApp.form.sectors.label')"
          prop="sectors"
        >
          <lxc-select
            v-model="interfaceForm.sectors"
            multiple
            :disabled="!canManageThirdPartyApps()"
            :placeholder="$t('applicationMgt.tabs.thirdPartyApp.form.sectors.placeholder')"
            :select-all-label="$t('sectors.select.all')"
            :select-options-selected-label="$t('sectors.select.selected')"
          >
            <lxc-option
              v-for="sector in allSectors"
              :key="sector.code"
              :value="sector.code"
              :label="sector.label"
              :disabled="!sector.grantedStatus.granted"
            />
          </lxc-select>
        </lxc-form-item>
        <lxc-form-item
          prop="authenticationType"
          :label="$t('applicationMgt.tabs.thirdPartyApp.form.authenticationType.label')"
          display-label
        >
          <div class="flex gap-4">
            <div
              :class="radioButtonWrapperClass"
            >
              <lxc-radio
                v-model="interfaceForm.authenticationType"
                :value="InterfaceAuthenticationType.CLIENTGRANT"
                :label="$t('applicationMgt.tabs.thirdPartyApp.form.authenticationType.clientGrant')"
                :disabled="isInterfaceUpdate"
              />
            </div>
            <div
              :class="radioButtonWrapperClass"
            >
              <lxc-radio
                v-model="interfaceForm.authenticationType"
                :value="InterfaceAuthenticationType.MTLS"
                :label="$t('applicationMgt.tabs.thirdPartyApp.form.authenticationType.mtls')"
                disabled
              />
            </div>
          </div>
        </lxc-form-item>
      </lxc-form>
      <div
        v-if="isInterfaceUpdate"
        class="flex flex-col gap-6 mt-2"
      >
        <div
          class="flex gap-4"
        >
          <div class="flex-1">
            <lxc-input
              type="text"
              disabled
              :label="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.clientId.label')"
              :model-value="interfaceForm?.clientId"
            />
          </div>
          <lxc-copy-to-clipboard
            :confirmation="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.copied')"
            :title="$t('applicationMgt.tabs.thirdPartyApp.creationModalSuccess.copyToClipboard.buttonTitle')"
            :info-to-copy="interfaceForm?.clientId"
          />
        </div>
        <div class="flex gap-2">
          <lxc-info-sign />
          <div>
            <h6 class="my-0">
              {{ $t('applicationMgt.tabs.thirdPartyApp.form.update.information.title') }}
            </h6>
            <p
              class="my-1"
            >
              {{ $t('applicationMgt.tabs.thirdPartyApp.form.update.information.content') }}
            </p>
          </div>
        </div>
      </div>
    </lxc-container>
    <template #footer>
      <div class="flex gap-4">
        <lxc-button
          html-type="button"
          type="tertiary"
          :title="closeButtonLabel"
          @click="onClose"
        >
          {{ closeButtonLabel }}
        </lxc-button>
        <lxc-button
          html-type="button"
          type="primary"
          :icon="isInterfaceUpdate ? undefined : ILxcPlus"
          :title="validateButtonLabel"
          class="flex-1"
          :disabled="!edited || !canManageThirdPartyApps() || isSaving"
          @click="onSubmit"
        >
          {{ validateButtonLabel }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
  <lxc-third-party-application-creation-response-modal
    :show="showModalSuccess"
    :new-interface-response="newInterfaceResponse"
    @close="onCloseResponseModal"
  />
</template>
