<script setup lang="ts">
import type { UniqueLabel } from '@lxc/app-device-common'
import { filterEmptyValues } from '@lxc/app-device-common'
import type { UserDataI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  modelValue: UserDataI[]
  label: string
}>()

const emit = defineEmits(['update:modelValue'])
const userName: Ref<string> = ref('')

function deleleTagFromList(id: string, list?: UserDataI[]) {
  if (list) {
    const index = list.findIndex(user => user.id === id)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

const selectedUsers = computed({
  get(): UserDataI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<UserDataI>) {
    emit('update:modelValue', selected)
  },
})

const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(props.modelValue.map(user => filtersUtils.getUserTag(user, props.label)))
})

const onTagDeleteClick = (label: string, uid?: string) => {
  deleleTagFromList(uid ?? label, selectedUsers.value)
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>

  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <lxc-log-user-name-filter v-model="userName" />
    <lxc-log-user-list-filter
      v-model="selectedUsers"
      :name="userName"
    />
  </div>
</template>
