import { NO_CONTENT } from '~/components/dtwins/dtwinsForm/LxcDtwinsFormType'

class Communication {
  readonly antenna: string
  readonly mobileNetwork: string
  readonly gsmFrequencyBand: string
  readonly communicationProtocol: string

  constructor({
    antenna,
    mobileNetwork,
    gsmFrequencyBand,
    communicationProtocol,
  }: {
    antenna?: string
    mobileNetwork?: string
    gsmFrequencyBand?: string
    communicationProtocol?: string
  }) {
    this.antenna = antenna ?? NO_CONTENT
    this.mobileNetwork = mobileNetwork ?? NO_CONTENT
    this.gsmFrequencyBand = gsmFrequencyBand ?? NO_CONTENT
    this.communicationProtocol = communicationProtocol ?? NO_CONTENT
  }
}

class SimCard {
  readonly accessPointName: string
  readonly userName: string

  constructor({
    accessPointName,
    userName,
  }: {
    accessPointName?: string
    userName?: string
  }) {
    this.accessPointName = accessPointName ?? NO_CONTENT
    this.userName = userName ?? NO_CONTENT
  }
}

export class LxcDtwinsDescriptionConnectivityType {
  readonly communication: Communication
  readonly simCard: SimCard

  constructor({
    antenna,
    mobileNetwork,
    gsmFrequencyBand,
    communicationProtocol,
    accessPointName,
    userName,
  }: {
    antenna?: string
    mobileNetwork?: string
    gsmFrequencyBand?: string
    communicationProtocol?: string
    accessPointName?: string
    userName?: string
  }) {
    this.communication = new Communication({
      antenna,
      mobileNetwork,
      gsmFrequencyBand,
      communicationProtocol,
    })
    this.simCard = new SimCard({
      accessPointName,
      userName,
    })
  }
}
