<script setup lang="ts">
import type { SectorI } from '@lxc/app-device-types'
import { ErrorCode } from '@lxc/app-device-types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import sectorsService from '~/services/sectors.service'
import { useSectorStore } from '~/stores/useSectorStore'

const props = defineProps<{
  isVisible: boolean
  sector: SectorI|null
}>()
const emit = defineEmits(['change', 'close'])
const { t } = useI18n()
const { resetSectorsSelection } = useSectorStore()

/**
 * Create a sector/sub sector
 */
async function onRemoveSector() {
  if (!props.sector?.id) {
    return
  }

  const response = await sectorsService.removeSector(props.sector.id)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    await resetSectorsSelection()
    emit('change')
  }
}

/**
 * Close
 * @param refresh
 */
function close(refresh = false) {
  emit('close', refresh)
}
</script>

<template>
  <lxc-confirm-modal
    :is-dialog-visible="isVisible"
    :title="$t('sectors.modals.remove.title', { sectorName: sector?.label })"
    :description="$t('sectors.modals.remove.description')"
    @cancel="close"
    @confirm="onRemoveSector"
  />
</template>
