<script setup lang="ts">
import type { SectorI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'
import { useElTable } from '~/composables/useElTable'
import { useToggle } from '~/composables/useToggle'
import { PATHS } from '~/constants/paths'
import SectorsService from '~/services/sectors.service'
import LxcError from '~/utils/LxcError'

const router = useRouter()
const [formDialogVisible, toggleFormDialogVisible] = useToggle()
const [removeDialogVisible, toggleRemoveDialogVisible] = useToggle()
const [removeDeniedDialogVisible, toggleRemoveDeniedDialogVisible] = useToggle()
const { getCellStyle } = useElTable()

const error = ref()
const isLoading = ref(false)
const sectors = ref<SectorI[]>([])
const query = ref<string>('')
const selectedSector = ref<SectorI | null>(null)
const selectedSectorHasAsyncTask = ref(false)
const isCreate = ref(false)
const grantedSectors: Ref<string[]> = computed(() => getGrantedSectors(sectors.value))

function getGrantedSectors(sectors: SectorI[], initialValue: string[] = []): string[] {
  return sectors.reduce((previousValue, currentValue) => {
    const grantedSectors = [...previousValue, ...getGrantedSectors(currentValue.children ?? [], [])]
    return currentValue.grantedStatus.granted ? [...grantedSectors, currentValue.code] : grantedSectors
  },
  initialValue,
  )
}

onMounted(getSectors)

/**
 * Retrieve sectors
 */
async function getSectors() {
  isLoading.value = true
  const response = await SectorsService.getSectors(query.value)

  if (LxcError.check(response)) {
    error.value = response
  } else {
    sectors.value = response === null ? [] : [response as SectorI]
    error.value = null
  }

  isLoading.value = false
}

function onClear() {
  query.value = ''
  getSectors()
}

/**
 * On select a specific sector
 * @param sector
 */
function onSelectSector(sector: SectorI) {
  if (canUpdateSector(sector.code)) {
    router.push(`${PATHS.SECTORS}/${sector.code}`)
  }
}

/**
 * Show create sector modal
 */
function onCreateSector(sector?: SectorI) {
  setSelectedSector(sector)
  isCreate.value = true
  toggleFormDialogVisible()
}

function onUpdateSector(sector?: SectorI) {
  setSelectedSector(sector)
  isCreate.value = false
  toggleFormDialogVisible()
}

/**
 * Show remove sector modal or denied modal based on sector devices
 */
async function onRemoveSector(sector: SectorI) {
  await setSelectedSector(sector)
  selectedSectorHasAsyncTask.value = true
  const isAllowed = await SectorsService.isAllowedToRemoveSector(sector.id)
  selectedSectorHasAsyncTask.value = false
  if (!isAllowed) {
    toggleRemoveDeniedDialogVisible()
  } else {
    toggleRemoveDialogVisible()
  }
}

/**
 * Close create sector modal
 * @param refresh
 */
function onCloseRemoveSector(refresh = false) {
  toggleRemoveDialogVisible(false)
  onClose(refresh)
}

function onCloseDeniedRemoveSector() {
  toggleRemoveDeniedDialogVisible(false)
}

/**
 * Close remove sector modal and reset parentSector
 * Refresh list if needed
 * @param refresh
 */
function onCloseCreateSector(refresh = false) {
  toggleFormDialogVisible(false)
  onClose(refresh)
}

async function setSelectedSector(sector?: SectorI) {
  if (sector) {
    selectedSector.value = await SectorsService.getSectorByCode(sector.code)
  }
}

function onClose(refresh: boolean) {
  selectedSector.value = null

  if (refresh) {
    getSectors()
  }
}

function onInputChange(event: string) {
  query.value = event
}

function canUpdateSector(sectorCode: string): boolean {
  return grantedSectors.value.includes(`${sectorCode}`)
}

function getClass(row: any): string {
  let classes = 'sector-row'
  if (!row?.row?.grantedStatus.granted) {
    classes = `${classes} not-granted-sector`
  }
  return classes
}

</script>

<template>
  <lxc-container
    :px="0"
  >
    <lxc-breadcrumb class="px-8 mb-8" />

    <lx-toolbar>
      <lx-search-bar
        :search-query="query"
        :is-loading="isLoading"
        :search-placeholder="$t('sectors.search')"
        @clear="onClear"
        @input-change="onInputChange"
        @search="getSectors"
      />
    </lx-toolbar>

    <lxc-container
      :is-loading="isLoading && !!sectors"
      :error="error"
    >
      <el-table
        v-loading="isLoading"
        class="tree-table"
        default-expand-all
        :data="sectors"
        :fit="true"
        :empty-text="$t('sectors.empty')"
        row-key="code"
        :row-class-name="getClass"
        :cell-style="getCellStyle"
      >
        <el-table-column :label="$t('sectors.header.name')">
          <template #default="scope">
            <el-button
              :disabled="!canUpdateSector(scope.row?.code)"
              text
              @click="onSelectSector(scope.row)"
            >
              {{ scope.row.label }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template #default="scope">
            <lxc-loader
              v-if="scope.row?.code == selectedSector?.code && selectedSectorHasAsyncTask"
              class="pl-3"
            />
            <lxc-sector-actions
              v-else
              :is-affected="canUpdateSector(scope.row?.code)"
              :is-top-level="scope.row.isRootSector"
              :is-last-level="scope.row.isLastLevel"
              @create="onCreateSector(scope.row)"
              @update="onUpdateSector(scope.row)"
              @remove="onRemoveSector(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </lxc-container>

    <lxc-sector-form
      v-if="selectedSector"
      :is-visible="formDialogVisible"
      :sector="selectedSector"
      :is-create="isCreate"
      @close="onCloseCreateSector"
    />

    <lxc-sector-remove
      :is-visible="removeDialogVisible"
      :sector="selectedSector"
      @close="onCloseRemoveSector(false)"
      @change="onCloseRemoveSector(true)"
    />
    <lxc-sector-remove-denied
      :is-visible="removeDeniedDialogVisible"
      @close="onCloseDeniedRemoveSector()"
    />
  </lxc-container>
</template>

<style lang="scss" scoped>
:deep(.sector-row) .cell {
  display: flex;
  align-items: center;

  .el-table__expand-icon {
    width: $row-expand-icon-size;
    line-height: $row-expand-icon-size;
    height: $row-expand-icon-size;
  }

  .el-button {
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
    flex: 1 1 100%;
    justify-content: start;
  }
}
</style>
