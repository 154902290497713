import { useRoute, useRouter } from 'vue-router'
import { computed, reactive } from 'vue'
import type { FilterSelectionDefinition, FiltersSelection } from '~/types'
import { Filters, FiltersType } from '~/types'

export enum SearchMode {
  URL_SEARCH,
  FILTER_SEARCH,
}

export function useSearch(filterType: FiltersType, appliedFilters?: FiltersSelection, searchMode: SearchMode = SearchMode.URL_SEARCH) {
  const route = useRoute() ?? {}
  const router = useRouter() ?? {}

  const filters = reactive<FiltersSelection>(appliedFilters ?? new Map())

  let onSearchCallback: Function

  const searchParams = computed(() => getSearchParams())

  // watch the route changes and set the filters, then execute the search
  if (searchMode === SearchMode.URL_SEARCH) {
    watch(route, () => setFiltersFromQueryParams())
  }

  const routePath = route.path
  /**
   * If filters set in url, apply as active filters
   */
  function setFiltersFromQueryParams() {
    if (route.path === routePath) {
      // Reset all the filters
      filters.forEach((_: FilterSelectionDefinition, filter: Filters) => {
        setFilter(filter, filters.get(filter)?.value)
      })

      // Apply URL filters
      for (const param in route.query) {
        const isParamArray = /\[\]$/.test(param)
        const filterName = isParamArray ? param.substring(0, param.length - 2) : param
        const filterValue = route.query[param]
        const filter = filterName as Filters
        if (filters.has(filter)) {
          setFilter(filter, isParamArray && !Array.isArray(filterValue) ? [filterValue] : filterValue)
        }
      }

      // Call callback
      if (onSearchCallback) {
        onSearchCallback()
      }
    }
  }

  /**
   * Set a filter
   * @param filter
   * @param value
   * @param fallbackValue
   */
  function setFilter(filter: Filters, value: any, fallbackValue?: any): void {
    const filtersSelection: FilterSelectionDefinition|undefined = filters.get(filter)

    if (filtersSelection) {
      filtersSelection.value = value
      filtersSelection.fallbackValue = fallbackValue
    }
  }

  /**
   * Execute a search with the filters set
   */
  function search() {
    // Default case => URL_SEARCH
    if (searchMode === SearchMode.URL_SEARCH) {
      // Update the URL parameters, the search is launched by the route watcher
      const query = Object.assign({}, route.query)
      filters.forEach((filterSelectionDefinition: FilterSelectionDefinition, filter: Filters) => {
        if (Array.isArray(filterSelectionDefinition.value)) {
          if (filterSelectionDefinition.value.length === 0) {
            delete query[`${filter}[]`]
          } else {
            query[`${filter}[]`] = filterSelectionDefinition.value
          }
        } else {
          if (filterSelectionDefinition.value === '') {
            delete query[filter]
          } else {
            query[filter] = filterSelectionDefinition.value
          }
        }
      })
      query.t = Date.now().toString()
      router.push({ path: route.path, query })
    }
    // Case FILTER_SEARCH
    else if (onSearchCallback) {
      onSearchCallback()
    }
  }

  /**
   * Define the function to call on search execution
   * Execute the callback at the same time
   * @param callback
   */
  function onSearch(callback: Function) {
    onSearchCallback = callback

    // Default case => URL_SEARCH
    if (searchMode !== SearchMode.FILTER_SEARCH) {
      setFiltersFromQueryParams()
    } else {
      onSearchCallback()
    }
  }

  /**
   * Build the query of filters depending on the filter type
   * @returns the formatted query
   */
  function getSearchParams(): string | FiltersSelection {
    if (filterType === FiltersType.RAW) {
      return filters.get(Filters.NAME)?.value as string
    }

    // get the filters which have a defined value (not empty string or empty array)
    const filtersWithValue: Array<FilterSelectionDefinition> = [...filters.values()]
      .filter(filterSelectionDefintion => filterSelectionDefintion.value.length || filterSelectionDefintion.fallbackValue?.length)

    if (filterType === FiltersType.FILTERS_SELECTION) {
      return initSearchFilterSelection()
    }

    let orFilterFormatter: (formattedFilters: Array<string>) => string
    let arrayValueFormatter: (value: Array<string>) => string
    let andSeparator: string

    const mapFormatFilter = (filter: FilterSelectionDefinition) => formatFilter(filter, orFilterFormatter, arrayValueFormatter)

    switch (filterType) {
      case FiltersType.TWO_AMPERSAND_SEPARATOR:
        orFilterFormatter = (formattedFilters: Array<string>) => `(${formattedFilters.join('||')})`
        arrayValueFormatter = (values: Array<string>) => `[${values.join(',')}]`
        andSeparator = '&&'

        return filtersWithValue.map(mapFormatFilter).join(andSeparator)
      case FiltersType.PIPE_SEPARATOR:
        orFilterFormatter = (formattedFilters: Array<string>) => `(${formattedFilters.join('|\'')})`
        arrayValueFormatter = (values: Array<string>) => `[${values.join(',')}]`
        andSeparator = '|'

        return `(${filtersWithValue.map(mapFormatFilter).join(andSeparator)})`
      case FiltersType.RSQL:
        orFilterFormatter = (formattedFilters: Array<string>) => `(${formattedFilters.join(',')})`
        arrayValueFormatter = (values: Array<string>) => `(${values.join(',')})`
        andSeparator = ';'

        return filtersWithValue.map(mapFormatFilter).join(andSeparator)
    }

    return ''
  }

  /**
   * Transform a FilterSelectionDefinition to a formatted string query
   * @param filterSelectionDefinition
   * @param orFilterFormatter
   * @param arrayValueFormatter
   * @returns query as string
   */
  function formatFilter(
    filterSelectionDefinition: FilterSelectionDefinition,
    orFilterFormatter: (formattedFilters: Array<string>) => string,
    arrayValueFormatter: (value: Array<string>) => string): string
  {
    // case when the key is an array => it is considered to be an OR query => format the filter for each key recursively
    if (Array.isArray(filterSelectionDefinition.key)) {
      const formattedValues = filterSelectionDefinition.key.map(key => formatFilter({ ...filterSelectionDefinition, key }, orFilterFormatter, arrayValueFormatter))
      return orFilterFormatter(formattedValues)
    } else {
      let value = filterSelectionDefinition.value ?? filterSelectionDefinition.fallbackValue ?? ''

      if (filterSelectionDefinition.valueFormatter) {
        value = filterSelectionDefinition.valueFormatter(value)
      } else if (Array.isArray(value)) {
        value = arrayValueFormatter(value)
      }

      return `${filterSelectionDefinition.key}${filterSelectionDefinition.operator}${value}`
    }
  }

  function initSearchFilterSelection(): FiltersSelection {
    const searchFilterSelection: FiltersSelection = new Map<Filters, FilterSelectionDefinition>()
    const searchKeys = Array.from(filters.keys())
      .filter((filterName: Filters) => {
        const definition = filters.get(filterName)
        return !!definition?.value?.length
      })
    for (const filterName of searchKeys) {
      searchFilterSelection.set(filterName, filters.get(filterName) as FilterSelectionDefinition)
    }
    return searchFilterSelection
  }

  return {
    searchParams,
    filters,
    setFilter,
    onSearch,
    search,
  }
}
