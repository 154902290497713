import type { ApiListResult, AsyncLogI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import type { ReactiveVariable } from 'vue/macros'
import { useSort } from './useSort'
import { useFetchPage } from './useFetchPage'
import { SearchMode, useSearch } from '~/composables/useSearch'
import logsService, { LogsService } from '~/services/logs.service'
import type { FiltersSelection } from '~/types'
import { Filters, FiltersType } from '~/types'
import type LxcError from '~/utils/LxcError'

export interface LogResult {
  error: Ref<LxcError | null>
  isLoading: Ref<boolean>
  fetchData: (page: number, pageSize: number, sortOverride?: string, params?: Map<string, any>) => Promise<void>
  results: Ref<ApiListResult<AsyncLogI> | null>
  searchParams: ComputedRef<string | FiltersSelection>
  filters: ReactiveVariable<FiltersSelection>
  setFilter: (filter: Filters, event: any) => void
  search: () => void
  onSearch: (callback: Function) => void
}

export function useLogs(): LogResult {
  const appliedFilters: FiltersSelection = new Map([
    [Filters.LOG_ACTION, { key: 'action', operator: '=', value: [] }],
    [Filters.LOG_COMPONENT_ID, { key: 'componentId', operator: '=', value: '' }],
    [Filters.LOG_END_DATE, { key: 'endDate', operator: '=', value: '' }],
    [Filters.LOG_EVENT_TYPE, { key: 'eventType', operator: '=', value: '' }],
    [Filters.LOG_ENTITY_CLASS, { key: 'entityClass', operator: '=', value: '' }],
    [Filters.LOG_ENTITY_ID, { key: 'entityId', operator: '=', value: [] }],
    [Filters.LOG_LEVEL, { key: 'level', operator: '=', value: [] }],
    [Filters.LOG_START_DATE, { key: 'startDate', operator: '=', value: '' }],
    [Filters.LOG_TYPE, { key: 'type', operator: '=', value: '' }],
    [Filters.STATUS, { key: 'status', operator: '=', value: [] }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.FILTERS_SELECTION, appliedFilters, SearchMode.URL_SEARCH)
  const service = LogsService.prototype.getLogs.bind(logsService)
  const { sort } = useSort()

  const { isLoading, results, error, fetchData } = useFetchPage<ApiListResult<AsyncLogI> | null>({ service, searchParams, sort })

  return {
    error,
    fetchData,
    isLoading,
    results,
    searchParams,
    filters,
    setFilter,
    search,
    onSearch,
  }
}
