<script lang='ts' setup>
import type { Breadcrumb } from './breadcrumb.config'
import ILxcArrowLeft from '~icons/lxc/arrow-left'

const { te } = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  name?: string | Breadcrumb | (string | Breadcrumb)[]
  back?: boolean
}>()

const breadcrumb = route.meta.breadcrumb as Breadcrumb
</script>

<template>
  <div
    class="h-11 flex justify-start items-center"
  >
    <lxc-button
      v-if="props.back"
      type="tertiary"
      class="h-11 w-11 mr-4"
      @click="router.back()"
    >
      <ILxcArrowLeft class="w-5 h-5 text-gray-700" />
    </lxc-button>

    <h2
      class="text-3xl font-semibold text-gray-900"
    >
      <span v-if="typeof props.name === 'string'">
        {{ $t(props.name) }}
      </span>
      <span
        v-else
      >
        {{ $t(breadcrumb.title) }}
      </span>
    </h2>
  </div>
</template>
