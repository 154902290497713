<script lang='ts' setup>
import type { AppDeviceState, DeviceI } from '@lxc/app-device-types'
import { ErrorCode } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import { useToggle } from '~/composables/useToggle'
import DeviceService from '~/services/device.service'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'
import { useUpdateState } from '~/composables/useUpdateState'
import { ACL_ROLES } from '~/types'
import ILxcLightTrash2 from '~icons/lxc-light/trash-2'
import { useLicenseStatus } from '~/composables/useLicenseStatus'

const props = defineProps<{
  isActivated: AppDeviceState
  device: DeviceI
}>()

const emit = defineEmits(['change'])

const {
  getLicenseStatus,
} = useLicenseStatus()

const { t } = useI18n()
const { can } = useAcl()
const { createDataForStateUpdate, getState } = useUpdateState()

const [confirmUpdateStateVisible, toggleConfirmUpdateState] = useToggle()
const [confirmDeleteDialogVisible, toggleConfirmDeleteVisible] = useToggle()
const disabledConfirm = ref(false)

const options = computed(() => createDataForStateUpdate(props.isActivated, 'device', props.device.name!))

async function updateState(state: AppDeviceState, id: string) {
  disabledConfirm.value = true
  const response = await DeviceService.updateDeviceState(id, getState(state))

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DEACTIVATION_CONFLICT) {
      showNotificationError(t('device.updateState.deactivate.error'))
    } else {
      response.notify(NotificationKey.saveError)
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    await getLicenseStatus()
  }

  toggleConfirmUpdateState()
  disabledConfirm.value = false
  emit('change')
}

async function deleteDevice(id: string) {
  disabledConfirm.value = true
  const response = await DeviceService.deleteDevice(id)

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DELETION_CONFLICT) {
      showNotificationError(t('device.delete.error'))
    } else {
      response.notify(NotificationKey.removeError)
    }
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    await getLicenseStatus()
  }

  toggleConfirmDeleteVisible()
  disabledConfirm.value = false
  emit('change')
}

enum Action {
  UPDATE_STATE = 'UPDATE_STATE',
  DELETE = 'DELETE',
}

function onClick(event: Event, action: Action) {
  event.preventDefault()
  event.stopPropagation()

  switch (action) {
    case Action.UPDATE_STATE:
      toggleConfirmUpdateState(true)
      break
    case Action.DELETE:
      toggleConfirmDeleteVisible(true)
      break
  }
}

const canUpdateState = computed(() => can(ACL_ROLES.UPDATE_DEVICES))

const buttonClass = [
  'invisible',
]
</script>

<template>
  <!-- activate/deactivate button -->
  <div class="flex">
    <lxc-button
      v-if="options && canUpdateState"
      html-type="button"
      type="borderless"
      :icon="options.icon"
      :title="options.activatedButton"
      :class="buttonClass"
      @click="onClick($event, Action.UPDATE_STATE)"
    />
    <!-- delete button -->
    <lxc-button
      v-if="device.deletable"
      html-type="button"
      type="borderless"
      :icon="ILxcLightTrash2"
      :title="t('device.delete.execute')"
      :class="buttonClass"
      @click.prevent="onClick($event, Action.DELETE)"
    />
  </div>

  <lxc-confirm-modal
    v-if="options && device.id"
    :is-dialog-visible="confirmUpdateStateVisible"
    :title="options.title"
    :description="options.description"
    :info="options.warning"
    :disabled-confirm="disabledConfirm"
    @cancel="toggleConfirmUpdateState"
    @confirm="updateState(isActivated, device.id)"
  />

  <lxc-confirm-modal
    v-if="device.id"
    :is-dialog-visible="confirmDeleteDialogVisible"
    :title="$t('device.delete.execute')"
    :description="$t('device.delete.description', {name: device.name})"
    :info="$t('device.delete.warning')"
    :disabled-confirm="disabledConfirm"
    @cancel="toggleConfirmDeleteVisible"
    @confirm="deleteDevice(device.id)"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
