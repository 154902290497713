import { INPUT_NOT_DEFINED, NO_CONTENT } from '~/components/dtwins/dtwinsForm/LxcDtwinsFormType'

export class LxcDtwinsDescriptionGeolocationType {
  readonly address: string
  readonly latitude: string
  readonly longitude: string
  readonly isAllowedToEdit: boolean
  readonly doesUpdateSucceed: boolean | undefined

  constructor({
    address,
    latitude,
    longitude,
    isAllowedToEdit,
    doesUpdateSucceed,
  }: {
    address?: string
    latitude?: string
    longitude?: string
    isAllowedToEdit?: boolean
    doesUpdateSucceed?: boolean
  }) {
    this.address = address ?? INPUT_NOT_DEFINED
    this.latitude = latitude ?? NO_CONTENT
    this.longitude = longitude ?? NO_CONTENT
    this.isAllowedToEdit = isAllowedToEdit ?? false
    this.doesUpdateSucceed = doesUpdateSucceed
  }
}
