<script setup lang="ts">
import { FILTER_SEARCH_TXT_MIN_LENGTH } from '~/constants/constants'

const props = defineProps<{
  class?: string | string[]
  disabled?: boolean
  modelValue: string
  placeholder: string
  filterSearchTxtMinLength?: number
}>()
const emit = defineEmits(['update:modelValue'])

const inputClass = computed(() => {
  let vClass = [
    '!pl-10',
  ]

  const propClass: Array<string>|undefined = Array.isArray(props.class) ? props.class : props.class?.split(' ')
  if (propClass) {
    vClass = vClass.concat(propClass)
  }

  return vClass
})

const searchString = computed<string>({
  get(): string {
    return props.modelValue || ''
  },
  set(input?: string) {
    if ((input?.length ?? 0) >= (props.filterSearchTxtMinLength ?? FILTER_SEARCH_TXT_MIN_LENGTH)) {
      emit('update:modelValue', input)
    } else {
      emit('update:modelValue', '')
    }
  },
})
</script>
<template>
  <lxc-input
    v-model="searchString"
    type="search"
    prefix-read-only
    prefix-class="!px-[0.625rem]"
    :class="inputClass"
    :placeholder="placeholder"
    :disabled="disabled ?? false"
  >
    <template #prefix>
      <span class="text-gray-500">
        <ILxcLightSearch class="w-5 h-5" />
      </span>
    </template>
  </lxc-input>
</template>
