import type { ApiListResult, TruststoreCertificateDetailI, TruststoreCertificateI, TruststoreCertificateRequestI, TruststoreUploadedCertificateI } from '@lxc/app-device-types'
import type { AxiosProgressEvent } from 'axios'
import RestService from './rest.service'
import type { FiltersSelection } from '~/types'

export class TruststoreService extends RestService {
  protected BASE_URL = '/truststore'
  protected UPLOAD_PATH = '/upload'
  /**
   * Get list of certificates in truststore
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @return Promise of List of truststore certificates with context

   */
  getListOfCertificates(page?: number, pageSize?: number, search?: string | FiltersSelection, sort?: string): Promise<ApiListResult<TruststoreCertificateI>> {
    const params = { page, pageSize, search, sort }
    return this.$api.get(this.BASE_URL, { params })
  }

  getCertificate(alias: string): Promise<TruststoreCertificateDetailI> {
    return this.$api.get(`${this.BASE_URL}/${alias}`)
  }

  /**
   * Delete a certificate entry by alias
   * @param alias Identifier of the certificate
   */
  deleteCertificate(alias: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${alias}`)
  }

  /**
   * Upload a certificate
   * @param certificate
   */
  uploadCertificate(certificate: File, progress: Ref<number | undefined | null>): Promise<TruststoreUploadedCertificateI> {
    const formData = new FormData()
    formData.append('certificate', certificate, certificate.name)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0
      },
    }
    return this.$api.post(`${this.BASE_URL}${this.UPLOAD_PATH}`, formData, config)
  }

  /**
   * Add a new certificate
   * @param certificate
   */
  addCertificate(certificate: TruststoreCertificateRequestI): Promise<void> {
    return this.$api.post(`${this.BASE_URL}`, { certificate })
  }

  /**
   * Update a certificate
   * @param certificate
   */
  updateCertificate(certificate: TruststoreCertificateRequestI): Promise<void> {
    return this.$api.put(`${this.BASE_URL}/${certificate.alias}`, { certificate })
  }
}

export default new TruststoreService()
