export enum Roles {
  DVTM_APP_ADM = 'dvtm-app-adm',
  DVTM_APP_CRT_RENEW = 'dvtm-app-crt-renew',
  DVTM_APP_VIEW = 'dvtm-app-view',
  DVTM_APPM_COM_ADM = 'dvtm-appm-com-adm',
  DVTM_APPM_COM_VIEW = 'dvtm-appm-com-view',
  DVTM_CACRT_ADM = 'dvtm-cacrt-adm',
  DVTM_CAMP_CFG = 'dvtm-camp-cfg',
  DVTM_CFG_ADM = 'dvtm-cfg-adm',
  DVTM_CYBER_LOGS_ACCESS = 'dvtm-cyber-logs-access',
  DVTM_DEVICE_FLEET_LOGS_ACCESS = 'dvtm-device-fleet-logs-access',
  DVTM_DEVICE_LOGS_ACCESS = 'dvtm-device-logs-access',
  DVTM_DVT_ADM = 'dvtm-dvt-adm',
  DVTM_DVT_CRT_RENEW = 'dvtm-dvt-crt-renew',
  DVTM_DVT_FIRM_UPDATE = 'dvtm-dvt-firm-update',
  DVTM_DVT_VIEW = 'dvtm-dvt-view',
  DVTM_FIRM_ADM = 'dvtm-firm-adm',
  DVTM_FIRM_VIEW = 'dvtm-firm-view',
  DVTM_LICENSEM_ADM = 'dvtm-licensem-adm',
  DVTM_PKI_ADM = 'dvtm-pki-adm',
  DVTM_PKI_CNX = 'dvtm-pki-cnx',
  DVTM_SECTORS_ADM = 'dvtm-sectors-adm',
  DVTM_SECTORS_VIEW = 'dvtm-sectors-view',
  DVTM_SYSTEM_LOGS_ACCESS = 'dvtm-system-logs-access',
  DVTM_USR_MGR = 'dvtm-usr-mgr',
  LXC_DEV_MAINTENER = 'lxc-dev-maintener',
  DVT_CACRT_VIEW = 'cacrt-cacrt-view',
  DVT_CACRT_ADM = 'dvtm-cacrt-adm',
  DVTM_APPM_THIRDPARTY_VIEW = 'dvtm-appm-thirdparty-view',
  DVTM_APPM_THIRDPARTY_ADM = 'dvtm-appm-thirdparty-adm',
}
