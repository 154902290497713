<script lang="ts" setup>
import type { FirmwareI } from '@lxc/app-device-types'
import { useDeleteFirmware } from '~/composables/useFirmware'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcLightTrash2 from '~icons/lxc-light/trash-2'
const { t } = useI18n()
const {
  canDeleteFirmware,
  checkFirmwareStateBeforeDelection,
  deleteError,
  deleteFirmware,
  deleteConfirmDialogVisible,
  isDeleteLoading,
  isEmbedded,
  toggleDeleteConfirmVisible,
} = useDeleteFirmware()

const props = defineProps<{
  firmware: FirmwareI
}>()
const emit = defineEmits(['change'])

const onDeletFirmwareAndPropagateChange = async() => {
  await deleteFirmware(props.firmware)

  if (!LxcError.check(deleteError.value)) {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    emit('change')
  } else {
    (deleteError.value as LxcError).notify(NotificationKey.saveError)
  }
}

const onCheckFirmwareStateBeforeDelection = async(event: Event) => {
  await checkFirmwareStateBeforeDelection(event, props.firmware.uuid)

  if (LxcError.check(deleteError.value)) {
    (deleteError.value as LxcError).notify(NotificationKey.error)
  }
}

</script>

<template>
  <lxc-button
    v-if="canDeleteFirmware"
    html-type="button"
    type="borderless"
    :disabled="isDeleteLoading"
    :icon="(!isDeleteLoading) ? ILxcLightTrash2 : undefined"
    :title="t('firmware.actions.delete.label')"
    class="invisible"
    @click.prevent="onCheckFirmwareStateBeforeDelection"
  >
    <lxc-loader
      v-if="isDeleteLoading"
    />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="deleteConfirmDialogVisible"
    :title="$t('firmware.actions.delete.label')"
    :description="isEmbedded
      ? $t('firmware.actions.delete.descriptionEmbedded', { name: firmware.name })
      : $t('firmware.actions.delete.description', { name: firmware.name })"
    @cancel="toggleDeleteConfirmVisible"
    @confirm="onDeletFirmwareAndPropagateChange"
  />
</template>
<style lang="scss" scoped>
  button {
    :deep(svg) {
      height: 20px;
      width: 20px;
    }
  }
</style>
