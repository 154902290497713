<script setup lang="ts">
import type { FilterSelectionDefinition } from '~/types'
import ILxcSearch from '~icons/lxc/search'

const props = defineProps<{
  searchQuery?: string | FilterSelectionDefinition
  isLoading: boolean
  searchPlaceholder: string
}>()

const emit = defineEmits(['search', 'clear', 'inputChange'])
</script>

<template>
  <el-form
    ref="formRef"
    label-width="100px"
    :inline="true"
    @submit.prevent="$emit('search')"
  >
    <el-form-item class="form-item">
      <el-input
        :model-value="searchQuery
          ? typeof searchQuery === 'string'
            ? searchQuery
            : searchQuery.value
          : ''"
        data-cy="searchInput"
        :placeholder="props.searchPlaceholder"
        clearable
        class="search-input"
        size="large"
        @clear="$emit('clear')"
        @input="$emit('inputChange', $event)"
      />
    </el-form-item>

    <slot />

    <el-form-item class="form-item">
      <el-button
        type="primary"
        size="large"
        class="search-button"
        role="search-button"
        :disabled="isLoading"
        :icon="ILxcSearch"
        data-cy="searchButton"
        @click="$emit('search')"
      />
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped>

.search-input{
  width:20rem;
}

.form-item {
  margin-bottom: 0;

  .search-button {
    margin-left: 0.5rem;
  }
}
</style>
