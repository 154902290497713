<script setup lang="ts">
import type { DtwinI, DtwinOperationI } from '@lxc/app-device-types'
import { DtwinOperationStatus } from '@lxc/app-device-types'
import { DTWIN_OPERATION_TYPE } from '~/constants/constants'
import operationManagerService from '~/services/operationManager.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcEdit from '~icons/lxc/edit'
import ILxcStopCircle from '~icons/lxc/stop-circle'
import { Roles } from '~/types/roles'
import { useUserSession } from '~/stores/useUserSession'

const { t } = useI18n()
const props = defineProps<{
  dtwin: DtwinI
  operation: DtwinOperationI
}>()
const emit = defineEmits(['change'])
const store = useUserSession()
const isAllowedToUpdate = computed(() => store.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE)
  && store.userSession?.roles.includes(Roles.DVTM_FIRM_VIEW))
const isUpdatable: ComputedRef<boolean> = computed(() => {
  let updatable: boolean
  switch (props.operation.status) {
    case DtwinOperationStatus.SCHEDULED:
    case DtwinOperationStatus.PENDING:
    case DtwinOperationStatus.QUEUED:
      updatable = isAllowedToUpdate.value ?? false
      break
    default:
      updatable = false
  }
  return updatable
})

const [confirmCancelOperationDialogVisible, toggleConfirmCancel] = useToggle()
const [updateFirmwareOperationDialogVisible, toggleUpdateFirmwareOperation] = useToggle()

const isCancelling: Ref<boolean> = ref(false)
async function cancel(operationUid: string) {
  toggleConfirmCancel()
  isCancelling.value = true
  const response = await operationManagerService.cancel(operationUid)
  isCancelling.value = false
  if (LxcError.check(response)) {
    showNotificationError(t(NotificationKey.error))
  } else {
    showNotificationSuccess(t(NotificationKey.success))
    emit('change')
  }
}

</script>
<template>
  <lxc-loader
    v-if="isCancelling"
  />
  <div
    v-else
    class="flex"
  >
    <lxc-button
      v-if="props.operation.type === DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE"
      :disabled="!isUpdatable"
      html-type="button"
      type="borderless"
      :icon="ILxcEdit"
      :title="isUpdatable ? $t('device.operation.update.tooltip.enabled') : $t('device.operation.update.tooltip.disabled')"
      class="invisible"
      @click="toggleUpdateFirmwareOperation()"
    />
    <lxc-button
      v-if="props.operation.type === DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE"
      :disabled="!isUpdatable"
      html-type="button"
      type="borderless"
      :icon="ILxcStopCircle"
      :title="isUpdatable ? $t('device.operation.cancel.tooltip.enabled') : $t('device.operation.cancel.tooltip.disabled')"
      class="invisible"
      @click="toggleConfirmCancel()"
    />
  </div>
  <lxc-confirm-modal
    :is-dialog-visible="confirmCancelOperationDialogVisible"
    :title="$t('device.operation.cancel.confirm.title')"
    :description="$t('device.operation.cancel.confirm.description')"
    @cancel="toggleConfirmCancel"
    @confirm="cancel(operation.uid)"
  />

  <lxc-dtwin-update-firmware-modal
    v-model:show="updateFirmwareOperationDialogVisible"
    :dtwin="dtwin"
    :operation="operation"
    @change="$emit('change')"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
