<script setup lang="ts">
import type { UniqueLabel } from '@lxc/app-device-common'
import { filterEmptyValues } from '@lxc/app-device-common'
import type { ApplicationI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  modelValue: ApplicationI[]
  label: string
}>()

const emit = defineEmits(['update:modelValue'])
const applicationName = ref('')
const applicationTypes: Ref<string[]> = ref([])
const applicationDeclinations: Ref<string[]> = ref([])

const selectedApplications = computed({
  get(): ApplicationI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<ApplicationI>) {
    emit('update:modelValue', selected)
  },
})
const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(props.modelValue.map(application => filtersUtils.getApplicationTag(application, props.label)))
})

function deleleTagFromList(uid: string) {
  if (selectedApplications.value) {
    const index = selectedApplications.value.findIndex(application => application.id === uid)

    if (index != null && index >= 0) {
      selectedApplications.value.splice(index, 1)
    }
  }
}

const onTagDeleteClick = (label: string, uid?: string) => {
  deleleTagFromList(uid ?? label)
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>

  <div class="rounded-lg bg-white text-gray-900">
    <div class="flex p-6 gap-6 flex-nowrap">
      <div class="flex-1 items-start">
        <lxc-log-application-type-filter v-model="applicationTypes" />
      </div>
      <div class="flex-1 items-start">
        <lxc-log-application-declination-filter
          v-model="applicationDeclinations"
          :types="applicationTypes"
        />
      </div>
    </div>
    <lxc-log-application-name-filter v-model="applicationName" />
    <lxc-log-application-list-filter
      v-model="selectedApplications"
      :declinations="applicationDeclinations"
      :name="applicationName"
      :types="applicationTypes"
    />
  </div>
</template>
