<script setup lang="ts">
import type { DtwinI, DtwinModelI } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import {
  LxcDtwinsDescriptionConnectivityType,
} from '~/components/dtwins/dtwinsForm/description/connectivity/LxcDtwinsDescriptionConnectivityType'
import {
  LxcDtwinsDescriptionGeolocationType,
} from '~/components/dtwins/dtwinsForm/description/geolocation/LxcDtwinsDescriptionGeolocationType'
import {
  LxcDtwinsDescriptionIdCardType,
} from '~/components/dtwins/dtwinsForm/description/idCard/LxcDtwinsDescriptionIdCardType'
import {
  LxcDtwinsDescriptionStateType,
} from '~/components/dtwins/dtwinsForm/description/state/LxcDtwinsDescriptionStateType'
import { useDtwin } from '~/composables/useDtwins'
import dtwinsService from '~/services/dtwins.service'
import { ACL_ROLES } from '~/types'
import { formatIsoDate } from '~/utils/date-tools'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'

const props = defineProps<{
  dtwin?: DtwinI | null
  models: DtwinModelI[]
}>()
const emit = defineEmits(['update:dtwin'])

const { t } = useI18n()
const dateFormat: string = t('dateFormat.datetime')

const acl = useAcl()
const isAllowedToEdit: ComputedRef<boolean> = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM))
const {
  getDtwinTypeLabel,
} = useDtwin()

/**
 * ID card representation used in the device ID UI card.
 */
const idCard: Ref<LxcDtwinsDescriptionIdCardType> = ref(
  new LxcDtwinsDescriptionIdCardType({
    friendlyName: props.dtwin?.attributes.friendlyName,
    type: getDtwinTypeLabel(props.models, props.dtwin),
    serialNumber: props.dtwin?.attributes.serialNumber,
    hardwareVersion: props.dtwin?.attributes.hardwareVersion,
    isAllowedToEdit: isAllowedToEdit.value,
  }),
)

/**
 * Handle the device friendly name update required by the user.
 * @param friendlyName New device friendly name
 */
async function updateFriendlyName(friendlyName: string): Promise<void> {
  const doesUpdateSucceed = await onUpdate({
    data: { friendlyName },
  })
  if (doesUpdateSucceed) {
    (props.dtwin as DtwinI).attributes.friendlyName = friendlyName
    emit('update:dtwin', props.dtwin)
  }

  idCard.value = new LxcDtwinsDescriptionIdCardType({
    friendlyName: doesUpdateSucceed ? friendlyName : props.dtwin?.attributes.friendlyName,
    type: getDtwinTypeLabel(props.models, props.dtwin),
    serialNumber: props.dtwin?.attributes.serialNumber,
    hardwareVersion: props.dtwin?.attributes.hardwareVersion,
    isAllowedToEdit: isAllowedToEdit.value,
    doesUpdateSucceed,
  })
}

/**
 * State representation used in the state UI card.
 */
const state: Ref<LxcDtwinsDescriptionStateType> = ref(
  new LxcDtwinsDescriptionStateType({
    lifeCycleState: props.dtwin?.lifeCycleState ? t(`dtwins.lifeCycleState.${props.dtwin?.lifeCycleState}`) : undefined,
    firstConnection: props.dtwin?.firstConnectionDate ? formatIsoDate(props.dtwin.firstConnectionDate, dateFormat) : undefined,
    lastConnection: props.dtwin?.lastConnectionDate ? formatIsoDate(props.dtwin.lastConnectionDate, dateFormat) : undefined,
    batteryStatus: props.dtwin?.features.battery?.reported?.level ?? undefined,
    configurationApplicationDate: props.dtwin?.features.configuration?.reported?.modificationTimestamp
      ? formatIsoDate(props.dtwin?.features.configuration.reported.modificationTimestamp, dateFormat)
      : undefined,
    firmwareVersion: props.dtwin?.features.firmware?.reported?.firmwareVersion ?? undefined,
    certificateExpirationDate: props.dtwin?.features.certificate?.reported.notAfter
      ? formatIsoDate(props.dtwin?.features.certificate?.reported.notAfter, dateFormat)
      : undefined,
    lastCertificateUpdate: props.dtwin?.features.certificate?.reported?.$metadata?.notAfter?.$lastValueUpdate
      ? formatIsoDate(props.dtwin?.features.certificate?.reported?.$metadata?.notAfter?.$lastValueUpdate, dateFormat)
      : undefined,
  }),
)

/**
 * Connectivity representation used in the connectivity UI card.
 */
const connectivity: Ref<LxcDtwinsDescriptionConnectivityType> = ref(
  new LxcDtwinsDescriptionConnectivityType({}),
)

/**
 * Geolocation representation used in the geolocation UI card.
 */
const geolocation: Ref<LxcDtwinsDescriptionGeolocationType> = ref(
  new LxcDtwinsDescriptionGeolocationType({
    address: props.dtwin?.attributes?.address,
    latitude: props.dtwin?.features?.location?.reported?.latitude,
    longitude: props.dtwin?.features?.location?.reported?.longitude,
    isAllowedToEdit: isAllowedToEdit.value,
  }),
)

/**
 * Handle the geolocation address update required by the user.
 * @param address New device address
 */
async function updateGeolocationAddress(address: string): Promise<void> {
  const doesUpdateSucceed = await onUpdate({
    data: { address },
  })
  if (doesUpdateSucceed) {
    (props.dtwin as DtwinI).attributes.address = address
    emit('update:dtwin', props.dtwin)
  }

  geolocation.value = new LxcDtwinsDescriptionGeolocationType({
    address: doesUpdateSucceed ? address : props.dtwin?.attributes.address,
    latitude: props.dtwin?.features?.location?.reported?.latitude,
    longitude: props.dtwin?.features?.location?.reported?.longitude,
    isAllowedToEdit: isAllowedToEdit.value,
    doesUpdateSucceed,
  })
}

async function onUpdate({ data }: { data: Record<string, any> }): Promise<boolean> {
  if (props.dtwin?.uid) {
    const response = await dtwinsService.patchDtwin(props.dtwin?.uid, data)

    if (!LxcError.check(response)) {
      emit('update:dtwin', props.dtwin)
      showNotificationSuccess(t(NotificationKey.saveSuccess))
      return true
    }
    response.notify(NotificationKey.saveError)
  }
  return false
}

</script>

<template>
  <LxcDtwinsDescriptionIdCard
    :model-value="idCard"
    @update:name="updateFriendlyName"
  />
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionState
    :model-value="state"
  />
  <!-- TODO: uncomment this section when the connectivity attribute will be available
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionConnectivity
    :model-value="connectivity"
  />
  -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionGeolocation
    :model-value="geolocation"
    @update:address="updateGeolocationAddress"
  />
</template>
