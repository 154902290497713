<script lang='ts' setup>
import type { UserGroupI } from '@lxc/app-device-types'
import { useToggle } from '~/composables/useToggle'
import userGroupsService from '~/services/userGroups.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxcDotMenu from '~icons/lxc-custom/dot-menu'
import ILxcTrash2 from '~icons/lxc/trash-2'

const props = defineProps<{ userGroup: UserGroupI }>()
const emit = defineEmits(['change'])
const { t } = useI18n()
const [removeModalVisible, toggleRemoveModalVisible] = useToggle()

enum Action {
  REMOVE = 'REMOVE',
}
const showConfirm = computed(() => props.userGroup.nbUsers === 0)
const modalMessagesKey = computed(() => showConfirm.value ? 'confirmDelete' : 'cannotDelete')
const title = computed(() => t(`userGroup.${modalMessagesKey.value}.title`))
const description = computed(() => t(`userGroup.${modalMessagesKey.value}.description`, {
  userGroupName: props.userGroup.label,
  usersCount: props.userGroup.nbUsers,
}))

/**
 * When removal confirmation of the user group
 * @param userGroupCode
 */
async function onRemoveUserGroup(userGroupCode: string) {
  const removeResponse = await userGroupsService.removeUserGroup(userGroupCode)

  if (LxcError.check(removeResponse)) {
    removeResponse.notify(NotificationKey.removeError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    toggleRemoveModalVisible()
    emit('change')
  }
}

/**
 * When selecting an action in menu
 * @param action
 */
function onSelectAction(action: Action) {
  switch (action) {
    case Action.REMOVE:
      toggleRemoveModalVisible(true)
      break
  }
}
</script>

<template>
  <el-dropdown
    trigger="click"
    placement="left"
    @command="onSelectAction"
  >
    <el-button
      text
      size="small"
    >
      <ILxcDotMenu />
    </el-button>
    <template #dropdown>
      <el-dropdown-menu class="table-menu">
        <el-dropdown-item
          :command="Action.REMOVE"
          :icon="ILxcTrash2"
        >
          {{ $t('userGroup.button.delete') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>

  <lxc-confirm-modal
    :show-confirm="showConfirm"
    :is-dialog-visible="removeModalVisible"
    :title="title"
    :description="description"
    @cancel="toggleRemoveModalVisible"
    @confirm="onRemoveUserGroup(userGroup?.code)"
  />
</template>
