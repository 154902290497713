<script lang='ts' setup>
import type { ApiListResult, ApplicationI, DeviceI, DtwinI, UserDataI, UserGroupI } from '@lxc/app-device-types'
import { useElTable } from '~/composables/useElTable'

const { getCellStyle } = useElTable()

const props = defineProps<{
  primaryKey: string
  isLoading: boolean
  selection?: boolean
  items: ApiListResult<any> | null
  displayActions?: boolean
  modelValue: any[]
  resource: string
  getDescription: ((item: ApplicationI) => string) | ((item: DeviceI) => string)| ((item: DtwinI) => string) | ((item: UserDataI) => string) | ((item: UserGroupI) => string)
}>()

const emit = defineEmits([
  'pagination:getNextPage',
  'actions:detach',
  'update:modelValue',
  'rowClick',
])

const detailTable = ref()
const rowSelection: any = ref([])

watch(() => props.items, async() => {
  await nextTick()
  refreshTableSelection()
})

watch(() => props.modelValue, async(value) => {
  rowSelection.value = value
  refreshTableSelection()
})

/**
 * When changing selection from input
 */
function onChangeSelectInput() {
  refreshTableSelection()
  updateModel()
}

/**
 * When selecting a specific row
 * @param _
 * @param selection
 */
function onSelectRow(_: Event, selection: any) {
  const rowIdx = rowSelection.value.findIndex((row: any) => row[props.primaryKey] === selection[props.primaryKey])

  if (rowIdx !== -1) {
    rowSelection.value.splice(rowIdx, 1)
  } else {
    rowSelection.value.push(selection)
  }

  updateModel()
}

/**
 * When selecting/deselecting everything
 * @param values
 */
function onSelectAll(values: Array<ApplicationI|DeviceI|DtwinI|UserDataI|UserGroupI>) {
  detailTable.value.data.forEach((selection: any) => {
    const rowIdx = rowSelection.value.findIndex((row: any) => row[props.primaryKey] === selection[props.primaryKey])

    if (values.length !== 0 && rowIdx === -1) {
      rowSelection.value.push(selection)
    } else if (values.length === 0) {
      rowSelection.value.splice(rowIdx, 1)
    }
  })

  updateModel()
}

function updateModel() {
  emit('update:modelValue', rowSelection.value)
}

function refreshTableSelection() {
  detailTable.value.data.forEach((row: any) => {
    const selected = rowSelection.value.some((item: any) => item[props.primaryKey] === row[props.primaryKey])
    detailTable.value.toggleRowSelection(row, selected)
  })
}
</script>

<template>
  <el-table
    ref="detailTable"
    v-loading="isLoading"
    :data="items?.data"
    :fit="true"
    class="sector-tables"
    row-class-name="table-row-clickable"
    :empty-text="$t(`${resource}.empty`)"
    :cell-style="getCellStyle"
    @select="onSelectRow"
    @select-all="onSelectAll"
    @row-click="$emit('rowClick', $event)"
  >
    <el-table-column
      v-if="selection"
      type="selection"
      width="55"
    />
    <slot />
    <el-table-column width="50">
      <template #default="scope">
        <lxc-sector-item-actions
          :display-actions="displayActions"
          @detach="emit('actions:detach', scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>

  <div class="footer-wrapper">
    <el-select
      v-if="rowSelection.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :placeholder="$t('sectors.details.currentSelection')"
      :teleported="false"
      @change="onChangeSelectInput"
    >
      <el-option
        v-for="item in rowSelection"
        :key="item.code"
        :label="getDescription(item)"
        :value="item"
      />
    </el-select>

    <lxc-pagination
      class="footer-pagination"
      :type-of-element="$t(`pagination.${resource}`, items?.context?.totalCount ?? 0)"
      :context="items?.context"
      @update:current-page="$emit('pagination:getNextPage', $event)"
    />
  </div>
</template>

<style lang='scss' scoped>
.sector-tables {
  margin-top: 1.5rem;
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .footer-pagination {
    flex: 1;
  }
}
</style>
