<script setup lang="ts">
import { type UniqueLabel } from '@lxc/app-device-common'
import { CampaignType } from '@lxc/app-device-types'
import { operationStateOptions } from './campaignOperationsFilters.config'
import { appModelTypeOptions } from '~/constants/applicationsFilters.config'
import { typeOptions } from '~/constants/deviceFilters.config'
import type { FilterFormSection, FilterSelectionDefinition, FilterSelectionValue, FiltersSelection, Option } from '~/types'
import { FilterInputType, Filters, OPERATOR_EQUAL } from '~/types'

const { t } = useI18n()

const props = defineProps<{
  currentCampaignType?: CampaignType|undefined
  filters: FiltersSelection
}>()

const stateFilterCompRef = ref()
const updateInProgress: Ref<boolean> = ref(false)
const applyPending: Ref<boolean> = ref(false)
const selectedStates: Ref<Array<string>> = ref([])
const selectedStatesTags: Ref<Array<UniqueLabel>> = ref([])
const appliedStatesTags: Ref<Array<UniqueLabel>> = ref([])

const isApplicationCertificateRenewalCampaignType: Ref<boolean> = computed(() => {
  return props.currentCampaignType === CampaignType.CRTCLT_RENEWAL_APP
})
const emit = defineEmits([
  'change',
  'enter',
])

const stateLabel = t('filters.state')
const typeLabel = t('filters.type')

function executeOnce(callback: () => void) {
  updateInProgress.value = true
  callback()
  updateInProgress.value = false
}

function executeOnceAndApplyFilter(callback: () => void) {
  executeOnce(callback)
  applyFilter()
}

function applyFilter() {
  if (!updateInProgress.value) {
    stateFilterCompRef.value.applyFilter()
    emit('enter')
  } else {
    applyPending.value = true
  }
}

function onClearFilters() {
  executeOnceAndApplyFilter(() => {
    stateFilterCompRef.value.clearFilter()
  })
}

function onAppliedFilterChange() {
  if (!updateInProgress.value) {
    stateFilterCompRef.value.onAppliedFilterChange()
  }
}

const onChange = (filter: Filters, value: FilterSelectionValue) => {
  emit('change', filter, value)
}

const onEnter = (event: Event) => {
  emit('enter', event)
}

const onUpdateInProgressChanged = (inProgress: boolean) => {
  if (!inProgress && applyPending.value) {
    applyFilter()
    applyPending.value = false
  }
}
const appliedFilterTags = computed((): UniqueLabel[] => {
  return appliedStatesTags.value
})

const selectedFilterTags = computed((): UniqueLabel[] => {
  return selectedStatesTags.value
})

const selectedFilterMap = computed((): FiltersSelection => {
  const filterSelection: FiltersSelection = new Map<Filters, FilterSelectionDefinition>()
  filterSelection.set(Filters.STATE, {
    key: 'state',
    operator: OPERATOR_EQUAL,
    value: selectedStates.value.slice(0),
  })
  return filterSelection
})

const selectedFiltersByTypes = computed<Array<Array<string>>>(() => [selectedStates.value])

function onDeleteSelectedTag(label: string, uid?: string) {
  executeOnce(() => {
    stateFilterCompRef.value.deleteFilter(label, uid)
  })
}

function onAppliedTagDeleteClick(label: string, uid?: string) {
  onDeleteSelectedTag(label, uid)
  applyFilter()
}

const filterOperationStateOptions: Ref<Option[]> = ref(operationStateOptions.options)
const filterModelTypeOptions: ComputedRef<Option[]> = computed(() => isApplicationCertificateRenewalCampaignType.value
  ? appModelTypeOptions.options
  : typeOptions.options)

const filterFormSections: FilterFormSection[] = [{
  disabled: false,
  filter: Filters.STATE,
  footerEnabled: true,
  footerId: 'state-footer',
  header: stateLabel,
  id: 'state',
  inputType: FilterInputType.CUSTOM,
  menuLabel: stateLabel,
  options: filterOperationStateOptions,
  tagPrefix: stateLabel,
  translate: true,
},
{
  disabled: false,
  filter: Filters.MODEL_TYPE,
  footerEnabled: true,
  footerId: 'type-footer',
  header: typeLabel,
  id: 'type',
  inputType: FilterInputType.CHECKBOX,
  menuLabel: typeLabel,
  options: filterModelTypeOptions,
  tagPrefix: typeLabel,
}]

function discardFilter() {
  executeOnce(() => {
    stateFilterCompRef.value.discardFilter()
  })
}

watch(() => updateInProgress.value, onUpdateInProgressChanged)
</script>
<template>
  <div class="relative mb-4">
    <lxc-filters
      :applied-tags="appliedFilterTags"
      :filter-sections="filterFormSections"
      :filters="filters"
      :filters-by-type="selectedFiltersByTypes"
      :selected-filters="selectedFilterMap"
      :selected-tags="selectedFilterTags"
      teleported-tags="#appliedFilterTags"
      is-button-right
      @apply="applyFilter"
      @applied-filter-change="onAppliedFilterChange"
      @change="onChange"
      @delete="onAppliedTagDeleteClick"
      @delete-selected="onDeleteSelectedTag"
      @enter="onEnter"
      @discard="discardFilter"
      @reset="onClearFilters"
    >
      <template #state>
        <lxc-campaign-operation-state-filter
          ref="stateFilterCompRef"
          v-model="selectedStates"
          v-model:selected-tags="selectedStatesTags"
          v-model:applied-tags="appliedStatesTags"
          :filters="filters"
          :tag-label="stateLabel"
          @change="onChange"
        />
      </template>
    </lxc-filters>
  </div>
</template>
