export enum ACL_ROLES {
  NO_CONTROL = 'NO_CONTROL',
  CONFIGURE_PKI = 'CONFIGURE_PKI',
  DISPLAY_USERS = 'DISPLAY_USERS',
  UPDATE_DEVICES = 'UPDATE_DEVICES',
  DISPLAY_SECTORS = 'DISPLAY_SECTORS',
  MANAGE_SECTORS = 'MANAGE_SECTORS',
  DISPLAY_DEVICES = 'DISPLAY_DEVICES',
  MANAGE_TRUSTSTORE = 'MANAGE_TRUSTSTORE',
  MANAGE_CERTIFICATES = 'MANAGE_CERTIFICATES',
  DVT_CACRT_VIEW = 'DVTM_CACRT_VIEW',
  DVT_CACRT_ADM = 'DVTM_CACRT_ADM',
  DISPLAY_FIRMWARES = 'DISPLAY_FIRMWARES',
  MANAGE_FIRMWARES = 'MANAGE_FIRMWARES',
  UPDATE_APPLICATIONS = 'UPDATE_APPLICATIONS',
  DISPLAY_APPLICATIONS = 'DISPLAY_APPLICATIONS',
  CONFIGURE_NOTIFICATION = 'CONFIGURE_NOTIFICATION',
  ASK_MANUAL_CERTIFICATE_RENEWAL_DEVICE = 'ASK_MANUAL_CERTIFICATE_RENEWAL_DEVICE',
  ASK_MANUAL_CERTIFICATE_RENEWAL_APP = 'ASK_MANUAL_CERTIFICATE_RENEWAL_APP',
  ASK_MANUAL_FIRMWARE_UPDATE_DEVICE = 'ASK_MANUAL_FIRMWARE_UPDATE_DEVICE',
  DISPLAY_CAMPAIGNS = 'DISPLAY_CAMPAIGNS',
  MANAGE_CAMPAIGNS = 'MANAGE_CAMPAIGNS',
  CYBER_LOGS_VIEW = 'CYBER_LOGS_VIEW',
  DEVICE_LOGS_VIEW = 'DEVICE_LOGS_VIEW',
  DEVICE_FLEET_LOGS_VIEW = 'DEVICE_FLEET_LOGS_VIEW',
  DVTM_DVT_ADM = 'dvtm-dvt-adm',
  SYSTEM_LOGS_VIEW = 'SYSTEM_LOGS_VIEW',
  LOGS_LIST_AUDIT = 'LOGS_LIST_AUDIT',
  USR_CERTIFICATES = 'USR_CERTIFICATES',
  BSCOMMON_ERRDESC_LIST = 'BSCOMMON_ERRDESC_LIST',
  LICENSEM_UPLOAD = 'LICENSEM_UPLOAD',
  DVTM_APPM_THIRDPARTY_VIEW = 'DVTM_APPM_THIRDPARTY_VIEW',
  DVTM_APPM_THIRDPARTY_ADM = 'DVTM_APPM_THIRDPARTY_ADM',
  DVTM_APPM_MOBILEAPP_VIEW = 'DVTM_APPM_MOBILEAPP_VIEW',
  DVTM_APPM_MOBILEAPP_ADM = 'DVTM_APPM_MOBILEAPP_ADM',
}
