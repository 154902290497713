<script setup lang='ts'>
import { Download } from '@element-plus/icons-vue'
import type { AsyncTaskIdI, AsyncTaskStatusI } from '@lxc/app-device-types'
import { saveAs } from 'file-saver'
import { useAcl } from 'vue-simple-acl'
import alertService from '~/services/alert.service'
import { ACL_ROLES } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'

const { t } = useI18n()
const isLoading = ref(false)
const acl = useAcl()
const canDownloadLogs = computed(() => acl.can(ACL_ROLES.BSCOMMON_ERRDESC_LIST))

async function downloadLogs() {
  isLoading.value = true
  const asyncTaskId: AsyncTaskIdI = await alertService.getExportLogsTaskId()

  if (LxcError.check(asyncTaskId)) {
    asyncTaskId.notify(NotificationKey.error)
    isLoading.value = false
  } else {
    const intervalId = setInterval(async() => {
      const asyncTaskStatus: AsyncTaskStatusI = await alertService.getExportLogsTaskStatus(asyncTaskId.taskId)

      if (LxcError.check(asyncTaskStatus)) {
        asyncTaskStatus.notify(NotificationKey.error)
        isLoading.value = false
        clearInterval(intervalId)
      } else {
        if (asyncTaskStatus.finished) {
          clearInterval(intervalId)

          const response = await alertService.downloadExportLogs(asyncTaskId.taskId)

          if (LxcError.check(response)) {
            response.notify(NotificationKey.error)
          } else {
            const blob = new Blob([response], { type: 'application/octet-stream' })
            const fileName = 'logExport.tar'
            saveAs(blob, fileName)
          }

          isLoading.value = false
        }
      }
    }, 5000)
  }
}

</script>

<template>
  <lxc-container>
    <h2
      v-if="canDownloadLogs"
      class="title"
    >
      {{ $t('page.about.downloadTitle') }}
    </h2>

    <div
      v-if="canDownloadLogs"
    >
      <el-button
        plain
        class="lxc-plain"
        :icon="Download"
        :disabled="isLoading"
        @click="downloadLogs"
      >
        {{ $t('button.download') }}
        <lxc-loader
          v-if="isLoading"
          class="ml-1.5"
        />
      </el-button>
    </div>
  </lxc-container>
</template>
