<script setup lang="ts">
import type { InterfaceAuthenticationType, InterfaceI, InterfaceStatus, UserProfileI } from '@lxc/app-device-types'
import { useAppInterface } from '~/composables/useAppInterface'
import { PATHS } from '~/constants/paths'
import UserProfilesService from '~/services/userProfiles.service'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  interfaceFormShown: boolean
  disableActivateInterfaceButton: boolean
}>()

const {
  isLoading,
  results,
  error,
  fetchData,
  canManageThirdPartyApps,
  canViewThirdPartyApps,
} = useAppInterface()

const route = useRoute()
const router = useRouter()

const emits = defineEmits([
  'update:sideCanvasShown',
])

const profileCodes: Ref<Array<string>> = ref([])
const userProfiles: Ref<Array<UserProfileI>> = ref([])
const selectedInterfaceClientId: Ref<string|undefined> = ref()

const formSideCanvasShown = computed({
  get: () => {
    return props.interfaceFormShown
  },
  set: (shown: boolean) => {
    emits('update:sideCanvasShown', shown)
  },
})

interface InterfaceListI {
  name: string
  authenticationType: InterfaceAuthenticationType
  profileName: string
  updatedAt: string
  status: InterfaceStatus
  clientId?: string
}

async function getProfilesCodes() {
  profileCodes.value = results.value ? results.value?.data.map((appInterface: InterfaceI) => appInterface.profileCode) : []
}

async function getUserProfiles() {
  await UserProfilesService.getUserProfilesByCodes(profileCodes.value).then((res) => { userProfiles.value = res })
}

async function loadData(page?: number, pageSize?: number) {
  if (page && pageSize) {
    await fetchData(page, pageSize)
  } else {
    await fetchData(results.value?.context.page, results.value?.context.pageSize)
  }
  await getProfilesCodes()
  await getUserProfiles()
}

const interfacesList: ComputedRef<Array<InterfaceListI>|undefined> = computed(() => {
  return results.value?.data.map((appInterface: InterfaceI) => {
    const profile = userProfiles.value.find(profile => profile.code === appInterface.profileCode)
    return {
      name: appInterface.name,
      authenticationType: appInterface.authenticationType,
      profileName: profile?.label ?? '',
      updatedAt: appInterface.updatedAt,
      status: appInterface.status,
      clientId: appInterface.clientId,
    }
  })
})

const displaySelectedInterfaceDetails = (interfaceClientId: string) => {
  selectedInterfaceClientId.value = interfaceClientId
  formSideCanvasShown.value = true
}

const onSelectInterface = async(selectedInterface: InterfaceListI) => {
  if (selectedInterface.clientId && canViewThirdPartyApps()) {
    displaySelectedInterfaceDetails(selectedInterface.clientId)
    await router.push(`${PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP}/${selectedInterface.clientId}`)
  }
}

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP)
    && route.params.clientId) {
    displaySelectedInterfaceDetails(route.params.clientId as string)
  }
}

const onClearInterfaceClientId = () => {
  selectedInterfaceClientId.value = undefined
}

onMounted(async() => {
  await loadData()
  initDisplayFromRoute()
})
</script>
<template>
  <lxc-table
    :is-loading="isLoading"
    :data="interfacesList"
    :context="results?.context"
    :error="error?.toError()"
    data-cy="interface-table"
    :clickable="canViewThirdPartyApps()"
    :empty-text="$t('applicationMgt.tabs.thirdPartyApp.empty')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectInterface"
  >
    <!-- ! Organization Name will be accessible in the future, for now we only get the organization code
    <lxc-table-column
      prop="organizationName"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.organizationName')"
    /> -->
    <lxc-table-column
      prop="name"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.interfaceName')"
    />
    <lxc-table-column
      prop="authenticationType"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.authMethod')"
    >
      <template #default="scope">
        {{ scope.row.authenticationType ? $t(`applicationMgt.tabs.thirdPartyApp.authMethod.${scope.row.authenticationType}`) : '' }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="profileName"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.profile')"
    />
    <lxc-table-column
      prop="updatedAt"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.updatedAt')"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.updatedAt, $t('dateFormat.datetime')) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="status"
      :label="$t('applicationMgt.tabs.thirdPartyApp.column.status')"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-third-party-application-status-badge
          :status="scope.row.status"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :width="100"
      class="!py-0"
    >
      <template #default="scope">
        <div
          class="flex"
        >
          <lxc-third-party-application-activate-deactivate-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :interface-status="scope.row.status"
            :can-manage-third-party-app="canManageThirdPartyApps()"
            :disable-activate-interface-button="disableActivateInterfaceButton"
            @change="loadData"
          />
          <lxc-third-party-application-delete-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :can-manage-third-party-apps="canManageThirdPartyApps()"
            @deleted="loadData"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-third-party-application-form
    v-model:side-canvas-shown="formSideCanvasShown"
    :selected-interface-client-id="selectedInterfaceClientId"
    @interface-created="loadData"
    @interface-updated="loadData"
    @clear-interface-client-id="onClearInterfaceClientId"
  />
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
