<script setup lang="ts">
import type { FirmwareI } from '@lxc/app-device-types'
import type { Rules } from 'async-validator'
import type { ComputedRef, Ref } from 'vue'
import firmwareService from '~/services/firmware.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import uploadUtils from '~/utils/upload.utils'

const { locale, t } = useI18n()

// File must be less than 750MB
const MAX_FILE_SIZE_BYTES_ALLOWED = 750_000_000

const props = defineProps<{
  sideCanvasShown: boolean
}>()

const emit = defineEmits([
  'upload',
  'update:sideCanvasShown',
])
const firmwareFormRef = ref()
const defaultFirmwareForm: FirmwareI = {
  uuid: '',
  name: '',
  description: '',
  version: '',
  range: '',
  declination: '',
  hardwareVersions: [],
  firmwareVersions: [],
  descriptor: '',
}

const formSideCancasShown = computed({
  get: () => {
    return props.sideCanvasShown
  },
  set: (shown: boolean) => {
    emit('update:sideCanvasShown', shown)
  },
})

const firmwareForm = reactive<FirmwareI>(Object.assign({}, defaultFirmwareForm))
// The reference of the file to upload
const files: Ref<FileList | undefined | null> = ref()
const filesProgress: Ref<number | undefined | null> = ref()
const filesUploadError: Ref<Error | undefined | null> = ref()
const isError: ComputedRef<boolean> = computed(() => (filesUploadError.value instanceof Error))
const filesLoading: ComputedRef<boolean> = computed(() => !!filesProgress.value && (filesProgress.value < 100))
const fileSelected: ComputedRef<boolean> = computed(() => !!files?.value?.length)
const isFirmwareUploaded: ComputedRef<boolean> = computed(() => !!firmwareForm.uuid && fileSelected.value)

function clearFirmwareUploadStatus() {
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined
  filesProgress.value = null

  if (files.value?.length) {
    setFirmwareForm()
  }
}

/**
 * Clear the firmware upload form and reset the reference of the file to upload
 */
function clearFirmwareUpload() {
  files.value = null
}

/**
 * Set the form of the firmware creation with the provided parameter, reset it if no parameter provided
 */
function setFirmwareForm(firmwareCreationForm?: FirmwareI) {
  firmwareFormRef.value?.clearValidate()
  Object.assign(firmwareForm, firmwareCreationForm ?? defaultFirmwareForm)
}

const rules = reactive<Rules>({
  name: [
    { required: true, message: t('firmware.validation.name'), type: 'string', whitespace: true },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true },
  ],
  version: [
    { required: true, message: t('firmware.validation.version'), type: 'string', whitespace: true },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true },
  ],
})

/**
 * Check if the file can be uploaded, show an error if not
 * @param file File to check
 * @return true if the file can be uploaded, false otherwise
 */
function canUploadFile(file: File): boolean {
  let error: string | undefined
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined

  if (!uploadUtils.isZipFile(file)) {
    error = 'fileMandatory'
  } else if (!file.size) {
    error = 'fileEmpty'
  } else if (file.size > MAX_FILE_SIZE_BYTES_ALLOWED) {
    error = 'fileSize'
  }

  if (error) {
    filesUploadError.value = new Error(t(`firmware.upload.validation.${error}`))
  }

  return !error
}

/**
 * Upload the selected referenced file and display creation form
 */
const uploadFirmware = async() => {
  filesUploadError.value = filesUploadError.value !== undefined ? null : undefined

  if (files?.value?.length && files?.value?.length !== 0 && canUploadFile(files?.value[0])) {
    const response: FirmwareI = await firmwareService.uploadFirmwareFile(files.value[0], filesProgress)

    if (LxcError.check(response)) {
      filesUploadError.value = response.toError(NotificationKey.uploadError)
    } else if (!(response as any).failed) {
      // only reload the list if at least one result succeed
      setFirmwareForm(response)
    }
  }
}

/**
 * Cancel create firmware and return to firmware list
 */
function onCancel() {
  clearFirmwareUpload()
  formSideCancasShown.value = false
}

const validateForm = async() => {
  return await firmwareFormRef.value.validate().catch((_: any) => false)
}

/**
 * Validate the form and call the service to save the new firmware
 */
async function onSubmit() {
  const isFormValid = await firmwareFormRef.value.validate().catch(() => false)

  if (isFormValid) {
    const response = await firmwareService.createFirmware(firmwareForm)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess))
      formSideCancasShown.value = false
      setTimeout(clearFirmwareUpload, 300)
      emit('upload')
    }
  }
}

watch(() => files.value, clearFirmwareUploadStatus)
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCancasShown"
    :header="$t('firmware.newFirmware')"
    :close-tooltip="$t('filters.close')"
    @discard="onCancel"
  >
    <div>
      <lxc-information
        class="mt-0 mb-8"
      >
        {{ $t('firmware.upload.restrictionInformation') }}
      </lxc-information>

      <lxc-input
        v-model="files"
        type="file"
        accept=".zip"
        :delete-tooltip="$t('input.deleteFile')"
        :error="filesUploadError"
        :locale="locale"
        :percentage="filesProgress"
        :size-unit="uploadUtils.getUnitTranslations()"
        @blur="clearFirmwareUploadStatus"
      >
        <template #placeholder>
          <div class="mx-8">
            <span class="text-primary-700 font-bold">{{ $t('input.clickToUpload') }}</span>&nbsp;<span>{{ $t('input.orDragAndDrop', { format: '.ZIP' }) }}</span>
          </div>
        </template>
      </lxc-input>

      <div v-if="isFirmwareUploaded">
        <lxc-form
          ref="firmwareFormRef"
          :model="firmwareForm"
          :rules="rules"
          @submit.prevent="validateForm"
        >
          <fieldset class="border-none mt-8 p-0">
            <legend class="m-0">
              <lxc-firmware-side-section-header>
                {{ $t('firmware.section.informations.title') }}
              </lxc-firmware-side-section-header>
            </legend>

            <lxc-form-item
              :label="$t('firmware.form.name')"
              prop="name"
            >
              <lxc-input
                v-model="firmwareForm.name"
                type="text"
                required
              />
            </lxc-form-item>
            <lxc-form-item
              :label="$t('firmware.form.version')"
              prop="version"
            >
              <lxc-input
                v-model="firmwareForm.version"
                type="text"
                required
              />
            </lxc-form-item>

            <lxc-form-item
              :label="$t('firmware.form.description')"
              prop="description"
            >
              <lxc-input
                v-model="firmwareForm.description"
                type="text"
              />
            </lxc-form-item>
          </fieldset>
          <div>
            <lxc-firmware-side-section-header>
              {{ $t('firmware.section.compatibility.title') }}
            </lxc-firmware-side-section-header>

            <lxc-input
              v-model="firmwareForm.range"
              class="text-base"
              :label="$t('firmware.form.range')"
              read-only
              type="text"
            />
            <div>
              <lxc-label
                class="mt-4"
                read-only
              >
                {{ $t('firmware.form.hardwareVersions') }}
              </lxc-label>

              <lxc-firmware-property-list
                :items="firmwareForm.hardwareVersions"
              />
              <lxc-label
                class="mt-4"
                read-only
              >
                {{ $t('firmware.form.firmwareVersions') }}
              </lxc-label>

              <lxc-firmware-property-list
                :items="firmwareForm.firmwareVersions"
              />
            </div>
          </div>
        </lxc-form>
      </div>
    </div>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          v-if="isFirmwareUploaded"
          html-type="button"
          type="secondary"
          :title="$t('button.cancel')"
          @click="onCancel"
        >
          {{ $t('button.cancel') }}
        </lxc-button>
        <lxc-button
          v-if="!isFirmwareUploaded"
          html-type="button"
          :disabled="filesLoading || !fileSelected || isError"
          :title="$t('button.import')"
          @click="uploadFirmware"
        >
          {{ $t('button.import') }}
        </lxc-button>
        <lxc-button
          v-if="isFirmwareUploaded"
          html-type="button"
          :disabled="isError"
          :title="$t('button.validate')"
          @click="onSubmit"
        >
          {{ $t('button.validate') }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
