<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { UniqueLabel } from '@lxc/app-device-common'
import ILxcAlertCircle from '~icons/lxc/alert-circle'
import { VALIDATION_NUMBER } from '~/constants/constants'
import type { KeystoreCSRRequestForm, KeystoreCertificateDetailUpdateForm, KeystoreSelfsignedCertificateForm, Option } from '~/types'
import LxcError from '~/utils/LxcError'

const props = defineProps <{
  disabled?: boolean
  edition?: boolean
  isTagsLoading: boolean
  modelValue: KeystoreSelfsignedCertificateForm | KeystoreCSRRequestForm | KeystoreCertificateDetailUpdateForm
  tags?: Array<string> | null
  tagsError?: LxcError | null
}>()

const emit = defineEmits([
  'update:modelValue',
])

const selectedTags = computed({
  get(): Array<UniqueLabel> {
    return props.modelValue.tags ?? []
  },
  set(paramTags: Array<UniqueLabel>) {
    const tmpCertificate = props.modelValue
    tmpCertificate.tags = paramTags
    emit('update:modelValue', tmpCertificate)
  },
})

// deep copy of modelValue including subject property
const form = reactive<KeystoreSelfsignedCertificateForm | KeystoreCSRRequestForm>(props.modelValue)

let initTagOptionsPending = props.tags === undefined || !props.tags?.length
const tagOptions: Ref<Array<Option>> = ref([])

async function initTagOptions() {
  if (props.isTagsLoading) {
    initTagOptionsPending = true
  } else if (!LxcError.check(props.tagsError)) {
    initTagOptionsPending = props.tags === undefined

    tagOptions.value = props.tags?.map((tag: string) => {
      return {
        disabled: props.disabled || !!selectedTags.value.find(selectedTag => selectedTag.uid === tag),
        label: tag,
        value: tag,
      }
    }) ?? []
  }
}

watch(() => form, (newForm: KeystoreSelfsignedCertificateForm | KeystoreCSRRequestForm) => {
  emit('update:modelValue', newForm)
}, {
  deep: true,
})

watch(() => props.isTagsLoading, (isLoading) => {
  if (!isLoading && initTagOptionsPending) {
    initTagOptions()
  }
})

const isTagError: ComputedRef<boolean> = computed(() => {
  return LxcError.check(props.tagsError)
})

onMounted(async() => {
  initTagOptions()
})

</script>

<template>
  <lxc-alert
    v-if="isTagError"
    :icon="ILxcAlertCircle"
    type="error"
  >
    <template #title>
      {{ $t('certificates.tags.label') }}
      {{ $t('certificates.tags.label') }}
    </template>
    <p>{{ tagsError?.toError()?.message ?? '' }}</p>
  </lxc-alert>

  <lxc-form-item
    :label="$t('certificates.tags.label')"
    prop="tags"
  >
    <lxc-tag-select
      v-model="selectedTags"
      :options="tagOptions"
      :placeholer="$t('certificates.form.tags.placeholder')"
      :delete-tooltip="$t('certificates.form.tags.remove.tooltip')"
      :disabled="disabled || isTagError"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.alias')"
    prop="alias"
  >
    <lxc-input
      v-model="form.alias"
      type="text"
      :max-length="VALIDATION_NUMBER.ALIAS_MAX_LENGTH"
      :disabled="disabled || edition"
      required
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.cn')"
    prop="subject.cn"
  >
    <lxc-input
      v-model="form.subject.cn"
      type="text"
      :disabled="disabled || edition"
      :required="!(disabled || edition)"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.email')"
    prop="subject.email"
  >
    <lxc-input
      v-model="form.subject.email"
      type="email"
      :disabled="disabled || edition"
      :max-length="VALIDATION_NUMBER.MAIL_MAX_LENGTH"
      :required="!(disabled || edition)"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.serialNumber')"
    prop="subject.serialNumber"
  >
    <lxc-input
      v-model="form.subject.serialNumber"
      type="text"
      :disabled="disabled || edition"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.organizationUnit')"
    prop="subject.organizationUnit"
  >
    <lxc-input
      v-model="form.subject.organizationUnit"
      type="text"
      :disabled="disabled || edition"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.organization')"
    prop="subject.organization"
  >
    <lxc-input
      v-model="form.subject.organization"
      type="text"
      :disabled="disabled || edition"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="$t('certificates.form.locality')"
    prop="subject.locality"
  >
    <lxc-input
      v-model="form.subject.locality"
      type="text"
      :disabled="disabled || edition"
    />
  </lxc-form-item>

  <div class="flex gap-6">
    <div
      class="grow shrink basis-0"
    >
      <lxc-form-item
        :label="$t('certificates.form.region')"
        prop="subject.region"
      >
        <lxc-input
          v-model="form.subject.region"
          type="text"
          :disabled="disabled || edition"
        />
      </lxc-form-item>
    </div>

    <div
      class="grow shrink basis-0"
    >
      <lxc-form-item
        :label="$t('certificates.form.country')"
        prop="subject.country"
      >
        <lxc-input
          v-model="form.subject.country"
          type="text"
          :disabled="disabled || edition"
        />
      </lxc-form-item>
    </div>
  </div>
</template>
