import certificateTagsService from '~/services/certificateTags.service'
import LxcError from '~/utils/LxcError'

export function useCertificateTags() {
  const tags = ref<Array<string> | undefined | null>()
  const error = ref<LxcError | undefined | null>()
  const isLoading = ref<boolean>(false)

  const getTags = async(): Promise<void> => {
    isLoading.value = true
    const response = await certificateTagsService.getTags()

    if (LxcError.check(response)) {
      if (tags.value) {
        tags.value = null
      }
      error.value = response
    } else {
      if (error.value) {
        error.value = null
      }

      tags.value = response
    }

    isLoading.value = false
  }

  return {
    error,
    getTags,
    isLoading,
    tags,
  }
}
