import type { DtwinI, DtwinModelI, SectorI } from '@lxc/app-device-types'
import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import type { FiltersSelection } from '~/types'
import { Filters, FiltersType, OPERATOR_RSQL_ILIKE, OPERATOR_RSQL_IN, OPERATOR_RSQL_OUT } from '~/types'
import DtwinsService from '~/services/dtwins.service'
import { useFetchPage } from '~/composables/useFetchPage'
import { useSort } from '~/composables/useSort'
import { useUserSession } from '~/stores/useUserSession'
import { useConfigStore } from '~/stores/useConfigStore'
import filtersUtils from '~/utils/filters.utils'

export function useDtwins(searchMode?: SearchMode) {
  const service = DtwinsService.getDtwins.bind(DtwinsService)
  const itemType = 'dtwins'

  const { userSession } = useUserSession()
  const { defaultSectorCode } = useConfigStore()

  const appliedFilters: FiltersSelection = new Map([
    [Filters.DTWIN_NAME_OR_SERIAL_NUMBER, {
      key: ['attributes.friendlyName', 'attributes.serialNumber'],
      operator: OPERATOR_RSQL_ILIKE,
      value: '',
      valueFormatter: filtersUtils.formatRSQLValue,
    }],
    [Filters.DTWIN_IN_SECTORS, { key: 'attributes.sectorId', operator: OPERATOR_RSQL_IN, value: [] }],
    [Filters.DTWIN_NOT_IN_SECTORS, { key: 'attributes.sectorId', operator: OPERATOR_RSQL_OUT, value: [] }],
    [Filters.DTWIN_MODEL_TYPE, { key: 'deviceModelUid', operator: OPERATOR_RSQL_IN, value: [] }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RSQL, appliedFilters, searchMode)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  /**
   * Return the description of a dtwin
   * @param dtwin
   * @returns
   */
  function getDescription(dtwin?: DtwinI) {
    return dtwin?.attributes.friendlyName ?? ''
  }

  function canManageItems(sector: SectorI) {
    return !sector.isRootSector && userSession?.sectors.map(sector => sector.code).includes(`${defaultSectorCode}`)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.uid)
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    onSearch,
    search,
    getDescription,
    canManageItems,
    getItemIds,
  }
}

export function useDtwin() {
  /**
   * Return the label of the model for a device twin
   * @param dtwin
   * @param models
   * @returns
   */
  const getDtwinTypeLabel = function(models: DtwinModelI[], dtwin?: DtwinI | null): string {
    const model = models.find((model: DtwinModelI) => model.uid === dtwin?.deviceModelUid)
    return model?.friendlyName || model?.name || ''
  }

  return {
    getDtwinTypeLabel,
  }
}
