<script setup lang="ts">
import type { DtwinI, DtwinModelI, PaginatedListI, SectorI } from '@lxc/app-device-types'
import { ElTableColumn } from 'element-plus'
import type { Ref } from 'vue'
import { onMounted } from 'vue'
import { useDtwin, useDtwins } from '~/composables/useDtwins'
import { PATHS } from '~/constants/paths'
import { router } from '~/plugins/router'
import DtwinsService from '~/services/dtwins.service'
import { Filters, SectorResource } from '~/types'
import LxcError from '~/utils/LxcError'

const props = defineProps<{
  sector: SectorI
}>()

const {
  isLoading: isLoadingDtwins,
  results: dtwins,
  error: errorDtwins,
  fetchData,
  setFilter,
  canManageItems,
  getDescription,
} = useDtwins()

const {
  getDtwinTypeLabel,
} = useDtwin()

const selectedItems: Ref<any[]> = ref([])
const displayActions = computed(() => canManageItems(props.sector))

const isLoadingModels: Ref<boolean> = ref(false)
const errorModels: Ref<LxcError | undefined> = ref()
const models: Ref<Array<DtwinModelI> | undefined> = ref()

const fetchModels = async() => {
  isLoadingModels.value = true

  const response: PaginatedListI<DtwinModelI> = await DtwinsService.getModels(1, 999999999)
  if (LxcError.check(response)) {
    errorModels.value = response
  } else {
    models.value = response.results
  }

  isLoadingModels.value = false
}

onMounted(async() => {
  await fetchModels()
  setFilter(Filters.DTWIN_IN_SECTORS, [props.sector.code])
  await fetchData()
})

async function refresh() {
  await fetchData()
  selectedItems.value = []
}

function onRowClick(dtwin: DtwinI) {
  router.push(`${PATHS.DTWINS}/${dtwin.uid}`)
}

const isLoading = computed(() => isLoadingModels.value || isLoadingDtwins.value)
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="uid"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.DTWINS"
    @change="refresh"
  >
    <el-table-column
      prop="attributes.friendlyName"
      :label="$t('dtwins.list.attributes.friendlyName')"
    />
    <el-table-column
      prop="attributes.type"
      :label="$t('dtwins.list.attributes.type')"
    >
      <template
        v-if="models"
        #default="scope"
      >
        {{ getDtwinTypeLabel(models, scope.row) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('dtwins.list.attributes.state')"
    >
      <template #default="scope">
        {{ $t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
      </template>
    </el-table-column>
  </lxc-sector-toolbar>

  <lxc-container
    :is-loading="isLoading && !dtwins"
    :error="errorModels || errorDtwins"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="uid"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="dtwins"
      :display-actions="displayActions"
      :resource="SectorResource.DTWINS"
      :get-description="getDescription"
      @pagination:get-next-page="fetchData"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <el-table-column
        prop="attributes.friendlyName"
        :label="$t('dtwins.list.attributes.friendlyName')"
      />
      <el-table-column
        prop="attributes.type"
        :label="$t('dtwins.list.attributes.type')"
      >
        <template
          v-if="models"
          #default="scope"
        >
          {{ getDtwinTypeLabel(models, scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="attributes.serialNumber"
        :label="$t('dtwins.list.attributes.serialNumber')"
      />
      <el-table-column
        :label="$t('dtwins.list.attributes.state')"
      >
        <template #default="scope">
          {{ $t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
        </template>
      </el-table-column>
    </lxc-sectors-details-table>
  </lxc-container>
</template>
