<script setup lang="ts">
import { VALIDATION_REGEXP } from '~/constants/constants'

const props = defineProps<{
  show: boolean
  isDownloadZipFileLoading: boolean
}>()

const emits = defineEmits(['download', 'update:show'])

const { t } = useI18n()

interface PasswordFormI {
  password: string|undefined
}

const passwordFormRef: Ref<HTMLFormElement | undefined | null> = ref()
const passwordForm = reactive<PasswordFormI>({
  password: undefined,
})

const isPasswordValid = (rules: any, value: any, callback: any) => {
  const pattern = VALIDATION_REGEXP.PASSWORD
  if (!pattern.test(value)) {
    return callback(new Error(t('connectMobileApp.passwordModal.password.validation.pattern')))
  }
  return callback()
}

const passwordFormRules = {
  password: [
    { required: true, message: t('connectMobileApp.passwordModal.password.validation.required'), trigger: 'blur' },
    { validator: isPasswordValid, trigger: 'blur' },
  ],
}

async function isFormValid() {
  return await passwordFormRef.value?.validate().catch((_: any) => false)
}

async function onSubmit() {
  if (await isFormValid()) {
    emits('download', passwordForm.password)
  }
}

const onClose = () => {
  emits('update:show', false)
  passwordForm.password = undefined
}

watch(() => props.show, () => {
  if (!props.show) {
    passwordForm.password = undefined
  }
})

</script>
<template>
  <lxc-common-modal
    :show="show"
    body-class="bg-white"
    footer-class="mb-2 flex-row-reverse gap-2"
    no-close-button
  >
    <template #header>
      <h4
        class="pt-5"
      >
        {{ $t('connectMobileApp.passwordModal.title') }}
      </h4>
    </template>
    <template #body>
      <lxc-form
        ref="passwordFormRef"
        :model="passwordForm"
        :rules="passwordFormRules"
      >
        <lxc-form-item
          :label="$t('connectMobileApp.passwordModal.password.label')"
          prop="password"
        >
          <lxc-input
            v-model="passwordForm.password"
            type="text"
          />
        </lxc-form-item>
      </lxc-form>
    </template>
    <template #footer>
      <lxc-button
        type="primary"
        html-type="button"
        :title="$t('button.confirm')"
        :is-loading="isDownloadZipFileLoading"
        @click="onSubmit"
      >
        {{ $t('button.confirm') }}
      </lxc-button>
      <lxc-button
        type="secondary"
        html-type="button"
        :title="$t('button.cancel')"
        @click="onClose"
      >
        {{ $t('button.cancel') }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>
