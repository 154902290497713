import dragula from 'dragula'
import type { LxcDragnDropItem, LxcDragnDropOption } from '~/types/dragndrop'

/**
 * Manage drag and drop for desktop computers, tablet and mobile terminals
 * Use the Dragula libray:
 * @see https://github.com/bevacqua/dragula
 * Demo visible on:
 * @see https://bevacqua.github.io/dragula/
 */
export default class LxcDragNDropManager {
  private dragnDropSettings = new Map<HTMLElement, LxcDragnDropItem>()

  add(item: LxcDragnDropItem) {
    if (!this.dragnDropSettings.has(item.element)) {
      this.dragnDropSettings.set(item.element, item)
    } else {
      const currentItem = this.dragnDropSettings.get(item.element)
      if (currentItem) {
        currentItem.eventType = item.eventType
        currentItem.handler = item.handler
        currentItem.hasBindTouchEvents = currentItem.hasBindTouchEvents || item.hasBindTouchEvents
      }
    }
  }

  bindEvents(element: HTMLElement) {
    const item = this.dragnDropSettings.get(element)

    if (item?.move) {
      const movableItems = this.getItemsByMove(item.move)
      const draggableItems = movableItems.filter(item => item.eventType === 'drag')
      const droppableItems = movableItems.filter(item => item.eventType === 'drop')
      const mainDroppableItem = droppableItems.find(item => item.isMainDroppable)

      for (const draggable of draggableItems) {
        for (const droppable of droppableItems) {
          this.bindDragndrop(draggable, droppable, mainDroppableItem)
        }
      }
    }
  }

  delete(element: HTMLElement) {
    const item = this.dragnDropSettings.get(element)
    if (item) {
      if (item.dragApi) {
        item.dragApi.destroy()
      } else if (item.eventType === 'drop' && item.move) {
        const droppableItem = this.getMainDroppableItemByMove(item.move)

        const elementIndex = droppableItem?.dragApi?.containers.findIndex(currentElement => currentElement === element)

        if (elementIndex !== undefined && elementIndex >= 0) {
          droppableItem?.dragApi?.containers.splice(elementIndex, 1)
        }
      }
      this.dragnDropSettings.delete(element)
    }
  }

  getItemsByMove(move: string): Array<LxcDragnDropItem> {
    const items = this.dragnDropSettings.values()
    const movingItems = []

    for (const item of items) {
      if (item.move === move) {
        movingItems.push(item)
      }
    }

    return movingItems
  }

  getDroppableItemsByMove(move: string): Array<LxcDragnDropItem> {
    return this.getItemsByMove(move).filter(item => item.eventType === 'drop')
  }

  getMainDroppableItemByMove(move: string): LxcDragnDropItem | undefined {
    return this.getItemsByMove(move).find(item => item.eventType === 'drop' && item.isMainDroppable)
  }

  bindDragndrop(draggableItem: LxcDragnDropItem, droppableItem: LxcDragnDropItem, mainDroppableItem?: LxcDragnDropItem) {
    if (!droppableItem.hasBindTouchEvents) {
      if (droppableItem.option?.transform) {
        droppableItem.option.revertOnSpill = true
      }

      if (droppableItem.isMainDroppable) {
        droppableItem.dragApi = dragula([draggableItem.element, droppableItem.element], droppableItem.option)

        if (draggableItem.handler) {
          droppableItem.dragApi.on('drag', draggableItem.handler)
        }

        if (droppableItem.handler) {
          this.bindDrop(droppableItem, mainDroppableItem)
        }

        droppableItem.hasBindTouchEvents = true
      } else {
        mainDroppableItem?.dragApi?.containers.push(droppableItem.element)
        droppableItem.hasBindTouchEvents = true
      }
    }
  }

  bindDrop(droppableItem: LxcDragnDropItem, mainDroppableItem?: LxcDragnDropItem) {
    let temporaryHandler: (el: Element, target: Element, source: Element, sibling?: Element) => void

    if (droppableItem.option?.transform) {
      // If tranform, hide the draggable target in the droppable element
      droppableItem.dragApi?.on('over', (el: Element) => {
        el.classList.add('hidden')
      })

      droppableItem.dragApi?.on('out', (el: Element) => {
        this.removeDragOverClassToMirror('gu-drag-over')
        el.classList.remove('hidden')
      })

      droppableItem.dragApi?.on('shadow', () => {
        this.addDragOverClassToMirror('gu-drag-over')
      })

      temporaryHandler = (el: Element, target: Element, source: Element, sibling?: Element) => {
        mainDroppableItem?.dragApi?.cancel()
        this.callTargetDroppableHander(el, target, source, sibling)
      }
    } else {
      temporaryHandler = this.callTargetDroppableHander.bind(this)
    }

    droppableItem.dragApi?.on('drop', temporaryHandler)
  }

  addDragOverClassToMirror(className: string) {
    const mirror = document.querySelector('.gu-mirror')

    if (mirror && !mirror.classList.contains(className)) {
      mirror.classList.add(className)
    }
  }

  removeDragOverClassToMirror(className: string) {
    const mirror = document.querySelector('.gu-mirror')

    if (mirror && mirror.classList.contains(className)) {
      mirror.classList.remove(className)
    }
  }

  bindDrag(draggableItem: LxcDragnDropItem, droppableItem: LxcDragnDropItem) {
    if (droppableItem.isMainDroppable && droppableItem.dragApi) {
      droppableItem.dragApi.on('drag', draggableItem.handler as (el: Element, target: Element) => void)
    }
  }

  callTargetDroppableHander(el: Element, target: Element, source: Element, sibling?: Element) {
    const targetDroppableItem = this.dragnDropSettings.get(target as HTMLElement)

    if (targetDroppableItem?.handler) {
      targetDroppableItem?.handler(el, targetDroppableItem.element, source, sibling)
    }
  }

  setMove(elm: HTMLElement, move: string) {
    if (this.dragnDropSettings.has(elm)) {
      const currentItem = this.dragnDropSettings.get(elm)

      if (currentItem) {
        currentItem.isMainDroppable = currentItem.eventType === 'drop'
          && (this.getDroppableItemsByMove(move).length === 0)
        currentItem.move = move
      }
    } else {
      const item: LxcDragnDropItem = {
        element: elm,
        eventType: 'drag',
        hasBindTouchEvents: false,
        isMainDroppable: false,
        move,
      }
      this.dragnDropSettings.set(elm, item)
    }
  }

  setOption(element: HTMLElement, option: LxcDragnDropOption) {
    if (this.dragnDropSettings.has(element)) {
      const currentItem = this.dragnDropSettings.get(element)
      if (currentItem && (currentItem.eventType === 'drop')) {
        currentItem.option = option
      }
    } else {
      const item: LxcDragnDropItem = {
        element,
        eventType: 'drop',
        hasBindTouchEvents: false,
        isMainDroppable: false,
        option,
      }
      this.dragnDropSettings.set(element, item)
    }
  }
}
