<script setup lang="ts">
import type { Ref } from 'vue'
import { ref } from 'vue'
import { useActions } from '~/composables/useActions'
import { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { Filters } from '~/types'
const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  header?: string
  name?: string
  modelValue?: Array<string>
  selectedActions?: Array<string>
}>()

const {
  isLoading,
  results: actions,
  fetchData,
  setFilter,
  search,
  onSearch,
} = useActions(SearchMode.FILTER_SEARCH)

const filterMap: Ref<Map<Filters, any> | undefined> = ref()

async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()
  await fetchData(page, pageSize, undefined, params)
}

// Apply provided default filters
const updateListFilter = () => {
  if (filterMap.value != null) {
    for (const [filter, value] of filterMap.value) {
      setFilter(filter, value ?? '')
    }
  }
}

watch(() => props.name, (filterByName) => {
  if (!filterMap.value) {
    filterMap.value = new Map<Filters, any>()
  }
  if (!filterByName) {
    if (filterMap.value?.has(Filters.NAME)) {
      filterMap.value.set(Filters.NAME, '')
    }
  } else {
    filterMap.value.set(Filters.NAME, filterByName)
  }

  updateListFilter()
  search()
})

const visibleData = computed(() => {
  return actions.value?.data.length ?? 0 <= 10 ? actions.value?.data : actions.value?.data.slice(0, 10) ?? []
})

const selectedItems = computed({
  get() {
    if (props.modelValue) {
      return props.modelValue
    }

    return []
  },
  set(selected?: Array<string>) {
    if (selected != null) {
      const actionItems: Array<string> = selected.map((selectedAction?: string) => {
        // If the action is not in the action list found, use modelValue where there was the previous selected action list
        let action: string | undefined = actions.value?.data.find(currentAction => currentAction === selectedAction)

        if (!action) {
          action = props.modelValue?.find(currentAction => currentAction === selectedAction)
        }
        return action
      }).filter(currentAction => currentAction !== undefined) as Array<string>
      emit('update:modelValue', actionItems)
    } else {
      emit('update:modelValue', [])
    }
  },
})

onSearch(loadData)
</script>

<template>
  <div class="pb-6 px-6 overlow-y-auto">
    <h6
      v-if="props.header != null"
      class="font-bold text-base mb-4 underline"
    >
      {{ props.header }}
    </h6>
    <lxc-checkbox
      v-for="action in visibleData"
      :key="action"
      v-model="selectedItems"
      :label="action"
      :value="action"
      class="mb-2"
    />
    <div v-if="visibleData?.length === 0 && !isLoading">
      {{ $t('logs.filters.actions.empty') }}
    </div>

    <div
      v-if="isLoading"
      class="absolute w-full inset-0"
    >
      <div class="flex flex-col items-center justify-center">
        <lxc-loader :size="20" />
      </div>
    </div>
  </div>
</template>
