import type { SectorI, UserGroupI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import { SearchMode, useSearch } from '~/composables/useSearch'
import UserGroupsService from '~/services/userGroups.service'
import type { FiltersSelection } from '~/types'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useSort } from '~/composables/useSort'
import { useFetchPage } from '~/composables/useFetchPage'

export function useUserGroups() {
  const service = UserGroupsService.getUserGroups.bind(UserGroupsService)
  const itemType = 'userGroup'

  const { can } = useAcl()

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: 'name', operator: '=', value: '' }],
  ])

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(FiltersType.RAW, appliedFilters, SearchMode.URL_SEARCH)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function getDescription(item: UserGroupI): string {
    return item?.label ?? ''
  }

  function canManageItems(_sector: SectorI) {
    return can(ACL_ROLES.DISPLAY_USERS)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.code)
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  }
}
