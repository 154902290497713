<script setup lang="ts">
import type { UserDataI } from '@lxc/app-device-types'
import type { UserLanguageI } from '~/types'
import ILxcQuestionCircle from '~icons/lxc-custom/question-circle'

const props = defineProps<{
  user: UserDataI | null
  isModeAD: boolean
}>()
const isAllowedToEdit = computed(() => !props.isModeAD && !props.user?.isProtected)

const formRef = ref()
const { availableLocales } = useI18n()

defineEmits(['saveLanguage'])

const userLanguageForm: UserLanguageI = reactive({
  language: '',
})

const setLanguageForm = () => {
  userLanguageForm.language = props.user?.language ?? ''
}

watch(() => props.user, setLanguageForm)

onMounted(setLanguageForm)
</script>

<template>
  <lxc-container :px="0">
    <h2>{{ $t('user.userAccount.language.title') }}</h2>
    <p class="flex gap-2">
      {{ $t('user.userAccount.instructions.language') }}
      <el-tooltip
        v-if="isModeAD"
        popper-class="small-popper"
      >
        <ILxcQuestionCircle />
        <template #content>
          <span>{{ $t('user.userAccount.language.activeDirectoryWarning') }}</span>
        </template>
      </el-tooltip>
    </p>

    <el-form
      ref="formRef"
      :model="userLanguageForm"
      label-position="top"
      label-width="200px"
    >
      <el-row :gutter="30">
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            data-cy="language"
            :label="$t('input.language')"
            prop="language"
          >
            <el-select
              v-model="userLanguageForm.language"
              filterable
              :placeholder="$t('input.language')"
              :disabled="!isAllowedToEdit"
            >
              <el-option
                v-for="item in availableLocales"
                :key="item"
                :label="$t('input.languages.' + item)"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          :disabled="!isAllowedToEdit"
          type="primary"
          @click="$emit('saveLanguage', {
            language: userLanguageForm.language,
          })"
        >
          {{ $t('user.userAccount.forms.validate') }}
        </el-button>
      </el-form-item>
    </el-form>
  </lxc-container>
</template>
