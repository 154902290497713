<script lang='ts' setup>
import type { UserProfileI } from '@lxc/app-device-types'
import type { LocationQuery } from 'vue-router/dist/vue-router'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'
import { userTabsItems } from '~/components/userManagement/tab-user-management.config'
import { useElTable } from '~/composables/useElTable'
import { useProfiles } from '~/composables/useProfiles'
import { PATHS } from '~/constants/paths'
import ILxcPlus from '~icons/lxc/plus'
import { useRoles } from '~/composables/useRoles'

const router = useRouter()
const { getCellStyle } = useElTable()

const {
  isLoading: isLoadingFetchUserProfiles,
  profiles,
  error: errorFetchUserProfiles,
  fetchUserProfiles,
} = useProfiles()

const {
  isLoading: isLoadingFetchRoles,
  roles,
  error: errorFetchRoles,
  fetchRoles,
} = useRoles()

const route = useRoute()

const tabItems = userTabsItems

const newProfile: Ref<boolean> = ref(false)

function openUserProfileForm(row?: UserProfileI) {
  if (row?.code) {
    const targetUrl: Record<string, string | string[] | LocationQuery> = {
      path: `${PATHS.USER_MANAGEMENT_PROFILES}/${row.code}`,
      query: route.query,
    }
    router.push(targetUrl)
  } else {
    newProfile.value = true
  }
}

function onCloseUserProfileForm() {
  const targetUrl: Record<string, string | string[] | LocationQuery> = {
    path: PATHS.USER_MANAGEMENT_PROFILES,
    query: route.query,
  }
  router.push(targetUrl)

  if (newProfile.value) {
    newProfile.value = false
  }
}

function reloadWithContext() {
  fetchUserProfiles(profiles.value?.context?.page)
}

onMounted(() => Promise.all([fetchRoles(), fetchUserProfiles()]))
</script>

<template>
  <lxc-container :px="0">
    <lxc-breadcrumb class="mb-8 px-8" />

    <lxc-tab-bar
      :tabs-items="tabItems"
      active-page-index="2"
    />
    <lx-toolbar :is-right="true">
      <el-button
        type="primary"
        :icon="ILxcPlus"
        size="large"
        @click="openUserProfileForm"
      >
        {{ $t('profile.new') }}
      </el-button>
    </lx-toolbar>

    <lxc-container
      :is-loading="(isLoadingFetchRoles || isLoadingFetchUserProfiles) && !profiles"
      :error="errorFetchRoles || errorFetchUserProfiles"
    >
      <el-table
        v-loading="isLoadingFetchRoles || isLoadingFetchUserProfiles"
        :data="profiles?.data"
        :fit="true"
        row-class-name="clickable"
        data-cy="profiles-table"
        :cell-style="getCellStyle"
        @row-click="openUserProfileForm"
      >
        <el-table-column
          prop="label"
          :label="$t('profile.profile')"
        />
        <el-table-column
          prop="nbUsers"
          :label="$t('profile.nbOfUsers')"
        />
        <el-table-column width="50">
          <template #default="scope">
            <lxc-profile-actions
              v-if="!scope.row.isDefault"
              :user-profile="scope.row"
              @change="reloadWithContext"
            />
          </template>
        </el-table-column>
      </el-table>

      <lxc-pagination
        :type-of-element="$t('pagination.profile', profiles?.context?.totalCount ?? 0)"
        :context="profiles?.context"
        @update:current-page="fetchUserProfiles"
      />

      <lxc-profile-form
        :new-profile="newProfile"
        :roles-by-section-and-context="roles"
        @close="onCloseUserProfileForm"
        @update="reloadWithContext"
      />
    </lxc-container>
  </lxc-container>
</template>
