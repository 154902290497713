/**
 * Return the unit translations
 */
function getUnitTranslations() {
  const { t } = useI18n()
  return {
    byte: t('unit.file.byte'),
    bytes: t('unit.file.bytes'),
    kB: t('unit.file.kB'),
    MB: t('unit.file.MB'),
    GB: t('unit.file.GB'),
  }
}

/**
 * Return true if the file is ZIP typed
 */
function isZipFile(file: File) {
  return file.name.toLowerCase().endsWith('.zip')
}

/**
 * Return true if the file is a certificate typed
 */
function isCertificateFile(file: File) {
  return /\.(pem|crt|cer)$/.test(file.name.toLowerCase())
}

export default {
  getUnitTranslations,
  isCertificateFile,
  isZipFile,
}
