<script lang='ts' setup>
import ILxcAlertCircle from '~icons/lxc/alert-circle'
import LxcError from '~/utils/LxcError'

const props = defineProps<{
  empty: boolean
  emptyMessage?: string
  error?: LxcError | null
  loading?: boolean
}>()

const translatedError: ComputedRef<Error | undefined> = computed(() => LxcError.check(props.error) ? props.error?.toError() : undefined)
const errorMessage: ComputedRef<string | undefined> = computed(() => translatedError.value?.message)
const errorCause: ComputedRef<string | undefined> = computed(() => translatedError.value?.cause as string)
</script>

<template>
  <div
    v-if="loading"
    class="inset-0 flex flex-col"
  >
    <div class="flex flex-1 items-center justify-center">
      <lxc-loader :size="20" />
    </div>
  </div>

  <lxc-alert
    v-else-if="LxcError.check(error)"
    :icon="ILxcAlertCircle"
    type="error"
  >
    <template #title>
      {{ errorMessage }}
    </template>
    <p v-if="errorCause">
      {{ errorCause }}
    </p>
  </lxc-alert>

  <div
    v-else-if="empty"
    class="px-6 pt-6 text-center"
  >
    {{ emptyMessage ?? '' }}"
  </div>

  <template v-else>
    <slot />
  </template>
</template>
